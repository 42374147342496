import { createSlice, current } from '@reduxjs/toolkit';
import { CONDITION_CHECKS } from '../constants';
import {
	newConditionTemplate,
	getDefaultConditionValueForQuestion,
	getQuestionByNanoId,
} from '../utils';
import { cloneDeep, forEach } from 'lodash';

const initialState = {
	project: {
		submissionStatus: 'idle',
		loading: false,
		nanoid: null,
		title: null,
		status: null,
		error: '',
		subscriptionDetail: null,
	},
	cost: {
		status: 'idle',
		data: null,
	},
	promoCodes: {
		promoCodes: [],
		loading: false,
		isApplyingPromoCode: '',
		error: null,
	},
	subscriptions: {
		status: 'idle',
		subscriptionList: [],
	},

	transactions: {
		status: 'idle',
		transactionList: [],
		isPaidMode: false,
		paymentMode: null,
		remainingTotal: 0,
		provisions: {
			transactionCount: 0,
			sum: 0,
		},
		paid: {
			transactionCount: 0,
			sum: 0,
		},
	},
	feasibility: {
		status: 'idle',
		data: null,
	},
	audience: {
		nanoid: null,
		reach: null,
		quotas: [],
		loadingQuotaField: {},
		errors: [],

		// [
		// 	{
		// 		"nanoid": "oZndasUu4kJd-gOs",
		// 		"audience": "-jwCcSWyd9m206Gi",
		// 		"category": "H2Osj9ltq6OLzniN",
		// 		"created": "2023-12-12T16:50:43.330828Z",
		// 		"modified": "2023-12-12T16:50:43.330838Z"
		//      "quotaFields":
		// [{
		// 	"nanoid": "pRnG2TwQVweIkAWr",
		// 	"quota": "oZndasUu4kJd-gOs",
		// 	"audience_field": "xAKEwkdb92arfn4A",
		// 	"response_quota": 1110,
		// 	"created": "2023-12-12T17:34:14.878868Z",
		// 	"modified": "2023-12-12T17:34:14.878924Z"
		// }]
		// 	}
		// ]
		//
		availableCategories: [],
		selectedCategories: {},
	},
	questionnaire: {
		nanoid: null,
		hasErrors: false,
		screeningQuestionnaireHasErrors: false,
		isAddingQuestion: false,
		isRandomized: false,
		screeningQuestionList: [],
		questionList: [],
		questionStatuses: {},
		activeQuestionIndex: -1,
		screeningActiveQuestionIndex: -1,
		screeningActiveQuestionNanoId: null,
		activeQuestionNanoId: null,
		preview: {
			status: 'idle',
			previewData: null,
		},
	},
	comments: {
		postComment: {
			draftComment: '',
		},
		status: 'idle',
		hasUnread: false,
		commentList: [],
	},
	activeQuestionnaire: {
		data: null,
		error: '',
	},
	selectedDemographics: [],
};

function moveItemInList(list, source, destination) {
	const [reorderedItem] = list.splice(source, 1);
	list.splice(destination, 0, reorderedItem);
	return list;
}

function findActiveQuestionIndex({ state, is_screening }) {
	// Helper to find the array index of the currently active (selected) question
	if (is_screening) {
		return state.questionnaire.screeningQuestionList.findIndex(element => {
			return (
				element.nanoid === state.questionnaire.screeningActiveQuestionNanoId
			);
		});
	} else {
		return state.questionnaire.questionList.findIndex(element => {
			return element.nanoid === state.questionnaire.activeQuestionNanoId;
		});
	}
}

// eslint-disable-next-line import/no-unused-modules
export const projectEditorSlice = createSlice({
	name: 'projectEditor',
	initialState,
	reducers: {
		resetProjectEditor: () => {
			return initialState;
		},
		changeProjectNameStarted: () => {},
		changeProjectNameFinished: (state, action) => {
			state.project.title = action.payload.title;
		},
		calculateProjectCostStart: state => {
			state.cost.status = 'loading';
		},
		calculateProjectCostFinish: (state, action) => {
			state.cost.status = 'idle';
			state.cost.data = action.payload.data;
		},
		loadPromoCodesStart: state => {
			state.promoCodes.loading = true;
		},
		loadPromoCodesFinish: (state, action) => {
			state.promoCodes.loading = false;
			state.promoCodes.promoCodes = action.payload;
		},
		addPromoCodeStart: (state, action) => {
			state.promoCodes.isApplyingPromoCode = action.payload.promoCode;
		},
		addPromoCodeFinish: state => {
			state.promoCodes.isApplyingPromoCode = '';
		},
		addPromoCodeFail: (state, action) => {
			state.promoCodes.error = action.payload;
			state.promoCodes.isApplyingPromoCode = '';
		},
		deletePromoCodeStart: (state, action) => {
			state.promoCodes.isApplyingPromoCode = action.payload.promoCode;
		},
		deletePromoCodeFinish: state => {
			state.promoCodes.isApplyingPromoCode = '';
		},
		deletePromoCodeErrors: state => {
			state.promoCodes.error = null;
		},
		setSubscriptionDetail: (state, action) => {
			state.project.subscriptionDetail = action.payload;
		},
		loadSubscriptionsStart: state => {
			state.subscriptions.status = 'loading';
			state.subscriptions.subscriptionList = [];
		},
		loadSubscriptionsFinish: (state, action) => {
			state.subscriptions.status = 'idle';
			state.subscriptions.subscriptionList = action.payload;
		},
		loadTransactionsStart: state => {
			state.transactions.status = 'loading';
			state.transactions.transactionList = [];
		},
		loadTransactionsFinish: (state, action) => {
			state.transactions.status = 'idle';
			state.transactions.transactionList = action.payload.data;

			//initially set the values to zero, as the calculation below is repeated every time the project loads
			state.transactions.provisions.transactionCount = 0;
			state.transactions.provisions.sum = 0;

			state.transactions.paid.transactionCount = 0;
			state.transactions.paid.sum = 0;

			state.transactions.transactionList.forEach(transaction => {
				if (transaction.is_succeeded) {
					state.transactions.paymentMode = transaction.transaction_type; // 'purchase_order', 'post_form', or 'post_auth'

					switch (transaction.transaction_type) {
						case 'post_form': // Provision
							state.transactions.provisions.transactionCount += 1;
							state.transactions.provisions.sum += transaction.amount.raw_value;
							break;
						case 'purchase_order': // Purchase order
						case 'post_auth': // Paid
							state.transactions.paid.transactionCount += 1;
							state.transactions.paid.sum += transaction.amount.raw_value;
							break;
					}
				}
			});

			state.transactions.isPaidMode =
				state.transactions.paid.transactionCount > 0;
		},
		calculateProjectFeasibilityStart: state => {
			state.feasibility.status = 'loading';
			state.feasibility.data = null;
		},
		calculateProjectFeasibilityFinish: (state, action) => {
			state.feasibility.status = 'idle';
			state.feasibility.data = action.payload.data;
		},
		setAudienceDetails: (state, action) => {
			state.audience.nanoid = action.payload.audienceNanoId;
			state.audience.reach = action.payload.reach;
			state.audience.quotas = action.payload.quotas;

			state.audience.errors = action.payload.errors;
		},
		addQuota: (state, action) => {
			state.audience.quotas.push(action.payload.quota);
			state.audience.errors = action.payload.errors;
		},
		deleteQuota: (state, action) => {
			state.audience.quotas = state.audience.quotas.filter(
				quota => quota.nanoid !== action.payload.quotaNanoId,
			);
		},
		deleteQuotaFromAudience: (state, action) => {
			state.audience.quotas = state.audience.quotas.filter(
				quota => quota.nanoid !== action.payload,
			);
		},
		updateQuotaResponseStart: (state, action) => {
			const quotaToUpdate = state.audience.quotas.find(
				quota => quota.nanoid === action.payload.quotaNanoId,
			);
			const quotaField = quotaToUpdate.quotaFields.find(
				field => field.nanoid === action.payload.quotaFieldNanoId,
			);
			quotaField['isLoading'] = true;
		},
		updateQuotaResponseFinish: (state, action) => {
			const quotaToUpdate = state.audience.quotas.find(
				quota => quota.nanoid === action.payload.quotaNanoId,
			);

			const quotaField = quotaToUpdate.quotaFields.find(
				field => field.nanoid === action.payload.quotaFieldNanoId,
			);

			quotaField['isLoading'] = false;
			quotaField['response_quota'] = action.payload.responseQuota;
			state.audience.errors = action.payload.audienceErrors;
		},
		updateQuotaFieldStart: (state, action) => {
			if (state.audience.loadingQuotaField[action.payload.quotaNanoId]) {
				state.audience.loadingQuotaField[action.payload.quotaNanoId].push(
					action.payload.audienceField,
				);
			} else {
				state.audience.loadingQuotaField[action.payload.quotaNanoId] = [];
				state.audience.loadingQuotaField[action.payload.quotaNanoId].push(
					action.payload.audienceField,
				);
			}
		},
		setQuotaFields: (state, action) => {
			if (action.payload.audienceField) {
				state.audience.loadingQuotaField[action.payload.quotaNanoId] =
					state.audience.loadingQuotaField[action.payload.quotaNanoId].filter(
						field => field !== action.payload.audienceField,
					);
			}

			if (action.payload.audienceErrors) {
				state.audience.errors = action.payload.audienceErrors;
			}
			const quotaToUpdate = state.audience.quotas.find(
				quota => quota.nanoid === action.payload.quotaNanoId,
			);
			quotaToUpdate.quotaFields = action.payload.quotaFields;
		},
		setRemainingTotal: (state, action) => {
			state.transactions.remainingTotal = action.payload;
		},
		setAudienceReach: (state, action) => {
			state.audience.reach = action.payload.reach;
		},
		setAvailableAudienceCategories: (state, action) => {
			state.audience.availableCategories = action.payload.categoryList;
		},
		setSelectedAudienceCategories: (state, action) => {
			state.audience.selectedCategories = action.payload.selectedCategories;
		},
		addSelectedAudienceCategory: (state, action) => {
			state.audience.selectedCategories[action.payload.categoryNanoId] = [];
		},
		removeSelectedAudienceCategory: (state, action) => {
			delete state.audience.selectedCategories[action.payload.categoryNanoId];
			//remove quota
			state.audience.quotas = state.audience.quotas.filter(
				quota => quota.category !== action.payload.categoryNanoId,
			);
		},
		setProject: (state, action) => {
			state.project.nanoid = action.payload.nanoid;
			state.project.title = action.payload.title;
			state.project.status = action.payload.status;
			state.project.metrics = action.payload.metrics;
			state.project.payment_mode = action.payload.payment_mode;
			state.questionnaire.nanoid = action.payload.questionnaire;
			state.project.subscription = action.payload.subscription;
		},
		setQuestionList: (state, action) => {
			const newQuestionList = cloneDeep(action.payload.questionList);

			let hasErrors = false;

			forEach(newQuestionList, question => {
				question.logic.isAddingRule = false;
				if (question.errors && Object.keys(question.errors).length > 0) {
					hasErrors = true;
				}
			});

			state.questionnaire.hasErrors = hasErrors;

			state.questionnaire.questionList = newQuestionList;

			let tempStatus = {};

			forEach(newQuestionList, question => {
				tempStatus[question.nanoid] = {
					isAddingChoice: false,
					isAddingRow: false,
					isAddingColumn: false,
				};
			});

			state.questionnaire.questionStatuses = {
				...state.questionnaire.questionStatuses,
				...tempStatus,
			};

			// Optionally, action dispatcher can tell us which question to be active after everything is set up
			if (action.payload.activeQuestion) {
				state.questionnaire.activeQuestionNanoId =
					action.payload.activeQuestion.nanoid;
				state.questionnaire.activeQuestionIndex =
					action.payload.activeQuestion.index;
			} else if (newQuestionList.length > 0) {
				state.questionnaire.activeQuestionNanoId = newQuestionList[0].nanoid;
				state.questionnaire.activeQuestionIndex = 0;
			}
		},
		setScreeningQuestionList: (state, action) => {
			const newQuestionList = cloneDeep(action.payload.questionList);

			let screeningQuestionnaireHasErrors = false;
			forEach(newQuestionList, question => {
				if (question.errors && Object.keys(question.errors).length > 0) {
					screeningQuestionnaireHasErrors = true;
				}
			});
			state.questionnaire.screeningQuestionnaireHasErrors =
				screeningQuestionnaireHasErrors;

			state.questionnaire.screeningQuestionList = newQuestionList;

			let tempStatus = {};
			forEach(newQuestionList, question => {
				tempStatus[question.nanoid] = {
					isAddingChoice: false,
					isAddingRow: false,
					isAddingColumn: false,
				};
			});
			state.questionnaire.questionStatuses = {
				...state.questionnaire.questionStatuses,
				...tempStatus,
			};

			if (action.payload.activeQuestion) {
				state.questionnaire.screeningActiveQuestionNanoId =
					action.payload.activeQuestion.nanoid;
				state.questionnaire.screeningActiveQuestionIndex =
					action.payload.activeQuestion.index;
			} else if (newQuestionList.length > 0) {
				state.questionnaire.screeningActiveQuestionNanoId =
					newQuestionList[0].nanoid;
				state.questionnaire.screeningActiveQuestionIndex = 0;
			}
		},
		updateProjectStatus: state => {
			state.project.loading = true;
		},
		finishUpdatingProjectStatus: (state, action) => {
			state.project = {
				...state.project,
				nanoid: action.payload.nanoid,
				status: action.payload.status,
				loading: false,
			};
		},
		setProjectSubmissionStatus: state => {
			state.project.submissionStatus = 'submitted';
		},
		clearProjectSubmissionStatus: state => {
			state.project.submissionStatus = 'idle';
		},
		createQuestion: state => {
			state.questionnaire.isAddingQuestion = true;
		},
		finishCreatingQuestion: (state, action) => {
			state.questionnaire.isAddingQuestion = false;

			let tempStatus = Object.assign(
				{ selected: false },
				current(state.questionnaire.questionStatuses),
			);

			tempStatus[action.payload.newQuestion.nanoid] = {
				isAddingChoice: false,
				isAddingRow: false,
				isAddingColumn: false,
			};

			state.questionnaire.questionStatuses = tempStatus;

			if (action.payload.newQuestion.is_screening) {
				state.questionnaire.screeningQuestionList.push(
					action.payload.newQuestion,
				);
				state.questionnaire.screeningActiveQuestionNanoId =
					action.payload.newQuestion.nanoid;
				state.questionnaire.screeningActiveQuestionIndex =
					state.questionnaire.screeningQuestionList.length - 1;
			} else {
				state.questionnaire.questionList.push(action.payload.newQuestion);
				state.questionnaire.activeQuestionNanoId =
					action.payload.newQuestion.nanoid;
				state.questionnaire.activeQuestionIndex =
					state.questionnaire.questionList.length - 1;
			}
		},
		deleteQuestion: (state, action) => {
			let tempStatus = Object.assign(
				{ selected: false },
				current(state.questionnaire.questionStatuses),
			);

			delete tempStatus[action.payload.question.nanoid];
			state.questionnaire.questionStatuses = tempStatus;

			if (action.payload.question.is_screening) {
				state.questionnaire.screeningQuestionList.splice(
					action.payload.index,
					1,
				);
				if (state.questionnaire.screeningQuestionList.length === 0) {
					return;
				}

				const currentActiveIndex =
					state.questionnaire.screeningActiveQuestionIndex;

				if (currentActiveIndex >= action.payload.index) {
					const newActiveIndex =
						currentActiveIndex - 1 > -1 ? currentActiveIndex - 1 : 0;

					state.questionnaire.screeningActiveQuestionIndex = newActiveIndex;
					state.questionnaire.screeningActiveQuestionNanoId =
						state.questionnaire.screeningQuestionList[newActiveIndex].nanoid;
				}
			} else {
				const currentActiveIndex = state.questionnaire.activeQuestionIndex;

				state.questionnaire.questionList.splice(action.payload.index, 1);

				if (currentActiveIndex >= action.payload.index) {
					const newActiveIndex =
						currentActiveIndex - 1 > -1 ? currentActiveIndex - 1 : 0;
					state.questionnaire.activeQuestionIndex = newActiveIndex;
					state.questionnaire.activeQuestionNanoId =
						state.questionnaire.questionList[newActiveIndex].nanoid;
				}
			}
		},
		moveQuestionToTop: (state, action) => {
			if (action.payload.is_screening) {
				state.questionnaire.screeningQuestionList = moveItemInList(
					state.questionnaire.screeningQuestionList,
					action.payload.source,
					0,
				);
				state.questionnaire.screeningActiveQuestionIndex =
					findActiveQuestionIndex({
						state,
						is_screening: action.payload.is_screening,
					});
			} else {
				state.questionnaire.questionList = moveItemInList(
					state.questionnaire.questionList,
					action.payload.source,
					0,
				);

				state.questionnaire.activeQuestionIndex = findActiveQuestionIndex({
					state,
					is_screening: action.payload.is_screening,
				});
			}
		},
		moveQuestionToBottom: (state, action) => {
			if (action.payload.is_screening) {
				state.questionnaire.screeningQuestionList = moveItemInList(
					state.questionnaire.screeningQuestionList,
					action.payload.source,
					state.questionnaire.screeningQuestionList.length,
				);

				state.questionnaire.screeningActiveQuestionIndex =
					findActiveQuestionIndex({
						state,
						is_screening: action.payload.is_screening,
					});
			} else {
				state.questionnaire.questionList = moveItemInList(
					state.questionnaire.questionList,
					action.payload.source,
					state.questionnaire.questionList.length,
				);

				state.questionnaire.activeQuestionIndex = findActiveQuestionIndex({
					state,
					is_screening: action.payload.is_screening,
				});
			}
		},
		reorderQuestionList: (state, action) => {
			if (action.payload.is_screening) {
				state.questionnaire.screeningQuestionList = moveItemInList(
					state.questionnaire.screeningQuestionList,
					action.payload.source,
					action.payload.destination,
				);

				state.questionnaire.screeningActiveQuestionIndex =
					findActiveQuestionIndex({
						state,
						is_screening: action.payload.is_screening,
					});
			} else {
				state.questionnaire.questionList = moveItemInList(
					state.questionnaire.questionList,
					action.payload.source,
					action.payload.destination,
				);

				state.questionnaire.activeQuestionIndex = findActiveQuestionIndex({
					state,
					is_screening: action.payload.is_screening,
				});
			}
		},
		setActiveQuestion: (state, action) => {
			if (action.payload.question.is_screening) {
				const foundIndex = state.questionnaire.screeningQuestionList.findIndex(
					element => {
						return element.nanoid === action.payload.question.nanoid;
					},
				);
				if (foundIndex > -1) {
					state.questionnaire.screeningActiveQuestionIndex = foundIndex;
					state.questionnaire.screeningActiveQuestionNanoId =
						action.payload.question.nanoid;
				}
			} else {
				const foundIndex = state.questionnaire.questionList.findIndex(
					element => {
						return element.nanoid === action.payload.question.nanoid;
					},
				);
				if (foundIndex > -1) {
					state.questionnaire.activeQuestionIndex = foundIndex;
					state.questionnaire.activeQuestionNanoId =
						action.payload.question.nanoid;
				}
			}
		},
		updateQuestionTitle: (state, action) => {
			if (action.payload.question.is_screening) {
				state.questionnaire.screeningQuestionList[
					action.payload.questionIndex
				].title = action.payload.title;
			} else {
				state.questionnaire.questionList[action.payload.questionIndex].title =
					action.payload.title;
			}
		},
		updateQuestionErrors: (state, action) => {
			if (action.payload.question.is_screening) {
				const foundIdx = state.questionnaire.screeningQuestionList.findIndex(
					element => {
						return element.nanoid === action.payload.question.nanoid;
					},
				);
				state.questionnaire.screeningQuestionList[foundIdx].errors =
					action.payload.errors;

				let hasErrors = false;

				forEach(state.questionnaire.screeningQuestionList, question => {
					if (question.errors && Object.keys(question.errors).length > 0) {
						hasErrors = true;
					}
				});

				state.questionnaire.screeningQuestionnaireHasErrors = hasErrors;
			} else {
				const foundIndex = state.questionnaire.questionList.findIndex(
					element => {
						return element.nanoid === action.payload.question.nanoid;
					},
				);
				state.questionnaire.questionList[foundIndex].errors =
					action.payload.errors;

				let hasErrors = false;

				forEach(state.questionnaire.questionList, question => {
					if (question.errors && Object.keys(question.errors).length > 0) {
						hasErrors = true;
					}
				});

				state.questionnaire.hasErrors = hasErrors;
			}
		},
		updateQuestionMedia: (state, action) => {
			if (action.payload.question.is_screening) {
				state.questionnaire.screeningQuestionList[
					action.payload.questionIndex
				].media = action.payload.media;
			} else {
				state.questionnaire.questionList[action.payload.questionIndex].media =
					action.payload.media;
			}
		},
		updateQuestionDescription: (state, action) => {
			if (action.payload.question.is_screening) {
				state.questionnaire.screeningQuestionList[
					action.payload.questionIndex
				].description = action.payload.description;
			} else {
				state.questionnaire.questionList[
					action.payload.questionIndex
				].description = action.payload.description;
			}
		},
		updateQuestionSetting: (state, action) => {
			let list = state.questionnaire.questionList;
			if (action.payload.question.is_screening) {
				list = state.questionnaire.screeningQuestionList;
			}

			const foundIndex = list.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				list[foundIndex].config[action.payload.setting] =
					action.payload.newValue;

				if (action.payload.question.question_type === 'multiple_selection') {
					if (
						action.payload.setting === 'min_selection_count' &&
						action.payload.question.config.selection_count_type ===
							'exact_number'
					) {
						list[foundIndex].config.max_selection_count =
							action.payload.newValue;
					} else if (action.payload.setting === 'selection_count_type') {
						if (action.payload.newValue === 'exact_number') {
							list[foundIndex].config.max_selection_count =
								list[foundIndex].config.min_selection_count;
						} else if (action.payload.newValue === 'range') {
							list[foundIndex].config.min_selection_count = Math.min(
								list[foundIndex].config.min_selection_count,
								list[foundIndex].config.max_selection_count,
							);

							list[foundIndex].config.max_selection_count =
								list[foundIndex].config.min_selection_count + 1;
						}
					}
				}
			}
		},
		updateChoice: (state, action) => {
			let list = state.questionnaire.questionList;

			if (action.payload.question.is_screening) {
				list = state.questionnaire.screeningQuestionList;
			}

			const foundIndex = list.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});

			if (foundIndex > -1) {
				const foundChoiceIndex = list[foundIndex].choices.findIndex(choice => {
					return choice.nanoid === action.payload.choice.nanoid;
				});

				if (action.payload.title !== undefined) {
					list[foundIndex].choices[foundChoiceIndex].title =
						action.payload.title;
				}

				if (action.payload.media !== undefined) {
					list[foundIndex].choices[foundChoiceIndex].media =
						action.payload.media;
				}
				list[foundIndex].choices[foundChoiceIndex].is_qualified =
					action.payload.is_qualified;
			}
		},
		createChoice: (state, action) => {
			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingChoice'
			] = true;
		},

		finishCreatingChoice: (state, action) => {
			let list = state.questionnaire.questionList;
			if (action.payload.question.is_screening) {
				list = state.questionnaire.screeningQuestionList;
			}
			const foundIndex = list.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				list[foundIndex].choices.push(action.payload.newChoice);
			}

			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingChoice'
			] = false;
		},

		reorderChoiceList: (state, action) => {
			let list = state.questionnaire.questionList;
			if (action.payload.question.is_screening) {
				list = state.questionnaire.screeningQuestionList;
			}

			const foundIndex = list.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				list[foundIndex].choices = moveItemInList(
					list[foundIndex].choices,
					action.payload.source,
					action.payload.destination,
				);
			}
		},
		deleteChoice: (state, action) => {
			let list = state.questionnaire.questionList;
			if (action.payload.question.is_screening) {
				list = state.questionnaire.screeningQuestionList;
			}
			const foundIndex = list.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				list[foundIndex].choices.splice(action.payload.index, 1);
			}
		},
		updateMatrixRowTitle: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].rows[
					action.payload.rowIndex
				].title = action.payload.title;
			}
		},
		updateMatrixColumnTitle: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].columns[
					action.payload.columnIndex
				].title = action.payload.title;
			}
		},
		createMatrixRow: (state, action) => {
			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingMatrixRow'
			] = true;
		},
		finishCreatingMatrixRow: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].rows.push(
					action.payload.newChoice,
				);
			}

			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingMatrixRow'
			] = false;
		},
		deleteMatrixRow: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].rows.splice(
					action.payload.index,
					1,
				);
			}
		},
		createMatrixColumn: (state, action) => {
			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingMatrixColumn'
			] = true;
		},
		finishCreatingMatrixColumn: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].columns.push(
					action.payload.newChoice,
				);
			}
			state.questionnaire.questionStatuses[action.payload.question.nanoid][
				'isAddingMatrixColumn'
			] = false;
		},
		deleteMatrixColumn: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].columns.splice(
					action.payload.index,
					1,
				);
			}
		},
		updateConditionQuestion: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				const foundRuleIndex = state.questionnaire.questionList[
					foundIndex
				].logic.rules.findIndex(element => {
					return element.nanoid === action.payload.rule.nanoid;
				});

				// Find the type of the new question so we can select an appropriate check for it (e.g. `is one of`)
				const newQuestion = getQuestionByNanoId(
					state.questionnaire.questionList,
					action.payload.newQuestionNanoId,
				);

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions[action.payload.index]['question'] = newQuestion.nanoid;

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions[action.payload.index]['check'] =
					CONDITION_CHECKS[newQuestion.question_type][0].value;

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions[action.payload.index].value =
					getDefaultConditionValueForQuestion({ question: newQuestion });
			}
		},
		addRule: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});

			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].logic.isAddingRule = true;
			}
		},
		finishAddingRule: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});

			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].logic.isAddingRule = false;

				state.questionnaire.questionList[foundIndex].logic.rules.push(
					action.payload.rule,
				);
			}
		},
		deleteRule: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].logic.rules.splice(
					action.payload.index,
					1,
				);
			}
		},
		addCondition: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				const foundRuleIndex = state.questionnaire.questionList[
					foundIndex
				].logic.rules.findIndex(element => {
					return element.nanoid === action.payload.rule.nanoid;
				});

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions.push(
					newConditionTemplate({
						question: state.questionnaire.questionList[foundIndex],
					}),
				);
			}
		},
		deleteCondition: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				const foundRuleIndex = state.questionnaire.questionList[
					foundIndex
				].logic.rules.findIndex(element => {
					return element.nanoid === action.payload.rule.nanoid;
				});

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions.splice(action.payload.index, 1);
			}
		},
		updateConditionCheck: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				const foundRuleIndex = state.questionnaire.questionList[
					foundIndex
				].logic.rules.findIndex(element => {
					return element.nanoid === action.payload.rule.nanoid;
				});

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions[action.payload.index].check = action.payload.check;
			}
		},
		updateConditionValue: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				const foundRuleIndex = state.questionnaire.questionList[
					foundIndex
				].logic.rules.findIndex(element => {
					return element.nanoid === action.payload.rule.nanoid;
				});

				state.questionnaire.questionList[foundIndex].logic.rules[
					foundRuleIndex
				].conditions[action.payload.index].value = action.payload.value;
			}
		},
		updateRuleTarget: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].logic.rules[
					action.payload.index
				].then = action.payload.then;
			}
		},
		updateRuleOperator: (state, action) => {
			const foundIndex = state.questionnaire.questionList.findIndex(element => {
				return element.nanoid === action.payload.question.nanoid;
			});
			if (foundIndex > -1) {
				state.questionnaire.questionList[foundIndex].logic.rules[
					action.payload.index
				].operator = action.payload.operator;
			}
		},
		updateQuestionnaireIsRandomized: (state, action) => {
			state.questionnaire.isRandomized = action.payload.isRandomized;
		},
		loadPreviewDataStart: state => {
			state.questionnaire.preview.status = 'loading';
		},
		loadPreviewDataComplete: (state, action) => {
			state.questionnaire.preview = {
				status: 'ready',
				previewData: action.payload.previewData,
			};
		},
		setQuestionnaire: (state, action) => {
			state.questionnaire.isRandomized = action.payload.isRandomized;
			// TODO: load questionlist here and update activeindex and activeQuestionNanoId
		},
		updateQuestionnaire: state => {
			state.activeQuestionnaire.data.is_randomized =
				!state.activeQuestionnaire.data.is_randomized;
		},
		failQuestionnaireAction: (state, action) => {
			state.activeQuestionnaire.error = action.payload;
		},
		loadComments: state => {
			state.comments.status = 'loading';
		},
		finishLoadingComments: (state, action) => {
			state.comments.status = 'idle';
			state.comments.commentList = action.payload.comments;
		},
		updateDraftComment: (state, action) => {
			state.comments.postComment.draftComment = action.payload.draftComment;
		},
		setCommentAsPosted: (state, action) => {
			state.comments.commentList.forEach(comment => {
				if (comment.nanoid === action.payload.nanoid) {
					comment.internalStatus = 'posted';
					comment.created = action.payload.created;
				}
			});
		},
		setUnreadComments: (state, action) => {
			state.comments.hasUnread = action.payload.hasUnread;
		},
		setSelectedDemographics: (state, action) => {
			state.selectedDemographics = action.payload;
		},
	},
});

// eslint-disable-next-line import/no-unused-modules
export const {
	resetProjectEditor,

	// Project
	changeProjectNameStarted,
	changeProjectNameFinished,
	setProject,
	addPromoCodeFinish,
	addPromoCodeFail,
	deletePromoCodeErrors,
	loadPromoCodesFinish,
	deletePromoCodeFinish,
	calculateProjectCostStart,
	calculateProjectCostFinish,
	finishUpdatingProjectStatus,
	setProjectSubmissionStatus,
	clearProjectSubmissionStatus,
	setSubscriptionDetail,
	loadSubscriptionsStart,
	loadSubscriptionsFinish,
	loadTransactionsStart,
	loadTransactionsFinish,
	setRemainingTotal,

	// Audience
	calculateProjectFeasibilityStart,
	calculateProjectFeasibilityFinish,
	setAudienceDetails,
	setAudienceReach,
	setQuotaFields,
	addQuota,
	deleteQuota,
	deleteQuotaFromAudience,
	updateQuotaFieldStart,
	updateQuotaResponseStart,
	updateQuotaResponseFinish,
	setAvailableAudienceCategories,
	setSelectedAudienceCategories,
	addSelectedAudienceCategory,
	removeSelectedAudienceCategory,

	// Questionnaire
	setQuestionList,
	setScreeningQuestionList,
	createQuestion,
	finishCreatingQuestion,
	deleteQuestion,
	moveQuestionToTop,
	moveQuestionToBottom,
	reorderQuestionList,
	setActiveQuestion,
	updateQuestionTitle,
	updateQuestionDescription,
	updateQuestionSetting,
	updateQuestionMedia,
	updateQuestionErrors,
	updateChoice,
	createChoice,
	finishCreatingChoice,
	reorderChoiceList,
	deleteChoice,
	updateMatrixRowTitle,
	updateMatrixColumnTitle,
	createMatrixRow,
	finishCreatingMatrixRow,
	deleteMatrixRow,
	createMatrixColumn,
	finishCreatingMatrixColumn,
	deleteMatrixColumn,
	addRule,
	finishAddingRule,
	deleteRule,
	addCondition,
	deleteCondition,
	updateConditionQuestion,
	updateConditionCheck,
	updateConditionValue,
	updateRuleTarget,
	updateRuleOperator,
	updateQuestionnaire,
	failQuestionnaireAction,
	setQuestionnaire,
	updateQuestionnaireIsRandomized,
	loadPreviewDataStart,
	loadPreviewDataComplete,

	// Comments
	loadComments,
	finishLoadingComments,
	updateDraftComment,
	postComment,
	setCommentAsPosted,
	setUnreadComments,
	setSelectedDemographics,
} = projectEditorSlice.actions;

export default projectEditorSlice.reducer;

import Badge from '../Badge';
import { useTranslation } from 'react-i18next';
import { TagIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addQuestionnaireLabelStart,
	addResponseLabelStart,
	deleteLabelStart,
} from '../../store/analysisSlice';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';
import Spinner from '../Spinner';

export default function LabelList({
	responseLabels,
	responseNanoId,
	questionNanoId,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');

	const { nanoid } = useParams();
	const questionnaireNanoId = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);
	const projectLabels = useSelector(
		state => state.analysis.projects[nanoid].labels,
	);
	const labelNames = responseLabels[questionNanoId]
		? responseLabels[questionNanoId].map(label => ({
				id: label,
				name: projectLabels.labelNames[label],
		  }))
		: [];

	let questionLabels = projectLabels.labelList.filter(
		label => label.question === questionNanoId,
	);

	questionLabels = orderBy(
		questionLabels,
		[label => label.name.toLowerCase()],
		['asc'],
	);

	const filteredLabels =
		questionLabels.length > 0
			? questionLabels.filter(
					label =>
						label.name &&
						label.name.toLowerCase().includes(searchText.toLowerCase()),
			  )
			: [];

	const isCheckboxChecked = (labelNanoId, responseLabels) => {
		return (
			responseLabels[questionNanoId] &&
			responseLabels[questionNanoId].findIndex(label => label === labelNanoId) >
				-1
		);
	};

	const onHandleChange = e => {
		const { checked, value } = e.target;
		checked ? addResponseLabel(value) : deleteLabel(e, value);
	};

	//prevents dropdown from closing when space key is pressed
	const handleOnKeyDown = e => {
		e.keyCode === 32 ? e.stopPropagation() : null;
	};

	const deleteLabel = (e, labelNanoId) => {
		dispatch(
			deleteLabelStart({
				projectNanoId: nanoid,
				responseNanoId: responseNanoId,
				labelNanoId: labelNanoId,
				questionNanoId: questionNanoId,
			}),
		);
	};

	const addResponseLabel = labelNanoId => {
		dispatch(
			addResponseLabelStart({
				responseNanoId: responseNanoId,
				projectNanoId: nanoid,
				questionNanoId: questionNanoId,
				labelNanoId: labelNanoId,
			}),
		);
	};

	const addQuestionnaireLabel = () => {
		dispatch(
			addQuestionnaireLabelStart({
				projectNanoId: nanoid,
				questionNanoId: questionNanoId,
				labelName: searchText,
				questionnaireNanoId: questionnaireNanoId,
				responseNanoId: responseNanoId,
			}),
		);
		setSearchText('');
	};

	return (
		<div className="flex gap-1 items-center">
			<Menu as="div" className="relative inline-block text-left">
				<div>
					<Menu.Button className="z-10 inline-flex items-center gap-x-1.5 rounded-full bg-white mt-1 px-2.5 py-1 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
						{t('Labels')}
					</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className=" absolute z-10 left-0 mt-2 mb-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-2 px-4 border-b border-gray-200">
							<div>
								<div className="relative rounded-md shadow-sm ">
									<div className="pointer-events-none  absolute inset-y-0 left-0 flex items-center pl-3">
										{filteredLabels.length > 0 ? (
											<MagnifyingGlassIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										) : (
											<TagIcon className="w-4 h-4 mr-1 text-slate-400" />
										)}
									</div>
									<input
										type="text"
										className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										placeholder={
											filteredLabels.length > 0
												? t('Search labels')
												: t('Create label')
										}
										onChange={e => setSearchText(e.target.value)}
										onKeyDown={handleOnKeyDown}
										value={searchText}
									/>
								</div>
							</div>
						</div>
						<div className="py-1 ">
							{filteredLabels &&
								filteredLabels.map(label => {
									return (
										<div
											key={label.nanoid}
											className="flex py-1 px-4 items-center"
										>
											<input
												type="checkbox"
												className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
												id={label.nanoid}
												value={label.nanoid}
												name={label.name}
												checked={
													isCheckboxChecked(label.nanoid, responseLabels) ||
													false
												}
												onChange={onHandleChange}
											/>
											<div className="ml-2 text-sm leading-6">
												<label htmlFor={label.nanoid}>{label.name}</label>
											</div>
										</div>
									);
								})}
						</div>
						{(filteredLabels.length === 0 || searchText) && (
							<div className="py-1 border-t border-gray-200">
								<Menu.Item
									disabled={
										!searchText ||
										projectLabels.status.loadingStatus === 'loading ?'
									}
								>
									<div className="py-1 px-4">
										<button
											disabled={
												!searchText ||
												projectLabels.status.loadingStatus === 'loading ?'
											}
											type="button"
											className="w-full rounded-md  px-2.5 py-1.5 text-sm font-semibold bg-white text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-not-allowed"
											onClick={addQuestionnaireLabel}
										>
											{projectLabels.status.loadingStatus === 'loading ?' ? (
												<Spinner className="h-4 w-4 m-auto" />
											) : (
												<div className="truncate text-ellipsis">
													{t('Add new label')}
													{searchText && `: ${searchText}`}
												</div>
											)}
										</button>
									</div>
								</Menu.Item>
							</div>
						)}
					</Menu.Items>
				</Transition>
			</Menu>
			{labelNames.map(label => {
				const responseLbl = `${responseNanoId[0]}_${label.id}`;
				return (
					<Badge
						className={classNames(
							'pr-6 lg:pr-2 relative mb-0.5 mt-1 inline-block group-one ',
							projectLabels.status.deletingStatus[responseLbl]
								? 'lg:pr-6'
								: 'transition-all duration-300 hover:pr-6',
						)}
						key={label?.id}
						type="info"
						border={true}
					>
						<TagIcon className="w-4 h-4 mr-1 text-slate-400" />
						{label.name ? label.name : ''}

						{projectLabels.status.deletingStatus[responseLbl] ? (
							<span className="pl-2 inline-block absolute right-2">
								<Spinner className=" h-3 w-3 " />
							</span>
						) : (
							<>
								<div
									className="ml-1 -mr-1 absolute right-2 opacity-100 lg:opacity-0 transition duration-400 group group-one-hover:opacity-100 "
									onClick={e => deleteLabel(e, label?.id)}
								>
									<XMarkIcon className="w-4 h-4 cursor-pointer lg:cursor-none hover:cursor-pointer" />
								</div>
							</>
						)}
					</Badge>
				);
			})}
		</div>
	);
}

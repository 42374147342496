import {
	TableCellsIcon,
	CloudArrowDownIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { classNames } from '../../utils';
import { getTranslatedQuestionText } from '../../utils/Translation';
import { Buttons } from '../Button';
import * as XLSX from 'xlsx';

function EmptyState({ className }) {
	const { t } = useTranslation();

	return (
		<div className={classNames(className, 'text-center')}>
			<TableCellsIcon
				className="mx-auto h-12 w-12 text-gray-400"
				aria-hidden="true"
			/>
			<h3 className="mt-2 text-sm font-semibold text-gray-600">
				{t('analysis.crossTab.emptyState.title')}
			</h3>
			<p className="mt-1 text-sm text-gray-500">
				{t('analysis.crossTab.emptyState.subTitle')}
			</p>
		</div>
	);
}

function TableRow({
	row,
	translations,
	language,
	rankingExistsinRow,
	showResponseCount,
	showTotalPercentage,
	showRowPercentage,
	showColumnPercentage,
}) {
	return (
		<Fragment key={row.nanoid}>
			{row.choices.map((choice, index) => (
				<tr key={index} className="divide-x divide-gray-200 bg-white">
					{index === 0 && (
						<td
							valign="top"
							rowSpan={row.choices.length}
							className="bg-gray-50 whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-gray-600"
						>
							{getTranslatedQuestionText(
								translations,
								language,
								row.nanoid,
								row.name,
							)}
						</td>
					)}

					<td
						valign="top"
						colSpan={rankingExistsinRow ? 2 : 1}
						className="bg-gray-50 whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-gray-600 border-l border-gray-200"
					>
						{getTranslatedQuestionText(
							translations,
							language,
							choice.nanoid,
							choice.name,
						)}
					</td>
					{choice.columnValues.map(colValue => (
						<td
							key={colValue.nanoid}
							className="bg-white whitespace-nowrap px-3 py-4 text-xs font-mono text-right text-gray-600"
						>
							<div className="flex flex-col">
								{showResponseCount && colValue.count.toLocaleString()}

								<div className="flex flex-col">
									{showTotalPercentage &&
										colValue.totalPercentage !== undefined && (
											<span>{colValue.totalPercentage.toFixed(2)}%</span>
										)}

									{showRowPercentage &&
										colValue.rowPercentage !== undefined && (
											<span>{colValue.rowPercentage.toFixed(2)}%</span>
										)}

									{showColumnPercentage &&
										colValue.columnPercentage !== undefined && (
											<span>{colValue.columnPercentage.toFixed(2)}%</span>
										)}
								</div>
							</div>
						</td>
					))}
				</tr>
			))}
		</Fragment>
	);
}

function RankingTableRow({
	row,
	translations,
	language,
	showResponseCount,
	showTotalPercentage,
	showRowPercentage,
	showColumnPercentage,
}) {
	return (
		<Fragment key={row.nanoid}>
			{row.choices.map((choice, choiceIndex) => {
				return choice.columnValues.map((el, index) => {
					return (
						<tr key={index} className="divide-x divide-gray-200 bg-white">
							{choiceIndex === 0 && index === 0 && (
								<td
									valign="top"
									rowSpan={row.choices.length * row.choices.length}
									className="bg-gray-50 whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-gray-600"
								>
									{getTranslatedQuestionText(
										translations,
										language,
										row.nanoid,
										row.name,
									)}
								</td>
							)}
							{index === 0 && (
								<td
									valign="top"
									rowSpan={row.choices.length}
									className="bg-gray-50 whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-gray-600 border-l border-gray-200"
								>
									{getTranslatedQuestionText(
										translations,
										language,
										choice.nanoid,
										choice.name,
									)}
								</td>
							)}
							<td
								valign="top"
								className="bg-gray-50 whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-gray-600 border-l border-gray-200"
							>
								#{index + 1}
							</td>
							{el.map(colValue => (
								<td
									key={`${row.nanoid}-${colValue.nanoid}`}
									className="bg-white whitespace-nowrap px-3 py-4 text-xs font-mono text-right text-gray-600"
								>
									<div className="flex flex-col">
										{showResponseCount && colValue.count.toLocaleString()}

										<div className="flex flex-col">
											{showTotalPercentage &&
												colValue.totalPercentage !== undefined && (
													<span>{colValue.totalPercentage.toFixed(2)}%</span>
												)}

											{showRowPercentage &&
												colValue.rowPercentage !== undefined && (
													<span>{colValue.rowPercentage.toFixed(2)}%</span>
												)}

											{showColumnPercentage &&
												colValue.columnPercentage !== undefined && (
													<span>{colValue.columnPercentage.toFixed(2)}%</span>
												)}
										</div>
									</div>
								</td>
							))}
						</tr>
					);
				});
			})}
		</Fragment>
	);
}

export default function AnalysisCrossTabTable({ translations, language }) {
	const { nanoid } = useParams();
	const { t } = useTranslation();
	const [showResponseCount, setShowResponseCount] = useState(true);
	const [showTotalPercentage, setShowTotalPercentage] = useState(false);
	const [showRowPercentage, setShowRowPercentage] = useState(false);
	const [showColumnPercentage, setShowColumnPercentage] = useState(false);

	const crossTabTableData = useSelector(state =>
		state.analysis.projects[nanoid]
			? state.analysis.projects[nanoid].crossTab.tableData
			: [],
	);

	const crossTabColumns = useSelector(state =>
		state.analysis.projects[nanoid]
			? state.analysis.projects[nanoid].crossTab.columns
			: [],
	);

	const rowTabColumns = useSelector(state =>
		state.analysis.projects[nanoid]
			? state.analysis.projects[nanoid].crossTab.rows
			: [],
	);

	const rankingQuestionExistsinRow =
		rowTabColumns.findIndex(row => row.question_type === 'ranking') > -1
			? true
			: false;

	const tableHeaders = [];
	let rankingHeaders = [];
	let rankingQuestionExistsInColumn = false;

	crossTabColumns.forEach(col => {
		if (col.question_type && col.question_type === 'ranking') {
			rankingQuestionExistsInColumn = true;
		}

		col.categoryfield_set.forEach(choice => {
			let choiceColSpan = 1;
			if (col.question_type === 'ranking') {
				choiceColSpan = col.categoryfield_set.length;
			}
			tableHeaders.push({
				nanoid: choice.nanoid,
				name: choice.name,
				choiceColSpan,
			});

			if (col.question_type === 'ranking') {
				Array.from({ length: col.categoryfield_set.length }, (_, i) => {
					const name = `#${i + 1}`;
					rankingHeaders.push({ name });
				});
			} else {
				rankingHeaders.push({ name: '' });
			}
		});

		tableHeaders.push({
			name: t('Total'),
		});
		rankingHeaders.push({ name: '' });
	});

	if (crossTabTableData.length === 0 || crossTabColumns.length === 0) {
		return <EmptyState className={'mt-10'} />;
	}
	return (
		<>
			<div className=" ml-1.5 mt-2 flex justify-between w-full">
				<div className="flex items-center">
					<div className="flex gap-4">
						<div className="flex">
							<div className="flex h-5 items-center">
								<input
									id="showResponses"
									name="showResponses"
									type="checkbox"
									className="disabled:opacity-30 disabled:cursor-not-allowed focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									checked={showResponseCount}
									disabled={
										showResponseCount &&
										!showTotalPercentage &&
										!showRowPercentage &&
										!showColumnPercentage
									}
									onChange={e => setShowResponseCount(e.target.checked)}
								/>
							</div>
							<div className="ml-1 text-sm">
								<label htmlFor={'showResponses'} className="text-gray-700">
									{t('analysis.crossTab.showResponseCount')}
								</label>
							</div>
						</div>

						<div className="flex">
							<div className="flex items-center">
								<input
									id="showTotalPercentage"
									name="showTotalPercentage"
									type="checkbox"
									className="disabled:opacity-30 disabled:cursor-not-allowed focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									checked={showTotalPercentage}
									disabled={
										!showResponseCount &&
										showTotalPercentage &&
										!showRowPercentage &&
										!showColumnPercentage
									}
									onChange={e => setShowTotalPercentage(e.target.checked)}
								/>
							</div>
							<div className="ml-1 text-sm">
								<label
									htmlFor={'showTotalPercentage'}
									className="text-gray-700"
								>
									{t('analysis.crossTab.showTotalPercentage')}
								</label>
							</div>
						</div>

						<div className="flex">
							<div className="flex h-5 items-center">
								<input
									id="showRowPercentage"
									name="showRowPercentage"
									type="checkbox"
									className="disabled:opacity-30 disabled:cursor-not-allowed focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									checked={showRowPercentage}
									disabled={
										!showResponseCount &&
										!showTotalPercentage &&
										showRowPercentage &&
										!showColumnPercentage
									}
									onChange={e => setShowRowPercentage(e.target.checked)}
								/>
							</div>
							<div className="ml-1 text-sm">
								<label htmlFor={'showRowPercentage'} className="text-gray-700">
									{t('analysis.crossTab.showRowPercentage')}
								</label>
							</div>
						</div>

						<div className="flex">
							<div className="flex h-5 items-center">
								<input
									id="showColumnPercentage"
									name="showColumnPercentage"
									type="checkbox"
									className="disabled:opacity-30 disabled:cursor-not-allowed focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									checked={showColumnPercentage}
									disabled={
										!showResponseCount &&
										!showTotalPercentage &&
										!showRowPercentage &&
										showColumnPercentage
									}
									onChange={e => setShowColumnPercentage(e.target.checked)}
								/>
							</div>
							<div className="ml-1 text-sm">
								<label
									htmlFor={'showColumnPercentage'}
									className="text-gray-700"
								>
									{t('analysis.crossTab.showColumnPercentage')}
								</label>
							</div>
						</div>
					</div>
				</div>

				<Buttons
					measure="small"
					kind="white"
					onClick={() => {
						var tbl = document.getElementById('crossTabTable');
						var wb = XLSX.utils.table_to_book(tbl);
						XLSX.writeFile(wb, 'Crosstab.xlsx');
					}}
				>
					<CloudArrowDownIcon className="w-4" />{' '}
					{t('analysis.crossTab.exportToExcel')}
				</Buttons>
			</div>
			<div className="overflow-x-auto mb-40 mt-6">
				<div className="inline-block min-w-full align-middle px-1 pt-0.5 pb-4">
					<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<table
							id="crossTabTable"
							className="min-w-full divide-y divide-gray-300 table-fixed"
						>
							<thead className="bg-gray-50 divide-y divide-gray-200">
								<tr className="divide-x divide-gray-200">
									<th
										colSpan={rankingQuestionExistsinRow ? 3 : 2}
										rowSpan={rankingQuestionExistsInColumn ? 3 : 2}
									>
										&nbsp;
										<div className="flex flex-col text-left text-xs font-semibold text-gray-600 px-4 mb-2">
											{showResponseCount && (
												<span>{t('crossTabTableKeys.count')} </span>
											)}

											{showTotalPercentage && (
												<span>{t('crossTabTableKeys.percentOfTotal')} </span>
											)}

											{showRowPercentage && (
												<span>{t('crossTabTableKeys.percentOfRow')} </span>
											)}

											{showColumnPercentage && (
												<span>{t('crossTabTableKeys.percentOfColumn')} </span>
											)}
										</div>
									</th>
									{crossTabColumns.map((col, index) => (
										<th
											key={index}
											scope="col"
											colSpan={
												col.question_type === 'ranking'
													? col.categoryfield_set.length *
															col.categoryfield_set.length +
													  1
													: col.categoryfield_set.length + 1
											}
											className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-600"
										>
											{getTranslatedQuestionText(
												translations,
												language,
												col.nanoid,
												col.name,
											)}
										</th>
									))}
								</tr>
								<tr className="divide-x divide-gray-200">
									{tableHeaders.map((col, i) => (
										<th
											key={i}
											scope="col"
											colSpan={col.choiceColSpan}
											className="py-2 pl-4 pr-3 text-left text-xs font-semibold text-gray-600 border-l border-gray-200"
										>
											{getTranslatedQuestionText(
												translations,
												language,
												col.nanoid,
												col.name,
											)}
										</th>
									))}
								</tr>
								{rankingQuestionExistsInColumn && (
									<tr className="divide-x divide-gray-200">
										{rankingHeaders.map((col, i) => (
											<th
												key={i}
												scope="col"
												className="py-2 pl-4 pr-3 text-left text-xs font-semibold text-gray-600 border-l border-gray-200"
											>
												{col.name}
											</th>
										))}
									</tr>
								)}
							</thead>
							<tbody className="divide-y divide-gray-200 ">
								{crossTabTableData.map((row, index) =>
									row.question_type === 'ranking' ? (
										<RankingTableRow
											key={index}
											row={row}
											language={language}
											translations={translations}
											showResponseCount={showResponseCount}
											showTotalPercentage={showTotalPercentage}
											showRowPercentage={showRowPercentage}
											showColumnPercentage={showColumnPercentage}
										/>
									) : (
										<TableRow
											row={row}
											key={index}
											language={language}
											translations={translations}
											rankingExistsinRow={rankingQuestionExistsinRow}
											showResponseCount={showResponseCount}
											showTotalPercentage={showTotalPercentage}
											showRowPercentage={showRowPercentage}
											showColumnPercentage={showColumnPercentage}
										/>
									),
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { setDocumentTitle } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
	applyComparisonItem,
	applyFilter,
	loadAnalysisDataStart,
	removeComparisonItem,
	removeFilter,
} from '../../../store/analysisSlice';
import { sendGoogleEvent } from '../../../utils/analytics';
import { filter, tidy } from '@tidyjs/tidy';
import { classNames } from '../../../utils';
import AnalysisQuestion from '../../../components/AnalysisQuestion';
import Badge from '../../../components/Badge';
import AnalysisFilterCompareOptions from '../../../components/AnalysisFilterCompareOptions';
import FeedbackSlideover from '../../../components/FeedbackSlideover';

import TourGuide from '../../../components/TourGuide';
import AnalysisHeader from '../../../components/AnalysisHeader';
import SlideOver from '../../../components/SlideOver';
import { toggleProjectFeedbackSlideOver } from '../../../store/projectSlice';

export default function DefaultAnalysisPage() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const urlRating = searchParams.get('rating');

	const { nanoid } = useParams();

	const { t } = useTranslation();

	const isFeedbackOpen = useSelector(
		state => state.project.projectFeedback.isSlideOverOpen,
	);

	const [currentTab, setCurrentTab] = useState('filter');
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isCompareOpen, setIsCompareOpen] = useState(false);

	const tabs = [
		{
			name: t('Filter'),
			key: 'filter',
			className: 'tour-analysis-filter',
			current: currentTab === 'filter',
		},
		{
			name: t('Compare'),
			key: 'compare',
			className: 'tour-analysis-compare',
			current: currentTab === 'compare',
		},
	];

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);
	const rawData = projectAnalysis ? projectAnalysis.rawData : [];
	const filteredData = projectAnalysis ? projectAnalysis.filteredData : [];

	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const availableFilterOptions = projectAnalysis
		? projectAnalysis.filterData.availableFilterOptions
		: {};

	const appliedFilters = projectAnalysis
		? projectAnalysis.filterData.appliedFilters
		: {};

	const appliedFilterCount = projectAnalysis
		? projectAnalysis.filterData.appliedFilterCount
		: 0;

	const appliedComparisonItems = projectAnalysis
		? projectAnalysis.comparisonData.appliedComparisonItems
		: {};

	const appliedComparisonItemCount = projectAnalysis
		? projectAnalysis.comparisonData.appliedComparisonItemCount
		: 0;

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.analysis'));
	}, [t]);

	useEffect(() => {
		dispatch(
			loadAnalysisDataStart({
				projectNanoId: nanoid,
			}),
		);
	}, [dispatch, nanoid]);

	useEffect(() => {
		if (urlRating !== null) {
			dispatch(toggleProjectFeedbackSlideOver(true));
		}
	}, [urlRating, dispatch]);

	const toggleFeedbackSlideOver = bool => {
		dispatch(toggleProjectFeedbackSlideOver(bool));
	};

	const handleAppliedFiltersChange = ({
		filterItem,
		filterField,
		rank,
		selected,
	}) => {
		if (selected) {
			dispatch(
				applyFilter({
					projectNanoId: nanoid,
					filterItem: filterItem,
					filterField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_filter_add', {
				filter: filterItem,
				option: filterField.name,
			});
		} else {
			dispatch(
				removeFilter({
					projectNanoId: nanoid,
					filterItem: filterItem,
					filterField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_filter_remove', {
				filter: filterItem,
				option: filterField.name,
			});
		}
	};

	const handleAppliedComparisonItemsChange = ({
		filterItem,
		filterField,
		rank,
		selected,
	}) => {
		if (selected) {
			dispatch(
				applyComparisonItem({
					projectNanoId: nanoid,
					comparisonItem: filterItem,
					comparisonField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_comparison_add', {
				filter: filterItem,
				option: filterField.name,
			});
		} else {
			dispatch(
				removeComparisonItem({
					projectNanoId: nanoid,
					comparisonItem: filterItem,
					comparisonField: filterField,
					rank,
				}),
			);
			sendGoogleEvent('analysis_comparison_remove', {
				filter: filterItem,
				option: filterField.name,
			});
		}
	};

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.analysis.reporting.title'),
			content: t('tour.content.analysis.reporting.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-progress',
			placement: 'auto',
			title: t('tour.content.analysis.responseProgress.title'),
			content: t('tour.content.analysis.responseProgress.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-export',
			placement: 'auto',
			title: t('tour.content.analysis.export.title'),
			content: t('tour.content.analysis.export.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-question',
			placement: 'auto',
			title: t('tour.content.analysis.questions.title'),
			content: t('tour.content.analysis.questions.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-filter',
			placement: 'auto',
			title: t('tour.content.analysis.filters.title'),
			content: t('tour.content.analysis.filters.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-compare',
			placement: 'auto',
			title: t('tour.content.analysis.compare.title'),
			content: t('tour.content.analysis.compare.content'),
			disableBeacon: true,
		},
	];

	const filteredResponsesPerQuestion = {};
	const rawResponsesPerQuestion = {};
	questionList.forEach(question => {
		filteredResponsesPerQuestion[question.nanoid] = tidy(
			filteredData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
		rawResponsesPerQuestion[question.nanoid] = tidy(
			rawData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
	});

	if (!projectAnalysis) {
		return <></>;
	}

	return (
		<>
			<div className="flex">
				<div className="w-1/4 hidden md:flex flex-col h-screen bg-white shadow-md z-20">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex" aria-label="Tabs">
							{tabs.map(tab => (
								<button
									key={tab.name}
									onClick={() => setCurrentTab(tab.key)}
									className={classNames(
										tab.className ? tab.className : '',
										tab.current
											? 'border-indigo-500 text-indigo-600'
											: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
										'w-1/2 h-14 px-1 text-center border-b-2 font-medium text-sm',
									)}
									aria-current={tab.current ? 'page' : undefined}
								>
									{tab.name}{' '}
									{tab.key === 'filter' && appliedFilterCount > 0 && (
										<Badge type={'complete'}>{appliedFilterCount}</Badge>
									)}
									{tab.key === 'compare' && appliedComparisonItemCount > 0 && (
										<Badge type={'complete'}>
											{appliedComparisonItemCount}
										</Badge>
									)}
								</button>
							))}
						</nav>
					</div>

					<div className="grow overflow-y-auto p-3 mb-10">
						{currentTab === 'filter' && (
							<AnalysisFilterCompareOptions
								mode={'filter'}
								availableOptions={availableFilterOptions}
								selectedOptions={appliedFilters}
								handleSelectedOptionChange={handleAppliedFiltersChange}
								translations={translations}
								language={displayLanguage}
								nanoid={nanoid}
							/>
						)}
						{currentTab === 'compare' && (
							<AnalysisFilterCompareOptions
								mode={'compare'}
								availableOptions={availableFilterOptions}
								selectedOptions={appliedComparisonItems}
								handleSelectedOptionChange={handleAppliedComparisonItemsChange}
								translations={translations}
								language={displayLanguage}
								nanoid={nanoid}
							/>
						)}
					</div>
				</div>
				<div className="grow overflow-y-auto  md:hidden">
					{currentTab === 'filter' && isFilterOpen && (
						<>
							<SlideOver
								title={t('Filter')}
								open={isFilterOpen}
								setOpen={setIsFilterOpen}
							>
								<AnalysisFilterCompareOptions
									mode={'filter'}
									availableOptions={availableFilterOptions}
									selectedOptions={appliedFilters}
									handleSelectedOptionChange={handleAppliedFiltersChange}
									translations={translations}
									language={displayLanguage}
									nanoid={nanoid}
								/>
							</SlideOver>
						</>
					)}
					{currentTab === 'compare' && isCompareOpen && (
						<>
							<SlideOver
								title={t('Compare')}
								open={isCompareOpen}
								setOpen={setIsCompareOpen}
							>
								<AnalysisFilterCompareOptions
									mode={'compare'}
									availableOptions={availableFilterOptions}
									selectedOptions={appliedComparisonItems}
									handleSelectedOptionChange={
										handleAppliedComparisonItemsChange
									}
									translations={translations}
									language={displayLanguage}
									nanoid={nanoid}
								/>
							</SlideOver>
						</>
					)}
				</div>

				<FeedbackSlideover
					isFeedbackOpen={isFeedbackOpen}
					toggleFeedbackSlideOver={toggleFeedbackSlideOver}
				/>

				<div className="w-full relative h-screen overflow-y-auto">
					<div className="bg-white flex flex-row items-center w-full pl-4 pr-7 pb-4 md:pb-0 shadow-sm md:h-14 z-20 border-b border-gray-100 sticky top-0">
						<AnalysisHeader
							selectedTab={'questions'}
							rawData={rawData}
							filteredData={filteredData}
							hasFilter={appliedFilterCount !== 0}
							translations={translations}
							language={displayLanguage}
							setIsFilterOpen={setIsFilterOpen}
							setIsCompareOpen={setIsCompareOpen}
							setCurrentTab={setCurrentTab}
							appliedFilterCount={appliedFilterCount}
							appliedComparisonItemCount={appliedComparisonItemCount}
						/>
					</div>
					<div className="mx-auto pt-4 max-w-4xl flex flex-col gap-4">
						{questionList.map((question, index) => (
							<AnalysisQuestion
								key={question.nanoid}
								translations={translations}
								language={displayLanguage}
								filteredData={filteredResponsesPerQuestion[question.nanoid]}
								index={index + 1}
								question={question}
								responseCount={rawResponsesPerQuestion[question.nanoid].length}
								filteredResponseCount={
									filteredResponsesPerQuestion[question.nanoid].length
								}
							/>
						))}
					</div>
					<div className="mx-auto pt-4 max-w-4xl mb-40 text-xs text-gray-400 px-4 sm:px-0">
						{t('analysis.disclaimer')}
					</div>
				</div>
			</div>
			<TourGuide tourName={'analysisPage'} steps={steps} />
		</>
	);
}

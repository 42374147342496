import {
	updateRuleTarget,
	addCondition,
	deleteRule,
	updateRuleOperator,
} from '../../store/projectEditorSlice';
import { useDispatch, useSelector } from 'react-redux';
import Listbox from '../Listbox';
import RuleCondition from '../RuleCondition';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../utils/analytics';
import Tabs from '../Tabs';

export default function RulePill({ question, rule, index, isEditable = true }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	// TODO: Questionnaire.nanoid should be Questionnaire.nanoId
	const questionnaireNanoId = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);

	const eligibleTargetList = [];

	let qIndex = 0;
	_.forEach(questionList, _question => {
		qIndex += 1;

		// BUSINESS LOGIC: Do not allow rule to go to the same question
		if (_question.nanoid !== question.nanoid)
			eligibleTargetList.push({
				value: _question.nanoid,
				label: `Q${qIndex}: ${_question.title}`,
			});
	});

	eligibleTargetList.push({ value: 'end_survey', label: t('End of Survey') });

	let ruleTargetDropdownItem = null;
	if (rule.then.action === 'end_survey') {
		ruleTargetDropdownItem = 'end_survey';
	} else if (rule.then.action === 'go_to_question') {
		ruleTargetDropdownItem = rule.then.question;
	}

	return (
		<>
			<div className="tour-logic-designer-rule bg-white shadow rounded-lg border-2 divide-y divide-gray-200 border-t-8 border-t-indigo-600">
				<div className="px-4 py-3 flex justify-between group">
					<h3
						className="leading-6 font-medium text-gray-900"
						title={rule.nanoid}
					>
						{t('Rule {number}', { number: index + 1 })}
					</h3>
					<button
						type="button"
						disabled={!isEditable}
						className="transition-opacity ease-in-out opacity-0 group-hover:opacity-100 p-1 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
						onClick={() => {
							dispatch(
								deleteRule({
									rule: rule,
									question: question,
									index: index,
								}),
							);
							sendGoogleEvent('questionnaire_question_logic_rule_remove', {
								question_type: question.question_type,
							});
						}}
					>
						{t('Remove')}
					</button>
				</div>
				<div className="py-2 px-2">
					{rule.conditions.map((condition, conditionIndex) => (
						<div key={conditionIndex}>
							<div className="tour-logic-designer-condition bg-gray-100 mt-2 p-2 rounded-md">
								<RuleCondition
									question={question}
									rule={rule}
									index={conditionIndex}
									isEditable={isEditable}
									condition={condition}
									canDelete={rule.conditions.length > 1}
								/>
							</div>
							{conditionIndex < rule.conditions.length - 1 && (
								<div className="flex justify-around">
									<Tabs
										size="xs"
										tabs={[
											{ key: 'and', title: t('AND') },
											{ key: 'or', title: t('OR') },
										]}
										className={'mt-2'}
										selectedTab={rule.operator}
										onChange={newOperator => {
											dispatch(
												updateRuleOperator({
													question: question,
													index: index,
													rule: rule,
													operator: newOperator,
												}),
											);
										}}
									/>
								</div>
							)}
						</div>
					))}
					<button
						type="button"
						disabled={!isEditable}
						className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={() => {
							dispatch(
								addCondition({
									questionnaireNanoId: questionnaireNanoId,
									question: question,
									rule: rule,
								}),
							);
							sendGoogleEvent('questionnaire_question_logic_condition_add', {
								question_type: question.question_type,
							});
						}}
					>
						{t('Add Condition')}
					</button>
				</div>
				<div className="px-2 py-4">
					<Listbox
						className="tour-logic-designer-condition-action"
						label={t('Then go to')}
						items={eligibleTargetList}
						value={ruleTargetDropdownItem}
						isEditable={isEditable}
						onChange={v => {
							const then = {
								action: v === 'end_survey' ? v : 'go_to_question',
								question: null,
							};

							if (v !== 'end_survey') {
								then['question'] = v;
							}

							dispatch(
								updateRuleTarget({
									question: question,
									rule: rule,
									index: index,
									then: then,
								}),
							);
						}}
					/>
				</div>
			</div>
		</>
	);
}

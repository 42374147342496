import { getInitialNameLetter } from '../../pages/Account/ProfilePage/constant';
import { classNames } from '../../utils';

export default function ProfilePicture({
	firstName,
	lastName,
	profilePicture,
	size,
}) {
	return (
		<div
			className={classNames(
				size === 'xs' ? 'w-6 h-6' : '',
				size === 'mid' ? 'w-12 h-12' : '',
				size !== 'xs' && size !== 'mid' ? 'w-8 h-8' : '',
			)}
			title={`${firstName} ${lastName}`}
		>
			{!profilePicture && (
				<div
					className={classNames(
						size === 'xs' ? 'w-6 h-6' : '',
						size === 'mid' ? 'w-12 h-12' : '',
						size !== 'xs' && size !== 'mid' ? 'w-8 h-8' : '',
						'bg-gray-700 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white',
					)}
				>
					<p
						className={classNames(
							size === 'xs' ? ' text-xs' : '',
							size === 'mid' ? 'text-xl' : '',
							size !== 'xs' && size !== 'mid' ? 'text-sm' : '',
							'text-white w-full text-center',
						)}
					>
						{getInitialNameLetter(firstName, lastName)}
					</p>
				</div>
			)}
			{profilePicture && (
				<img
					className="w-full h-full rounded-full"
					src={profilePicture}
					alt=""
				/>
			)}
		</div>
	);
}

import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import FilterPill from './FilterPill';

export default function FilterList({
	filters,
	droppableId,
	translations,
	language,
	isAddingEnabled = true,
	limit = null,
}) {
	const { t } = useTranslation();

	const isDropDisabled = limit && filters.length >= limit ? true : false;

	return (
		<div className="flex flex-col gap-2 mr-1">
			<Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
				{provided => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						<div className="grid grid-rows-1 gap-1.5">
							{filters.map((filter, index) => (
								<FilterPill
									key={filter.nanoid}
									filter={filter}
									index={index}
									translations={translations}
									language={language}
								/>
							))}
							{!isDropDisabled && isAddingEnabled && (
								<div className="text-center text-xs text-gray-500 border border-dashed w-full py-2 rounded-sm border-gray-400">
									{t('analysis.crossTab.dropDimension')}
								</div>
							)}
							{provided.placeholder}
						</div>
					</div>
				)}
			</Droppable>
		</div>
	);
}

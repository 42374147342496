import ChoiceList from './ChoiceList';

const MultipleSelectionQuestion = ({
	question,
	answers,
	questionList,
	isEditable = true,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	isScreeningQuestion,
	handleChoiceQualifyChange,
}) => {
	return (
		<ChoiceList
			question={question}
			answers={answers}
			questionList={questionList}
			isEditable={isEditable}
			handleReorderChoiceList={handleReorderChoiceList}
			handleCreateChoice={handleCreateChoice}
			handleChoiceTitleChange={handleChoiceTitleChange}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			handleDeleteChoice={handleDeleteChoice}
			isScreeningQuestion={isScreeningQuestion}
			handleChoiceQualifyChange={handleChoiceQualifyChange}
		/>
	);
};

export default MultipleSelectionQuestion;

import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { getTranslatedQuestionText } from '../../utils/Translation';

function Icon({ className }) {
	return (
		<svg
			className={className}
			width="6"
			height="14"
			viewBox="0 0 12 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="4" height="4" fill="#9ca3af" />
			<rect x="8" width="4" height="4" fill="#9ca3af" />
			<rect y="8" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="8" width="4" height="4" fill="#9ca3af" />
			<rect y="16" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="16" width="4" height="4" fill="#9ca3af" />
			<rect y="24" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="24" width="4" height="4" fill="#9ca3af" />
		</svg>
	);
}

export default function FilterPill({ filter, index, translations, language }) {
	const { t } = useTranslation();

	return (
		<Draggable draggableId={filter.nanoid} index={index}>
			{provided => (
				<div
					ref={provided.innerRef}
					className={classNames(
						// Differentiate question filters from audience ones
						filter.type === 'question'
							? 'border-l-orange-600'
							: 'border-l-indigo-600',
						'flex gap-2 py-2 px-1.5 text-xs text-gray-500 bg-gray-100 rounded-sm border border-l-4  border-gray-300 cursor-grab hover:shadow transition-all',
					)}
					{...provided.dragHandleProps}
					{...provided.draggableProps}
				>
					<Icon className="shrink-0" />
					<span>
						{getTranslatedQuestionText(
							translations,
							language,
							filter.nanoid,
							filter.name ? filter.name : t('common.emptyString'),
						)}
					</span>
				</div>
			)}
		</Draggable>
	);
}

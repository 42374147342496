import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import { DragDropContext } from 'react-beautiful-dnd';
import {
	addCrossTabColumn,
	addCrossTabRow,
	moveCrossTabFilterItemBetweenRowsAndColumns,
	removeCrossTabColumn,
	removeCrossTabRow,
	swapCrossTabRowsAndColumns,
} from '../../store/analysisSlice';
import FilterList from './FilterList';
import { useParams } from 'react-router-dom';
import { selectProjectFilterCategories } from '../../utils';

export default function AnalysisCrossTabSettings({ translations, language }) {
	const { nanoid } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const project = useSelector(state => state.analysis.projects[nanoid]);
	const crossTabRows = useSelector(state =>
		state.analysis.projects[nanoid]
			? state.analysis.projects[nanoid].crossTab.rows
			: [],
	);
	const crossTabColumns = useSelector(state =>
		state.analysis.projects[nanoid]
			? state.analysis.projects[nanoid].crossTab.columns
			: [],
	);

	const _filterOptions = [];

	project?.filterData.availableFilterOptions.forEach(section => {
		section.category_set.forEach(category => {
			const foundIndexRows = crossTabRows.findIndex(element => {
				return element.nanoid === category.nanoid;
			});

			if (foundIndexRows < 0) {
				const foundIndexColumns = crossTabColumns.findIndex(element => {
					return element.nanoid === category.nanoid;
				});

				if (foundIndexColumns < 0) {
					_filterOptions.push(category);
				}
			}
		});
	});

	const filterOptions = _filterOptions.filter(filter =>
		selectProjectFilterCategories(nanoid, filter.slug),
	);

	const swapRowsAndColumns = () => {
		dispatch(swapCrossTabRowsAndColumns({ projectNanoId: nanoid }));
	};

	const handleDrop = handleDrop => {
		const sourceIndex = handleDrop.source?.index;
		const sourceDroppable = handleDrop.source?.droppableId;
		const destIndex = handleDrop.destination?.index;
		const destDroppable = handleDrop.destination?.droppableId;

		if (sourceDroppable === 'dimensions' && destDroppable === 'dimensions') {
			return false;
		}

		if (sourceDroppable === destDroppable && sourceIndex === destIndex) {
			return false;
		}

		// Moving filter items from the dimension list to either rows or columns
		if (sourceDroppable === 'dimensions') {
			if (destDroppable === 'rows') {
				dispatch(
					addCrossTabRow({
						destinationIndex: handleDrop.destination.index,
						filterObject: filterOptions[handleDrop.source.index],
						projectNanoId: nanoid,
					}),
				);
				return true;
			} else if (destDroppable === 'columns') {
				dispatch(
					addCrossTabColumn({
						destinationIndex: handleDrop.destination.index,
						filterObject: filterOptions[handleDrop.source.index],
						projectNanoId: nanoid,
					}),
				);
				return true;
			}
		}

		// Moving filter items from either the row list or the column list to the dimension list
		if (destDroppable === 'dimensions') {
			if (sourceDroppable === 'rows') {
				dispatch(
					removeCrossTabRow({
						index: handleDrop.source.index,
						projectNanoId: nanoid,
					}),
				);
				return true;
			} else if (sourceDroppable === 'columns') {
				dispatch(
					removeCrossTabColumn({
						index: handleDrop.source.index,
						projectNanoId: nanoid,
					}),
				);
				return true;
			}
		}

		// Moving between rows and columns or re-ordering in the same list
		if (
			(sourceDroppable === 'rows' || sourceDroppable === 'columns') &&
			(destDroppable === 'rows' || destDroppable === 'columns')
		) {
			dispatch(
				moveCrossTabFilterItemBetweenRowsAndColumns({
					source: sourceDroppable,
					dest: destDroppable,
					sourceIndex: sourceIndex,
					destIndex: destIndex,
					projectNanoId: nanoid,
				}),
			);

			return true;
		}
	};

	return (
		<>
			<DragDropContext onDragEnd={handleDrop}>
				<div className="w-1/4 h-screen flex flex-col bg-white border-r">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex" aria-label="Tabs">
							<button
								className={classNames(
									'border-indigo-500 text-indigo-600',
									'w-full h-14 px-1 text-center border-b-2 font-medium text-sm',
								)}
							>
								{t('analysis.crossTab.dimensions')}
							</button>
						</nav>
					</div>
					<div className="overflow-y-auto p-3">
						<div className="mb-40">
							<FilterList
								filters={filterOptions}
								droppableId="dimensions"
								isAddingEnabled={false}
								translations={translations}
								language={language}
							/>
						</div>
					</div>
				</div>
				<div className="w-1/4 h-screen flex flex-col bg-white shadow-md z-10">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex" aria-label="Tabs">
							<button
								className={classNames(
									'border-indigo-500 text-indigo-600',
									'w-full h-14 px-1 text-center border-b-2 font-medium text-sm',
								)}
							>
								{t('analysis.crossTab.tableSettings')}
							</button>
						</nav>
					</div>
					<div className="overflow-y-auto p-3">
						<div className="mb-40 flex flex-col gap-4">
							<div className="flex flex-col gap-2 mr-1">
								<div className="text-xs font-medium uppercase text-gray-500">
									{t('analysis.crossTab.rows')}
								</div>
								<FilterList
									filters={crossTabRows}
									droppableId="rows"
									limit={10}
									translations={translations}
									language={language}
								/>
							</div>
							<button
								disabled={
									crossTabColumns.length === 0 && crossTabRows.length === 0
								}
								className="mx-auto flex flex-row items-center justify-center text-xs text-gray-500 disabled:opacity-30 disabled:pointer-events-none"
								onClick={swapRowsAndColumns}
							>
								<ArrowsUpDownIcon className="h-5 w-5 text-gray-500 mr-1" />
								<p className=" mr-1">{t('analysis.crossTab.swapButtonText')}</p>
							</button>
							<div className="flex flex-col gap-2 mr-1">
								<div className="text-xs font-medium uppercase text-gray-500">
									{t('analysis.crossTab.columns')}
								</div>
								<FilterList
									filters={crossTabColumns}
									droppableId="columns"
									limit={10}
									translations={translations}
									language={language}
								/>
							</div>
						</div>
					</div>
				</div>
			</DragDropContext>
		</>
	);
}

import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormInput } from '../TextInput';
import * as Yup from 'yup';
import Combobox from '../Combobox';
import { find } from 'lodash';
import RadioGroup from '../RadioGroup';
import { restrictInputType } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadCitiesStart,
	loadDistrictsStart,
} from '../../store/addressReducer';
import { useTranslation } from 'react-i18next';

const addressTypes = [
	{
		id: 'commercial',
		title: 'Commercial', //t('Commercial')
		description: 'commercialDesc', //t('commercialDesc')
	},
	{
		id: 'personal',
		title: 'Personal', //t('Personal')
		description: 'personalDesc', //t('personalDesc')
	},
];

const phoneNumberRegExp =
	/^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;

const identityNumberRegExp = /^[1-9]{1}[0-9]{9}[02468]{1}$/;

const postCodeRegExp = /^\b\d{5}\b/;

const AddressFormValidationSchema = Yup.object().shape({
	title: Yup.string().max(150, 'Too Long!').required('Required'), //t('Too Long!)
	first_name: Yup.string().max(150, 'Too Long!').required('Required'), //t('Required')
	last_name: Yup.string().max(150, 'Too Long!').required('Required'),
	email: Yup.string().email('Invalid email').required('Required'), //t('Invalid email')
	city: Yup.object({
		nanoid: Yup.string().required('Please select a city'), //t('Please select a city')
	}),
	district: Yup.object({
		nanoid: Yup.string().required('Please select a district'), //t('Please select a district')
	}),
	line: Yup.string().max(255, 'Too long!').required('Required'),
	post_code: Yup.string()
		.matches(postCodeRegExp, 'Invalid post code') //t('Invalid post code')
		.required('Required'),
	phone_number: Yup.string()
		.matches(phoneNumberRegExp, 'Invalid phone number') //t('Invalid phone number')
		.required('Required'),
	identity_number: Yup.string().when('address_type', {
		is: 'personal',
		then: Yup.string()
			.required('Required')
			.matches(identityNumberRegExp, 'Invalid identity number'), //t('Invalid identity number')
		otherwise: Yup.string().notRequired(),
	}),
	company_name: Yup.string().when('address_type', {
		is: 'commercial',
		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
	tax_office: Yup.string().when('address_type', {
		is: 'commercial',
		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
	tax_no: Yup.string().when('address_type', {
		is: 'commercial',
		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
});

export default function AddressForm({ address, isLoading, handleAddressSave }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const isCitiesLoading = useSelector(
		state => state.addresses.cities.isLoading,
	);
	const isDistrictsLoading = useSelector(
		state => state.addresses.districts.isLoading,
	);
	const citiesDistrictsLoading = isCitiesLoading || isDistrictsLoading;

	const cities = useSelector(state => state.addresses.cities.cityList);
	const cityDistrictList = useSelector(
		state => state.addresses.districts.cityDistrictList,
	);

	const [initialValues, setInitialValues] = useState();

	useEffect(() => {
		if (cities.length === 0) {
			dispatch(loadCitiesStart());
		}
	}, [cities.length, dispatch]);

	useEffect(() => {
		if (cities.length > 0) {
			const cityNanoIdToLoad = address ? address.city.nanoid : cities[0].nanoid;
			dispatch(loadDistrictsStart({ cityNanoId: cityNanoIdToLoad }));
		}
	}, [address, cities, dispatch]);

	useEffect(() => {
		if (address) {
			setInitialValues(address);
		} else {
			if (cities.length > 0) {
				if (cityDistrictList[cities[0].nanoid]) {
					setInitialValues({
						title: '',
						email: '',
						address_type: 'commercial',
						city: cities[0],
						district: cityDistrictList[cities[0].nanoid][0],
						line: '',
						post_code: '',
						phone_number: '',
						company_name: '',
						tax_office: '',
						tax_no: '',
						first_name: '',
						last_name: '',
						identity_number: '',
					});
				}
			}
		}
	}, [address, cities, cityDistrictList]);

	if (!initialValues) {
		return <></>;
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={AddressFormValidationSchema}
			enableReinitialize={true}
			onSubmit={handleAddressSave}
			validateOnChange={true}
			validateOnBlur={false}
		>
			{props => (
				<Form onSubmit={props.handleSubmit}>
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
						<div className="sm:col-span-3">
							<div className="space-y-6">
								<div className="mb-6">
									<FormInput
										id="title"
										name="title"
										type="text"
										label={t('formLabels.addressTitle') + '*'}
										onChange={props.handleChange}
										value={props.values.title}
										errorMessage={props.touched.title && t(props.errors.title)}
									/>
								</div>
								<div className="pb-2">
									<RadioGroup
										label={t('Address type')}
										selectedItem={find(
											addressTypes,
											o => o.id === props.values.address_type,
										)}
										data={addressTypes}
										handleChange={item => {
											props.setFieldValue('address_type', item.id);
										}}
									/>
								</div>
								<div>
									<div className="flex flex-row gap-4">
										<div className="w-full">
											<FormInput
												id="first_name"
												name="first_name"
												type="text"
												label={t('formLabels.firstName') + '*'}
												onChange={e =>
													restrictInputType(e, props.handleChange, 'letter')
												}
												value={props.values.first_name}
												errorMessage={
													props.touched.first_name && t(props.errors.first_name)
												}
											/>
										</div>
										<div className="w-full">
											<FormInput
												id="last_name"
												name="last_name"
												type="text"
												label={t('formLabels.lastName') + '*'}
												onChange={e =>
													restrictInputType(e, props.handleChange, 'letter')
												}
												value={props.values.last_name}
												errorMessage={
													props.touched.last_name && t(props.errors.last_name)
												}
											/>
										</div>
									</div>
								</div>
								<div>
									<FormInput
										id="email"
										name="email"
										type="text"
										label={t('formLabels.email') + '*'}
										onChange={props.handleChange}
										value={props.values.email}
										errorMessage={props.touched.email && t(props.errors.email)}
									/>
								</div>
								<div>
									<div className="flex flex-row gap-4">
										<div className="w-full">
											<Combobox
												label={t('City')}
												type="text"
												data={cities}
												onChange={async item => {
													props.setFieldValue('city', item);

													dispatch(
														loadDistrictsStart({ cityNanoId: item.nanoid }),
													);

													props.setFieldValue('district', {});
												}}
												selectedItem={props.values.city}
												disabled={citiesDistrictsLoading}
											/>
											{props.errors.city && (
												<p
													className="mt-2 text-sm text-red-600"
													id={`city-error`}
												>
													{props.errors.city.nanoid}
												</p>
											)}
										</div>
										<div className="w-full">
											<Combobox
												label={t('District')}
												type="text"
												data={
													cityDistrictList[props.values.city.nanoid]
														? cityDistrictList[props.values.city.nanoid]
														: []
												}
												selectedItem={props.values.district}
												onChange={async item => {
													props.setFieldValue('district', item);
												}}
												disabled={citiesDistrictsLoading}
											/>
											{props.errors.district && props.touched.district && (
												<p
													className="mt-2 text-sm text-red-600"
													id={`district-error`}
												>
													{t('Please select a district')}
												</p>
											)}
										</div>
									</div>
								</div>
								<div>
									<FormInput
										id="line"
										name="line"
										type="text"
										label={t('formLabels.addressLine') + '*'}
										onChange={props.handleChange}
										value={props.values.line}
										errorMessage={props.touched.line && t(props.errors.line)}
									/>
								</div>
								<div>
									<div className="flex flex-row gap-4">
										<div className="w-full">
											<FormInput
												id="post_code"
												name="post_code"
												type="text"
												label={t('formLabels.postCode') + '*'}
												pattern="[0-9]{5}"
												onChange={e =>
													restrictInputType(e, props.handleChange, 'number')
												}
												value={props.values.post_code}
												errorMessage={
													props.touched.post_code && t(props.errors.post_code)
												}
											/>
										</div>
										<div className="w-full">
											<FormInput
												id="phone_number"
												name="phone_number"
												type="text"
												maxLength={10}
												label={t('formLabels.phoneNumber') + '*'}
												placeholder="5xx xxx xxxx"
												onChange={e =>
													restrictInputType(e, props.handleChange, 'number')
												}
												value={props.values.phone_number}
												errorMessage={
													props.touched.phone_number &&
													t(props.errors.phone_number)
												}
											/>
										</div>
									</div>
								</div>
								{props.values.address_type === 'commercial' && (
									<>
										<div>
											<FormInput
												id="company_name"
												name="company_name"
												type="text"
												label={t('formLabels.companyName') + '*'}
												placeholder={t('formPlaceholders.companyName2')}
												onChange={props.handleChange}
												value={props.values.company_name}
												errorMessage={
													props.touched.company_name &&
													t(props.errors.company_name)
												}
											/>
										</div>
										<div>
											<div className="flex flex-row gap-4">
												<div className="w-full">
													<FormInput
														id="tax_office"
														name="tax_office"
														type="text"
														label={t('formLabels.taxOffice') + '*'}
														onChange={props.handleChange}
														value={props.values.tax_office}
														errorMessage={
															props.touched.tax_office &&
															t(props.errors.tax_office)
														}
													/>
												</div>
												<div className="w-full">
													<FormInput
														id="tax_no"
														name="tax_no"
														type="text"
														label={t('formLabels.taxNo') + '*'}
														onChange={props.handleChange}
														value={props.values.tax_no}
														errorMessage={
															props.touched.tax_no && t(props.errors.tax_no)
														}
													/>
												</div>
											</div>
										</div>
									</>
								)}
								<div>
									{props.values.address_type === 'personal' && (
										<FormInput
											id="identity_number"
											name="identity_number"
											type="text"
											maxLength={11}
											label={t('formLabels.identityNumber') + '*'}
											onChange={e =>
												restrictInputType(e, props.handleChange, 'number')
											}
											value={props.values.identity_number}
											errorMessage={
												props.touched.identity_number &&
												t(props.errors.identity_number)
											}
										/>
									)}
								</div>
								<div className="mb-6">
									<button
										type="submit"
										disabled={props.isSubmitting || isLoading}
										className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									>
										{t('Save')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}

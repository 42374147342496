import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import {
	useParams,
	useNavigate,
	Link,
	useSearchParams,
} from 'react-router-dom';
import { getAddress } from '../../../../api/services/Address';
import AddressForm from '../../../../components/AddressForm';
import { cloneDeep } from 'lodash';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/solid';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearAddressStatus,
	createAddressStart,
	deleteAddressStart,
	updateAddressStart,
} from '../../../../store/addressReducer';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle } from '../../../../utils';

export default function BillingAddressPage({ mode }) {
	const dispatch = useDispatch();
	const { nanoid } = useParams();
	const [searchParams] = useSearchParams();
	const next = searchParams.get('next');

	const [address, setAddress] = useState();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const addressCreationStatus = useSelector(
		state => state.addresses.addressCreationStatus,
	);

	const addressStatus = useSelector(state => state.addresses.addressStatus);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.billingAddress'));
	}, [t]);

	useEffect(() => {
		async function fetchData() {
			const addressResult = await getAddress({ addressNanoId: nanoid });

			setAddress(addressResult);
		}

		if (mode === 'edit') {
			fetchData();
		}
	}, [nanoid, mode]);

	useEffect(() => {
		if (
			addressStatus[nanoid] === 'updated' ||
			addressStatus[nanoid] === 'deleted' ||
			addressCreationStatus === 'created'
		) {
			const messageMap = {
				updated: t('billingPage.billingAddress.messageMap.updated'),
				deleted: t('billingPage.billingAddress.messageMap.deleted'),
			};

			const message =
				addressCreationStatus === 'created'
					? t('billingPage.billingAddress.messageMap.created')
					: messageMap[addressStatus[nanoid]];

			toast.success(message, { title: t('Success') });
			dispatch(clearAddressStatus({ addressNanoId: nanoid }));
			navigate('/account/billing');
		}
	});

	const handleAddressSave = async values => {
		const payload = cloneDeep(values);

		payload['city'] = values.city.nanoid;
		payload['district'] = values.district.nanoid;

		if (mode === 'edit') {
			delete payload['nanoid'];
			delete payload['country'];
			dispatch(
				updateAddressStart({
					addressNanoId: nanoid,
					addressPayload: payload,
				}),
			);
		} else if (mode === 'create') {
			dispatch(createAddressStart({ addressPayload: payload }));
		}
	};

	return (
		<section aria-labelledby="payment-details-heading">
			<div className="shadow sm:rounded-md sm:overflow-hidden">
				<div className="bg-white">
					<div className="border-b border-gray-200 px-4 py-5 sm:px-6">
						<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								{mode === 'create' && (
									<span>{t('billingPage.billingAddress.createAddress')}</span>
								)}
								{mode === 'edit' && (
									<span>{t('billingPage.billingAddress.editAddress')}</span>
								)}
							</h3>

							<div className="ml-4 flex flex-shrink-0">
								<Link
									to={next ? next : '/account/billing'}
									className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									<ArrowLeftIcon
										className="-ml-1 mr-2 h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
									<span>{t('Back')}</span>
								</Link>
								{mode === 'edit' && (
									<button
										type="button"
										className="disabled:cursor-not-allowed disabled:opacity-30 relative ml-3 inline-flex items-center rounded-md border border-red-300 bg-white px-4 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
										disabled={
											addressStatus[nanoid] &&
											addressStatus[nanoid] === 'deleting'
										}
										onClick={() => {
											NiceModal.show(Modal, {
												icon: 'warning',
												title: t('billingPage.billingAddress.modalTitle'),
												message: t('billingPage.billingAddress.modalMessage'),
												showPrimaryButton: true,
												primaryButtonLabel: t(
													'billingPage.billingAddress.modalPrimaryButton',
												),
												secondaryButtonLabel: t(
													'billingPage.billingAddress.modalSecondaryButton',
												),
												onPrimaryButtonClick: async () => {
													dispatch(
														deleteAddressStart({ addressNanoId: nanoid }),
													);
												},
											});
										}}
									>
										<TrashIcon
											className="-ml-1 mr-2 h-5 w-5 text-red-600 hover:text-red-700"
											aria-hidden="true"
										/>
										<span>{t('billingPage.billingAddress.deleteAddress')}</span>
									</button>
								)}
							</div>
						</div>
					</div>
					<div className="w-3/4 px-4 py-6 sm:px-6">
						<AddressForm
							address={address}
							isLoading={
								(addressStatus[nanoid] &&
									addressStatus[nanoid] === 'updating') ||
								addressCreationStatus === 'creating'
							}
							handleAddressSave={handleAddressSave}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

import { tidy, filter } from '@tidyjs/tidy';
import { indexOf } from 'lodash';

function analysisFilter(data, dimension, value) {
	const resultingList = tidy(
		data,
		filter(d => {
			const dataType = typeof d[dimension];
			if (dataType === 'string') {
				// For single choice filter items
				return d[dimension] === value;
			} else if (dataType === 'object') {
				// For multiple choice filter items
				return indexOf(d[dimension], value) > -1;
			}
		}),
	);

	return resultingList;
}

function rankingQuestionRowFilter(data, dimension, value, rank) {
	return data.filter(
		d =>
			d[dimension] &&
			d[dimension].find(el => el.choice === value).order === rank,
	);
}

function rankingQuestionColumnFilter(data, dimension, value, length) {
	const counts = {};
	Array.from({ length: length }, (_, i) => {
		counts[i + 1] = 0;
	});
	data.forEach(d => {
		if (d[dimension]) {
			const filteredValue = d[dimension].find(el => el.choice === value);

			counts[filteredValue.order] = counts[filteredValue.order] + 1;
		}
	});
	return counts;
}

export function createColumnForCrossTabTable(
	arr,
	calculatedValues,
	rowFilterItem,
	choice,
	rowRank,
) {
	const columnValues = [];
	let index = 0;

	arr.crossTab.columns.forEach(columnFilterItem => {
		let total = 0;
		let rankingDivider =
			columnFilterItem.question_type === 'ranking'
				? columnFilterItem.categoryfield_set.length
				: 1;

		columnFilterItem.categoryfield_set.forEach(columnChoice => {
			let rankingLoopLength =
				columnFilterItem.question_type === 'ranking'
					? columnFilterItem.categoryfield_set.length
					: 1;
			//create rank rows for ranking question by looping the number of ranking choices
			Array.from({ length: rankingLoopLength }, (_, i) => {
				let columnRank = (rankingLoopLength =
					columnFilterItem.question_type === 'ranking' ? i + 1 : 0);
				const columnTotal = calculatedValues
					.filter(
						col =>
							col.columnChoiceNanoid === columnChoice.nanoid &&
							col.columnNanoid === columnFilterItem.nanoid &&
							col.rowNanoid === rowFilterItem.nanoid &&
							col.rowRankId === rowRank &&
							col.columnRankId === columnRank,
					)
					.reduce((acc, curr) => acc + curr.count, 0);

				const rowTotal = calculatedValues
					.filter(
						col =>
							col.rowChoiceNanoid === choice.nanoid &&
							col.columnNanoid === columnFilterItem.nanoid &&
							col.rowRankId === rowRank &&
							col.columnRankId === columnRank,
					)
					.reduce((acc, curr) => acc + curr.count, 0);

				let colDivider =
					columnRank === 0 ? 1 : columnFilterItem.categoryfield_set.length;
				let rowDivider =
					rowRank === 0 ? 1 : rowFilterItem.categoryfield_set.length;
				const totalTotal =
					calculatedValues
						.filter(
							col =>
								col.rowNanoid === rowFilterItem.nanoid &&
								col.columnNanoid === columnFilterItem.nanoid,
						)
						.reduce((acc, curr) => acc + curr.count, 0) /
					(rowDivider * colDivider);

				const columnCount = calculatedValues.find(
					col =>
						col.columnChoiceNanoid === columnChoice.nanoid &&
						col.columnNanoid === columnFilterItem.nanoid &&
						col.rowNanoid === rowFilterItem.nanoid &&
						col.rowChoiceNanoid === choice.nanoid &&
						col.columnRankId === columnRank &&
						col.rowRankId === rowRank,
				).count;

				columnValues.push({
					nanoid: `${columnFilterItem.slug}-${columnChoice.nanoid}-${columnRank}`,
					name: `${columnChoice.nanoid}-${columnRank}`,
					count: columnCount,
					totalPercentage: (columnCount / totalTotal) * 100,
					columnPercentage:
						columnCount > 0 ? (columnCount / columnTotal) * 100 : 0,
					rowPercentage: rowTotal > 0 ? (columnCount / rowTotal) * 100 : 0,
				});
				total = total + columnCount;
			});
		});
		columnValues.push({
			nanoid: index++,
			name: 'Total',
			count: total / rankingDivider,
		});
	});
	return columnValues;
}

export function calculateRowColumnData(columns, rows, rawData) {
	let calculatedValues = [];
	columns.forEach(columnFilterItem => {
		columnFilterItem.categoryfield_set.map(columnChoice => {
			rows.forEach(rowFilterItem => {
				rowFilterItem.categoryfield_set.map(rowChoice => {
					let rankingLoopLength =
						rowFilterItem.question_type === 'ranking'
							? rowFilterItem.categoryfield_set.length
							: 1;

					Array.from({ length: rankingLoopLength }, (_, i) => {
						const rowResponses =
							rowFilterItem.question_type === 'ranking'
								? rankingQuestionRowFilter(
										rawData,
										rowFilterItem.slug,
										rowChoice.nanoid,
										parseInt(i + 1),
								  )
								: analysisFilter(rawData, rowFilterItem.slug, rowChoice.nanoid);

						if (columnFilterItem.question_type === 'ranking') {
							const colResponses = rankingQuestionColumnFilter(
								rowResponses,
								columnFilterItem.slug,
								columnChoice.nanoid,
								columnFilterItem.categoryfield_set.length,
							);
							Object.entries(colResponses).map(([key, value]) => {
								calculatedValues.push({
									columnNanoid: columnFilterItem.nanoid,
									columnChoiceNanoid: columnChoice.nanoid,
									rowNanoid: rowFilterItem.nanoid,
									rowChoiceNanoid: rowChoice.nanoid,
									rowRankId:
										rowFilterItem.question_type === 'ranking'
											? parseInt(i + 1)
											: parseInt(0),
									columnRankId: parseInt(key),
									count: value,
								});
							});
						} else {
							const columnResponses = analysisFilter(
								rowResponses,
								columnFilterItem.slug,
								columnChoice.nanoid,
							);

							calculatedValues.push({
								columnNanoid: columnFilterItem.nanoid,
								columnChoiceNanoid: columnChoice.nanoid,
								rowNanoid: rowFilterItem.nanoid,
								rowChoiceNanoid: rowChoice.nanoid,
								rowRankId:
									rowFilterItem.question_type === 'ranking'
										? parseInt(i + 1)
										: parseInt(0),
								columnRankId: parseInt(0),
								count: columnResponses.length,
							});
						}
					});
				});
			});
		});
	});
	return calculatedValues;
}

// TODO: This is a temporary fix to a backend issue
export const hiddenCategories = [
	'segment-ses',
	'bolge',
	'sehir',
	'ilce',
	'cocuk-says',
	'cocuk-yas',
];

export const overrideHiddenCategories = {
	tWUMB7ceWD4wVG36: ['segment-ses'],
	vesL73CdcP9k3ykc: ['segment-ses'],
	cMBXXNmrusA1CLkh: ['segment-ses'],
	OFxgBTZYlY43Nx12: ['segment-ses'],
	'9iyqYtYpNID-PvJ8': ['segment-ses'],
	'OPhcB-fU-kPdMDFb': ['segment-ses', 'sehir'],
	'EGE-5JAJF7p8RRgO': ['segment-ses'],
	'3X0qXTJE2iTiLTNq': ['segment-ses'],
	EdM97FTD075TNba2: ['segment-ses'],
	zHb517ZYZZatMJsO: ['segment-ses'],
	nvsXqkBVpSPl0eFz: ['segment-ses'],
	'ZPe1CilYAkpv-afM': ['segment-ses', 'sehir'],
	cRlfROX2IFJaSzLq: ['segment-ses'],
	'JibGq7-bgfwx9ySu': ['segment-ses'],
	'nWL5-atA03slCoyU': ['segment-ses'],
	XZ8Gut2wQWdTM9zv: ['segment-ses'],
	FVkbSZ0BNsJEchu0: ['segment-ses', 'bolge'],
	EfIoKvcJPiLkjWAy: ['segment-ses', 'bolge'],
	FErmvjZcEJb2nTPa: ['segment-ses'],
	'tmDWpFUFdVee-PF8': ['segment-ses'],
	RnYrjjMppCmerimd: ['segment-ses'],
	Lq3edsNBvnxPYUNC: ['segment-ses'],
	PnfMdBGiGoWmfTHv: ['segment-ses'],
	VHtLtKFBAEm9rUWS: ['segment-ses'],
	'Qd-SA6vKIuq7QBm1': ['segment-ses'],
	fU1hmTzoMGK3cXLp: ['segment-ses'],
	A15v8jQx4NUYI9fy: ['segment-ses'],
	'1-wi4ZLibnRT-UZ9': ['segment-ses'],
	PB1Z9fRQn2HLk1ea: ['segment-ses'],
	dca07TrM4xXtO0ti: ['segment-ses'],
	'8xOGMe9M-T4yszQQ': ['segment-ses'],
	DVl3qSDr4MKHFJYc: ['segment-ses'],
	rAA47xcnxlRnY2vu: ['segment-ses'],
	'BcbxHKtvXA-CsGEq': ['segment-ses'],
	'2RzCwhpZEM3cP1Dt': ['segment-ses'],
	GubtIcHR4Nz3OWQR: ['segment-ses'],
	BBGB3JP9TWsL1PTq: ['segment-ses'],
	zLlsbXsiW0mvDcGX: ['segment-ses'],
	wS9dofaWKvIk4tA3: ['segment-ses', 'bolge'],
	BiUPm20v05LPnGsK: ['segment-ses'],
	'0eQgxQ03jfr8uoCU': ['segment-ses'],
};

/*
	In a Project (nanoid below), we had duplicate choices in a question.
	We merge their responses in Analysis saga and below are the hardcoded nanoids.
	We should remove this in the future and fix the issue in backend.
*/
export const ELCA_PROJECT_NANOID = 'ZC9yKxw4yejQxcUq';
export const ELCA_PROBLEMATIC_QUESTION_NANOID = 'I0fbH8oJz9ywL94P';
export const ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID = 'CWLUAvMSwCWD4LCY';
export const ELCA_PROBLEMATIC_QUESTION_RIGHT_CHOICE_NANOID = 'FglXh0llWvOJOijq';

export const calculateHeightForBarChart = (
	choiceLength,
	isCompareMode = false,
	comparisonItemCount,
) => {
	let height = 60 * 16; //h-[60rem]
	if (choiceLength < 3) height = 44 * 4; //h-44
	else if (choiceLength >= 3 && choiceLength < 5) height = 52 * 4; //h-52
	else if (choiceLength >= 5 && choiceLength < 10) height = 64 * 4; //h-64
	else if (choiceLength >= 10 && choiceLength < 20) height = 96 * 4; //h-96
	const legendHeight = comparisonItemCount * 20;

	//returns pixel value
	return isCompareMode ? height + legendHeight : height;
};

export function calculateArithmeticMean(numbers) {
	// Check if the array is not empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// Sum up all the values in the array
	let sum = numbers.reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		0,
	);

	// Divide the sum by the number of elements
	let mean = sum / numbers.length;

	// Return the mean
	return parseFloat(mean.toFixed(4));
}

export function calculateMedian(numbers) {
	// Check if the array is empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// Sort the array in ascending order
	let sortedNumbers = numbers.slice().sort((a, b) => a - b);

	// Calculate the middle index
	let middleIndex = Math.floor(sortedNumbers.length / 2);

	// Check if the array has an odd or even number of elements
	let result = null;
	if (sortedNumbers.length % 2 !== 0) {
		// Return the middle element for an odd number of elements
		result = sortedNumbers[middleIndex];
	} else {
		// Return the average of the two middle elements for an even number of elements
		result = (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
	}
	return parseFloat(result.toFixed(4));
}

export function calculateStandardDeviation(numbers, type = 'S') {
	// Check if the array is not empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// For sample standard deviation, we need at least two elements
	if (type === 'S' && numbers.length < 2) {
		return 'N/A';
	}

	// Calculate the mean
	let mean = numbers.reduce((acc, val) => acc + val, 0) / numbers.length;

	// Calculate the variance
	let variance = numbers.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0);

	// Adjust the divisor depending on the type (population 'P' or sample 'S')
	variance /= type === 'S' ? numbers.length - 1 : numbers.length;

	// Calculate the standard deviation (square root of variance)
	let standardDeviation = Math.sqrt(variance);

	return parseFloat(standardDeviation.toFixed(4));
}

import ChoicePill from '../ChoicePill';
import { useEffect, useState } from 'react';
import NextButton from '../NextButton';
import { shuffle, find, cloneDeep, each } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function ChoiceList({
	question,
	showPreviousButton,
	isPreviousDisabled,
	handlePrevious,
	handleQuestionSubmit,
	showOther,
	lastQuestion,
}) {
	const isRequired = question.config.required;
	const [error, setError] = useState(isRequired);
	const [otherTextValue, setOtherTextValue] = useState('');

	const { t } = useTranslation();

	const initialValues = {
		multiple_selection: [],
		single_selection: null,
		yes_no: null,
		ranking: [],
	};

	const [values, setValues] = useState(initialValues[question.question_type]);
	const [ranks, setRanks] = useState([]);
	const [noneChoice, setNoneChoice] = useState(null);

	const [questionChoices, setQuestionChoices] = useState(null);

	useEffect(() => {
		let q = cloneDeep(question.choices);

		if (question.config.is_randomized) {
			let other = null;
			if (question.config.show_other) {
				other = q.find(choice => choice.choice_type === 'other');
				q.splice(
					q.findIndex(choice => choice.choice_type === 'other'),
					1,
				);
			}
			let shuffle_mode = question.config.shuffle ? question.config.shuffle : '';
			let placementPosition; //for placing the items back in the questions array
			let fixedItems;
			let hasFixedItems = true;
			switch (shuffle_mode) {
				case 'shuffle_first_one_fixed':
					//extract items that will be fixed before shuffling
					fixedItems = q.splice(0, 1);
					placementPosition = 0;
					break;
				case 'shuffle_first_two_fixed':
					fixedItems = q.splice(0, 2);
					placementPosition = 0;
					break;
				case 'shuffle_last_one_fixed':
					fixedItems = q.splice(-1);
					placementPosition = q.length;
					break;
				case 'shuffle_last_two_fixed':
					fixedItems = q.splice(-2);
					placementPosition = q.length;
					break;
				case 'shuffle_all':
				default:
					hasFixedItems = false;
			}
			q = shuffle(q);
			//place fixedItems in their respective places
			if (hasFixedItems) {
				q.splice(placementPosition, 0, ...fixedItems);
			}
			if (other) q.push(other);
		}

		if (question.question_type === 'ranking') {
			const newValues = [];
			const newRanks = [];
			each(q, choice => {
				newValues.push({ choice: choice.nanoid, order: null });
				newRanks.push({
					id: choice.order,
					name: choice.order.toString(),
				});
			});
			setRanks(newRanks);
			setValues(newValues);
		}
		if (question.question_type === 'multiple_selection') {
			const noneAnswer = q.find(choice => choice.title === 'Hiçbiri');
			if (noneAnswer) setNoneChoice(noneAnswer.nanoid);
		}

		setQuestionChoices(q);
	}, [
		question.question_type,
		question.choices,
		question.config.is_randomized,
		question.config.shuffle,
		showOther,
		t,
		question.config,
	]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		let otherQuestion = null;
		if (question.questions && question.questions.length > 0) {
			otherQuestion = question.questions[0];
		}

		/*
		 * Check if question required.
		 * Check if current answer is valid
		 */
		if (question.question_type === 'yes_no') {
			handleQuestionSubmit({
				question: question,
				answer: values.value,
			});
		} else if (question.question_type === 'single_selection') {
			// This will be `undefined` if an `Other` option was not selected (or the choice did not exist at all)
			const otherChoice = find(
				question.choices,
				i => i.nanoid === values.value && i.choice_type === 'other',
			);

			const otherAnswerValue = otherChoice
				? { question: otherQuestion, answer: otherTextValue }
				: null;

			handleQuestionSubmit({
				question: question,
				answer: values.value,
				otherAnswerValue: otherAnswerValue,
			});
		} else if (question.question_type === 'multiple_selection') {
			// This will be `undefined` if an `Other` option was not selected (or the choice did not exist at all)
			const otherChoice = find(
				values,
				i =>
					find(
						question.choices,
						c => c.nanoid === i.value && c.choice_type === 'other',
					) !== undefined,
			);

			const otherAnswerValue = otherChoice
				? { question: otherQuestion, answer: otherTextValue }
				: null;

			handleQuestionSubmit({
				question: question,
				answer: values.map(val => val.value),
				otherAnswerValue: otherAnswerValue,
			});
		}
		if (question.question_type === 'ranking') {
			handleQuestionSubmit({
				question: question,
				answer: values,
			});
		}
	};

	useEffect(() => {
		if (
			question.question_type === 'single_selection' ||
			question.question_type === 'yes_no'
		) {
			if (isRequired) {
				if (values && values.checked) {
					setError(false);
				} else {
					setError(true);
				}
			} else {
				if (values && values.checked) {
					setError(false);
				} else {
					setError(false);
				}
			}
		}
		if (question.question_type === 'multiple_selection') {
			if (isRequired) {
				if (values && values.length > 0) {
					if (question.config.selection_count_type === 'exact_number') {
						values.length === question.config.min_selection_count
							? setError(false)
							: setError(true);
					} else if (question.config.selection_count_type === 'range') {
						question.config.min_selection_count <= values.length &&
						question.config.max_selection_count >= values.length
							? setError(false)
							: setError(true);
					} else {
						values.length > 0 ? setError(false) : setError(true);
					}
				} else if (values.length === 0) {
					setError(true);
				}
			} else {
				if (values && values.length > 0) {
					if (question.config.selection_count_type === 'exact_number') {
						values.length === question.config.min_selection_count
							? setError(false)
							: setError(true);
					} else if (question.config.selection_count_type === 'range') {
						question.config.min_selection_count <= values.length &&
						question.config.min_selection_count >= values.length
							? setError(false)
							: setError(true);
					} else {
						values.length > 0 ? setError(false) : setError(true);
					}
				} else if (values.length === 0) {
					setError(false);
				}
			}
		}
		if (question.question_type === 'ranking') {
			values.some(value => value.order === null)
				? setError(true)
				: setError(false);
		}
	}, [
		isRequired,
		question.config.max_selection_count,
		question.config.min_selection_count,
		question.config.selection_count_type,
		question.question_type,
		values,
	]);

	if (!questionChoices) {
		return <></>;
	}

	return (
		<>
			<ul role="list" className=" list-container grid grid-rows-1 gap-3">
				{questionChoices.map((choice, index) => (
					<li className="item-container" key={index}>
						<ChoicePill
							question={question}
							choice={choice}
							ranks={ranks}
							values={values}
							setValues={setValues}
							otherTextValue={otherTextValue}
							setOtherTextValue={setOtherTextValue}
							noneChoice={noneChoice}
						/>
					</li>
				))}
			</ul>
			<NextButton
				disabled={error}
				handleNext={formSubmissionHandler}
				showPreviousButton={showPreviousButton}
				isPreviousDisabled={isPreviousDisabled}
				handlePrevious={handlePrevious}
				lastQuestion={lastQuestion}
			/>
		</>
	);
}

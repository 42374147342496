import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnalysisCrossTabSettings from '../../../components/AnalysisCrossTabSettings';
import AnalysisCrossTabTable from '../../../components/AnalysisCrossTabTable';
import AnalysisHeader from '../../../components/AnalysisHeader';
import { toggleProjectFeedbackSlideOver } from '../../../store/projectSlice';
import FeedbackSlideover from '../../../components/FeedbackSlideover';

export default function CrossTabAnalysisPage() {
	const { nanoid } = useParams();
	const dispatch = useDispatch();

	const isFeedbackOpen = useSelector(
		state => state.project.projectFeedback.isSlideOverOpen,
	);

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);
	const rawData = projectAnalysis ? projectAnalysis.rawData : [];

	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const toggleFeedbackSlideOver = bool => {
		dispatch(toggleProjectFeedbackSlideOver(bool));
	};

	return (
		<>
			<div className="flex">
				<AnalysisCrossTabSettings
					translations={translations}
					language={displayLanguage}
				/>

				<FeedbackSlideover
					isFeedbackOpen={isFeedbackOpen}
					toggleFeedbackSlideOver={toggleFeedbackSlideOver}
				/>

				<div className="w-full relative h-screen overflow-y-auto">
					<div className="bg-white flex flex-row items-center h-42 pl-4 pr-7 shadow-sm md:h-14 z-10 border-b border-gray-100 sticky top-0">
						<AnalysisHeader
							selectedTab={'cross-tab'}
							rawData={rawData}
							translations={translations}
							language={displayLanguage}
						/>
					</div>
					<div className="mx-auto p-4">
						<AnalysisCrossTabTable
							translations={translations}
							language={displayLanguage}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

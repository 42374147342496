import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { loadAddressesStart } from '../../../store/addressReducer';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../components/Pagination';
import { slice } from 'lodash';
import { setDocumentTitle } from '../../../utils';

const PAGE_SIZE = 10;

function EmptyState() {
	const { t } = useTranslation();
	return (
		<div className="text-center">
			<svg
				className="mx-auto h-12 w-12 text-gray-400"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				aria-hidden="true"
			>
				<path
					vectorEffect="non-scaling-stroke"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
				/>
			</svg>
			<h3 className="mt-2 text-sm font-medium text-gray-900">
				{t('billingPage.emptyState.noAddresses')}
			</h3>
			<p className="mt-1 text-sm text-gray-500">
				{t('billingPage.emptyState.message')}
			</p>
			<div className="mt-6">
				<Link
					to={`/account/billing/addresses/create/`}
					className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				>
					<PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
					{t('New Address')}
				</Link>
			</div>
		</div>
	);
}

export default function BillingPage() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const addresses = useSelector(state => state.addresses.addresses);
	const isLoadingBillingAddresses = useSelector(
		state => state.addresses.isLoading,
	);
	const [page, setPage] = useState(1);

	const startIndex = (page - 1) * PAGE_SIZE;
	const endIndex = startIndex + PAGE_SIZE;

	useEffect(() => {
		setDocumentTitle(t('pageTitles.billingAddresses'));
	}, [t]);

	useEffect(() => {
		dispatch(loadAddressesStart());
	}, [dispatch]);

	return (
		<section aria-labelledby="billing-history-heading">
			<div className="bg-white shadow sm:overflow-hidden sm:rounded-md">
				<div className="px-4 py-5 sm:px-6">
					<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							{t('billingPage.billingAddresses')}
						</h3>

						<div className="ml-4 flex flex-shrink-0">
							<Link
								to={`/account/billing/addresses/create/`}
								className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
							>
								<PlusIcon
									className="-ml-1 mr-2 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
								<span>{t('New Address')}</span>
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden border-t border-gray-200">
								{addresses.length > 0 && (
									<table className="min-w-full divide-y divide-gray-200">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
												>
													{t('Title')}
												</th>
												<th
													scope="col"
													className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
												>
													{t('Address')}
												</th>

												{/*
						  `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
						*/}
												<th
													scope="col"
													className="relative px-6 py-3 text-left text-sm font-medium text-gray-500"
												>
													<span className="sr-only">{t('Edit')}</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{slice(addresses, startIndex, endIndex).map(address => (
												<tr key={address.nanoid}>
													<td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
														{address.title}
													</td>
													<td className="px-6 py-4 text-sm text-gray-500">
														{address.line} {address.district.name}/
														{address.city.name}
													</td>

													<td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
														<Link
															to={`/account/billing/addresses/${address.nanoid}`}
															className="text-indigo-600 hover:text-indigo-900"
														>
															{t('Edit')}
														</Link>
													</td>
												</tr>
											))}
											<tr className="border-none">
												<td colSpan={4}>
													<Pagination
														currentPage={page}
														startIndex={startIndex + 1}
														endIndex={
															endIndex <= addresses.length
																? endIndex
																: addresses.length
														}
														totalItemCount={addresses.length}
														handlePageChange={setPage}
														textSize="small"
														itemName={t('paginationItem.addresses')}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								)}
								{addresses.length === 0 && (
									<div className="my-16">
										{isLoadingBillingAddresses && (
											<Spinner className="mx-auto w-10 h-10 text-indigo-600" />
										)}
										{!isLoadingBillingAddresses && <EmptyState />}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

import { Route, Routes } from 'react-router-dom';
import CrossTabAnalysisPage from './CrossTabAnalysisPage';
import DefaultAnalysisPage from './DefaultAnalysisPage';

const AnalysisPage = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<DefaultAnalysisPage />} />
				<Route path="/cross-tab" element={<CrossTabAnalysisPage />} />
			</Routes>
		</>
	);
};

export default AnalysisPage;

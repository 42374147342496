import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import Badge from '../Badge';
import { Menu } from '@headlessui/react';
import { classNames } from '../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { PROJECT_STATUSES } from '../../constants';
import CircularProgress from '../CircularProgress';

const ProjectListItem = ({ project }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleWithdrawClick = (nanoid, status) => {
		NiceModal.show(Modal, {
			icon: 'warning',
			title: t('withdrawProject.question'),
			message: t('withdrawProject.message'),
			primaryButtonLabel: t('withdrawProject.yes'),
			secondaryButtonLabel: t('withdrawProject.no'),
			onPrimaryButtonClick: () => {
				dispatch({
					type: 'projectEditor/updateProjectStatus',
					payload: { nanoid, status },
				});
			},
		});
	};

	return (
		<li
			key={project.nanoid}
			className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
		>
			<div className="flex items-center justify-between space-x-4">
				{/* Repo name and link */}
				<div className="min-w-0 space-y-3">
					<div className="flex items-center space-x-3">
						<span
							className={classNames(
								project.status === 'draft' ? 'bg-gray-100' : '',
								project.status === 'under_approval' ? 'bg-yellow-100' : '',
								project.status === 'under_edit' ? 'bg-yellow-100' : '',
								project.status === 'approved' ? 'bg-green-100' : '',
								project.status === 'running' ? 'bg-green-100' : '',
								project.status === 'paused' ? 'bg-red-100' : '',
								project.status === 'completed' ? 'bg-indigo-100' : '',
								'h-4 w-4 rounded-full flex items-center justify-center',
							)}
							aria-hidden="true"
						>
							<span
								className={classNames(
									project.status === 'draft' ? 'bg-gray-400' : '',
									project.status === 'under_approval' ? 'bg-yellow-400' : '',
									project.status === 'under_edit' ? 'bg-yellow-400' : '',
									project.status === 'approved' ? 'bg-green-400' : '',
									project.status === 'running' ? 'bg-green-400' : '',
									project.status === 'paused' ? 'bg-red-400' : '',
									project.status === 'completed' ? 'bg-indigo-400' : '',
									'h-2 w-2 rounded-full',
								)}
							/>
						</span>

						<h2 className="text-sm font-medium cursor-pointer">
							<Link
								to={
									project.status === 'completed' || project.status === 'running'
										? `/project/${project.nanoid}/analysis`
										: `/project/${project.nanoid}/audience`
								}
							>
								<span className="absolute inset-0" aria-hidden="true" />
								{project.title}
							</Link>
						</h2>
					</div>

					<Badge
						className="ml-6 mt-5"
						type={PROJECT_STATUSES[project.status].badgeType}
					>
						{t(PROJECT_STATUSES[project.status].label)}
					</Badge>
				</div>
				<div className="sm:hidden">
					<Link to={`/project/${project.nanoid}/audience`}>
						<ChevronRightIcon
							className="h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
					</Link>
				</div>
				<div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
					<div className="flex items-center">
						{project.payment_mode === 'payg' ? (
							<Badge type="info">{t('projectList.paymentModes.payg')}</Badge>
						) : (
							<div className="px-2 py-0.5 rounded-full text-xs font-medium text-white bg-indigo-600">
								{t('projectList.paymentModes.subscription') +
									': ' +
									project.subscription.plan.name}
							</div>
						)}
						<Menu as="div" className="relative">
							<Menu.Button>
								<EllipsisVerticalIcon className="w-6 text-gray-500" />
							</Menu.Button>
							<Menu.Items className="origin-top-right z-40 absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
								<Menu.Item
									as="a"
									key="link"
									className="block px-4 py-2 text-sm w-full text-left"
									href={`/project/${project.nanoid}/audience`}
								>
									{t('projectList.seeDetails')}
								</Menu.Item>
								{project.status === 'under_approval' && (
									<Menu.Item
										as="button"
										key="status"
										className="block px-4 py-2 text-sm w-full text-left"
										onClick={() => handleWithdrawClick(project.nanoid, 'draft')}
									>
										{t('withdrawProject.text')}
									</Menu.Item>
								)}
							</Menu.Items>
						</Menu>
					</div>

					<div
						className="flex gap-1 text-gray-500 text-sm z-30"
						title={moment(project?.created).format('LLL')}
					>
						{t('common.createdTimeAgo', {
							ago: moment(project?.created).fromNow(),
						})}
						<div
							className="w-5 h-5"
							title={t('Completes {{completed}}/{{totalReach}}', {
								completed: project.metrics.completed,
								totalReach: project.metrics.total_reach,
							})}
						>
							<CircularProgress
								progress={
									project.metrics.completed === 0
										? 0
										: (project.metrics.completed /
												project.metrics.total_reach) *
										  100
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};

export default ProjectListItem;

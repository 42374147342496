import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { deleteAudienceFieldsByCategory } from '../../api/services/Audience';
import {
	addSelectedAudienceCategory,
	removeSelectedAudienceCategory,
} from '../../store/projectEditorSlice';
import { sendGoogleEvent } from '../../utils/analytics';
import { StarIcon } from '@heroicons/react/24/solid';

export default function AudienceCategories({
	isEditable,
	categoryList,
	selectedCategories,
	getLastAddedCategory,
	subscription,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const projectNanoId = useSelector(
		state => state.projectEditor.project.nanoid,
	);
	const audienceNanoId = useSelector(
		state => state.projectEditor.audience.nanoid,
	);

	const handleChange = async (e, categorySlug) => {
		if (e.target.checked) {
			dispatch(
				addSelectedAudienceCategory({
					categoryNanoId: e.target.value,
				}),
			);
			getLastAddedCategory(e.target.value);

			sendGoogleEvent('audience_category_add', {
				category: categorySlug,
			});
		} else {
			await deleteAudienceFieldsByCategory({
				projectNanoId: projectNanoId,
				audienceNanoId: audienceNanoId,
				categoryNanoId: e.target.value,
			});

			dispatch(
				removeSelectedAudienceCategory({
					audienceNanoId: audienceNanoId, //For Saga
					projectNanoId: projectNanoId, // For Saga
					categoryNanoId: e.target.value,
				}),
			);

			sendGoogleEvent('audience_category_remove', {
				category: categorySlug,
			});
		}
	};

	const disableAdvancedTierCategories = (subCategoryTier, subscription) => {
		if (
			subscription &&
			subscription.plan.audience_tier === 'basic' &&
			subCategoryTier === 'advanced'
		) {
			return true;
		}
		return false;
	};

	return (
		<div className="mb-40">
			<form className="space-y-10 pl-5 pt-5 divide-y mb-52 divide-gray-200">
				{categoryList.map((category, index) => (
					<div key={index} className={index === 0 ? null : 'pt-10'}>
						<fieldset>
							<legend className="block text-sm font-medium text-gray-900">
								{category.name}
							</legend>
							<div className="space-y-3 pt-6">
								{category.category_set.map((subCategory, subCategoryIndex) => {
									return (
										<span
											key={subCategoryIndex}
											className=" flex items-center group relative"
										>
											{disableAdvancedTierCategories(
												subCategory.tier,
												subscription,
											) && (
												<span className="w-60 shadow hidden lg:block z-50 pointer-events-none absolute border border-gray-300 text-xs mb-1 top-6 left-1/4 -translate-x-1/4 whitespace-normal rounded bg-white text-gray-800 opacity-0 transition  before:absolute before:left-1/4 before:bottom-full before:-translate-x-1/4 before:border-4 before:border-transparent before:border-b-gray-400 group-hover:opacity-100">
													<div className="py-4 pr-2 pl-4">
														{t('audienceCategoryHelperText')}
													</div>
												</span>
											)}
											<div key={subCategoryIndex} className="flex items-center">
												<input
													id={subCategory.nanoid}
													name={subCategory.nanoid}
													checked={
														selectedCategories[subCategory.nanoid] !== undefined
													}
													onChange={e => handleChange(e, subCategory.slug)}
													value={subCategory.nanoid}
													type="checkbox"
													disabled={
														!isEditable ||
														disableAdvancedTierCategories(
															subCategory.tier,
															subscription,
														)
													}
													className="disabled:cursor-not-allowed disabled:opacity-30 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
												/>
												<label
													htmlFor={subCategory.nanoid}
													className={classNames(
														disableAdvancedTierCategories(
															subCategory.tier,
															subscription,
														)
															? 'text-gray-300'
															: 'text-gray-600',
														'ml-3 text-sm  flex',
													)}
												>
													<span>{subCategory.name}</span>
													{subscription && subCategory.tier === 'advanced' && (
														<StarIcon className="h-4 ml-1 text-yellow-400" />
													)}
												</label>
											</div>
										</span>
									);
								})}
							</div>
						</fieldset>
					</div>
				))}
			</form>
		</div>
	);
}

import ChoicePill from './ChoicePill';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import Spinner from '../../Spinner';
import ValidationMessage from '../ValidationMessage';
import { useRef } from 'react';
import { useEffect } from 'react';

export default function ChoiceList({
	question,
	isEditable = true,
	canAdd = true,
	canDelete = true,
	canReorder = true,
	handleCreateChoice,
	handleReorderChoiceList,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	handleChoiceQualifyChange,
	isScreeningQuestion = false,
}) {
	const { isAddingChoice } = useSelector(
		state =>
			state.projectEditor.questionnaire.questionStatuses[question.nanoid],
	);

	const { t } = useTranslation();

	const handleDrop = droppedItem => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;

		// Reordering is now allowed
		if (!canReorder) return;

		handleReorderChoiceList({
			question: question,
			source: droppedItem.source.index,
			destination: droppedItem.destination.index,
		});
	};

	const choicePillRefs = useRef({});
	const didMount = useRef(false);
	//runs if 'question.choices.length' changes, but not on initial render
	useEffect(() => {
		if (didMount.current)
			choicePillRefs.current[question.choices.length - 1].focus();
		else didMount.current = true;
	}, [question.choices.length]);

	const handleOnKeyDown = index => e => {
		if (e.key === 'Enter') {
			let newIndex = -1;
			//Check if there is an empty Choice Pill after the current pill
			//if there is, set the newIndex to the index of that one and switch focus
			question.choices
				.slice(index + 1, question.choices.length)
				.some((choice, i) => {
					if (choice.title.trim() === '') {
						newIndex = index + 1 + i;
						return true;
					}
				});
			//if there is no empty choice pill after the current one, create new choice
			if (newIndex === -1) {
				handleCreateChoice();
			} else {
				choicePillRefs.current[newIndex].focus();
			}
		}
	};

	if (!question.choices) {
		return <></>;
	}

	const choiceErrorObject = question.errors?.choices
		? find(question.errors?.choices, i => {
				return i.nanoid === '';
		  })
		: null;
	const errors = choiceErrorObject ? choiceErrorObject.errors : [];

	return (
		<>
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId="list-container">
					{provided => (
						<ul
							role="list"
							className=" list-container mt-5 grid grid-rows-1 gap-3"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{question.choices.map((choice, index) => (
								<Draggable
									key={choice.nanoid}
									draggableId={choice.nanoid}
									index={index}
									isDragDisabled={
										choice.choice_type === 'other' || !isEditable || !canReorder
									}
								>
									{provided => (
										<li
											className="item-container"
											ref={provided.innerRef}
											{...provided.dragHandleProps}
											{...provided.draggableProps}
										>
											<ChoicePill
												index={index}
												question={question}
												choice={choice}
												isLetterCodeHidden={
													question.question_type === 'ranking'
												}
												isEditable={isEditable}
												canDelete={
													canDelete &&
													choice.nanoid &&
													question.choices.length > 1 &&
													isEditable
												}
												handleChoiceTitleChange={handleChoiceTitleChange}
												handleChoiceImageChange={handleChoiceImageChange}
												handleDeleteChoice={handleDeleteChoice}
												handleChoiceImageTooLarge={handleChoiceImageTooLarge}
												handleChoiceQualifyChange={handleChoiceQualifyChange}
												handleOnKeyDown={handleOnKeyDown}
												refs={choicePillRefs}
												isScreeningQuestion={isScreeningQuestion}
											/>
										</li>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>

			{canAdd && (
				<>
					<span className="group relative">
						{question.choices.length >= 50 && (
							<span className="w-52 p-2 pl-4 shadow hidden sm:block z-50 pointer-events-none absolute border border-gray-300 text-sm mb-1 -top-4 translate-x-1/2 rounded bg-white text-gray-700 opacity-0 transition  before:absolute left-6 before:top-1/3 before:-left-1.5 before:-translate-x-1/2 before:border-4 before:border-transparent before:border-r-gray-400 group-hover:opacity-100">
								{t('addChoiceBtnTotalChoiceError', { choiceCount: 50 })}
							</span>
						)}
						<button
							type="button"
							className="tour-question-editor-add-choice disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={handleCreateChoice}
							disabled={
								isAddingChoice || !isEditable || question.choices.length >= 50
							}
						>
							{isAddingChoice ? (
								<div className="flex justify-center items-center w-full">
									<Spinner className="h-4" />
								</div>
							) : (
								t('Add Choice')
							)}
						</button>
					</span>
				</>
			)}
			{errors.map((error, index) => (
				<ValidationMessage key={index} message={error} className="mt-1" />
			))}
		</>
	);
}

export const calculateChartKeys = comparisonData => {
	const calculatedKeys = [];
	if (Object.keys(comparisonData).length > 0) {
		Object.keys(comparisonData).forEach(comparisonKey => {
			if (Object.keys(comparisonData[comparisonKey]).length > 0) {
				Object.keys(comparisonData[comparisonKey]).forEach(
					comparisonItemKey => {
						if (
							Object.keys(comparisonData[comparisonKey][comparisonItemKey])
								.length > 0
						) {
							Object.keys(
								comparisonData[comparisonKey][comparisonItemKey],
							).forEach(rank => {
								const key = `${comparisonKey}_${comparisonItemKey}_${rank}`;
								calculatedKeys.push(key);
							});
						} else {
							const key = `${comparisonKey}_${comparisonItemKey}`;
							calculatedKeys.push(key);
						}
					},
				);
			}
		});
	} else {
		calculatedKeys.push('responsePercentage');
	}
	return calculatedKeys;
};

export const wordCountHistogram = (stringList, excludeWords = [], groupBy) => {
	const wordCount = {};

	stringList.forEach(str => {
		// Remove punctuation from text
		// next line disables unnecessary escape character error
		// eslint-disable-next-line
		const punctuation = /[\.,?!():]/g;
		const newText = str.replace(punctuation, ' ');

		// Split the string into words using regular expression.
		let words = newText.split(/\s+/);

		const trimmedWords = [];

		words.forEach(word => {
			const trimmedWord = word.trim().toLowerCase();
			if (trimmedWord.length > 0 && !excludeWords.includes(trimmedWord))
				trimmedWords.push(trimmedWord);
		});

		let newWords = trimmedWords;

		if (groupBy > 1) {
			newWords = [];

			while (trimmedWords.length > groupBy) {
				const splicedWords = trimmedWords.splice(0, groupBy);
				newWords.push(splicedWords.join(' '));
			}
			newWords.push(trimmedWords.join(' '));
		}

		// Count the frequency of each word (case-insensitive) excluding the specified words.
		newWords.forEach(word => {
			wordCount[word] = (wordCount[word] || 0) + 1;
		});
	});

	// Sort the word count object by word frequency (value) in descending order.
	const sortedHistogram = Object.entries(wordCount).sort((a, b) => a[1] - b[1]);

	// Convert the sorted array back to an object.
	//const keys = ['country', 'count'];
	const sortedWordCount = sortedHistogram.map(entry => {
		return { country: entry[0], count: entry[1] };
	});

	//return only the top 25 words
	const limitedWordCount =
		sortedWordCount.length > 25 ? sortedWordCount.slice(-25) : sortedWordCount;

	return {
		barData: limitedWordCount,
		tableData: [...limitedWordCount].reverse(),
	};
};

import {
	ArrowDownIcon,
	ArrowUpIcon,
	EllipsisVerticalIcon,
	BoltIcon,
	TrashIcon,
} from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from '../../utils';
import { QUESTION_META_DATA } from '../../constants';
import QuestionIcon from '../QuestionIcon';
import { Fragment } from 'react';
import {
	deleteQuestion,
	moveQuestionToTop,
	moveQuestionToBottom,
} from '../../store/projectEditorSlice';
import { Menu, Transition } from '@headlessui/react';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../utils/analytics';

export default function QuestionPill({
	index,
	active,
	question,
	canDelete,
	isEditable = true,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const showModal = () => {
		NiceModal.show(Modal, {
			icon: 'warning',
			title: t('Cannot Delete {{option}}', {
				option: t('Question'),
			}),
			message: t('questionPill.deleteErrorMsg'),
			showPrimaryButton: false,
			secondaryButtonLabel: t('Close'),
		});
	};

	const questionnaireNanoid = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const isQuestionUsedInAnyLogicRules = (question, questionList) => {
		let result = false;
		questionList.forEach(_question => {
			if (_question.logic.rules.length > 0) {
				_question.logic.rules.forEach(rule => {
					if (rule.then.question === question.nanoid) {
						result = true;
					} else {
						rule.conditions.forEach(condition => {
							if (
								_question.nanoid !== question.nanoid &&
								condition.question === question.nanoid
							) {
								result = true;
							}
						});
					}
				});
			}
		});
		return result;
	};

	return (
		<>
			<div
				className={classNames(
					QUESTION_META_DATA[question.question_type].bgColor,
					'flex items-center justify-between px-3 w-16 border-1 text-white text-sm font-medium rounded-l-md',
				)}
			>
				<QuestionIcon type={question.question_type} />
				<span className="font-mono">{index + 1}</span>
			</div>
			<div
				className={classNames(
					active
						? QUESTION_META_DATA[question.question_type].borderColor
						: 'border-gray-200',
					'flex-1 flex items-center justify-between border-l-0 border-2 bg-white rounded-r-md',
				)}
			>
				<div className="flex-1 px-4 py-2 text-sm w-28 truncate">
					<div className="text-gray-900 font-medium">
						{question.title && question.title}
						{!question.title && <>&nbsp;</>}
					</div>
					<p className="text-gray-500 flex flex-auto">
						{question.errors && Object.keys(question.errors).length > 0 && (
							<ExclamationCircleIcon
								className={classNames('-ml-1 mr-2 h-5 w-5')}
								aria-hidden="true"
							/>
						)}
						{question.logic.rules.length > 0 && (
							<BoltIcon
								className={classNames('-ml-1 mr-2 h-5 w-5')}
								aria-hidden="true"
								title={t('questionPill.boltIconMessage')}
							/>
						)}

						{t(QUESTION_META_DATA[question.question_type].title)}
					</p>
				</div>
				<div className="flex-shrink-0 pr-2">
					<Menu as="div" className="relative inline-block text-left">
						<div>
							<Menu.Button
								disabled={!isEditable}
								className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
							>
								<span className="sr-only">{t('Open options')}</span>
								<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
							</Menu.Button>
						</div>

						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg divide-y divide-gray-100 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="py-1">
									<Menu.Item disabled={!isEditable}>
										{({ active }) => (
											<button
												onClick={() => {
													dispatch(
														moveQuestionToTop({
															source: index,
															is_screening: question.is_screening,
														}),
													);
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'w-full flex flex-items group px-4 py-2 text-sm',
												)}
											>
												<ArrowUpIcon
													className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
													aria-hidden="true"
												/>
												{t('Move to the top')}
											</button>
										)}
									</Menu.Item>
									<Menu.Item disabled={!isEditable}>
										{({ active }) => (
											<button
												onClick={() => {
													dispatch(
														moveQuestionToBottom({
															source: index,
															is_screening: question.is_screening,
														}),
													);
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'w-full flex items-center group px-4 py-2 text-sm',
												)}
											>
												<ArrowDownIcon
													className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
													aria-hidden="true"
												/>
												{t('Move to the bottom')}
											</button>
										)}
									</Menu.Item>
								</div>
								<div className="py-1">
									<Menu.Item disabled={!canDelete || !isEditable}>
										{({ active }) => (
											<button
												onClick={() => {
													if (
														isQuestionUsedInAnyLogicRules(
															question,
															questionList,
														)
													) {
														showModal();
													} else {
														dispatch(
															deleteQuestion({
																index: index,
																questionnaireNanoId: questionnaireNanoid,
																question: question,
															}),
														);

														sendGoogleEvent('questionnaire_question_remove', {
															question_type: question.question_type,
														});
													}
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													canDelete ? '' : 'opacity-75',
													'w-full group flex items-center px-4 py-2 text-sm',
												)}
											>
												<TrashIcon
													className={classNames(
														canDelete ? 'group-hover:text-gray-500' : '',
														'mr-3 h-5 w-5 text-gray-400',
													)}
													aria-hidden="true"
												/>
												{t('Delete')}
											</button>
										)}
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
					</Menu>
				</div>
			</div>
		</>
	);
}

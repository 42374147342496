import { useDispatch } from 'react-redux';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { updateQuestionSetting } from '../../store/projectEditorSlice';
import {
	SHUFFLE_OPTIONS,
	MULTIPLE_SELECTION_OPTIONS,
	RATING_META_DATA,
} from '../../constants';
import Switch from '../Switch';
import Listbox from '../Listbox';
import { each, range } from 'lodash';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { updateQuestionMedia } from '../../store/projectEditorSlice';
import { useState, useRef, useEffect } from 'react';
import { scaleBase64Image } from '../../utils';
import { updatePreQuestion } from '../../api/services/PreQuestion';
import Spinner from '../Spinner';
import ValidationMessage from '../QuestionEditor/ValidationMessage';

function SettingValidationMessage({ settingErrors }) {
	return (
		<>
			{settingErrors && (
				<>
					{settingErrors.map((error, index) => (
						<ValidationMessage key={index} message={error} className="mt-1" />
					))}
				</>
			)}
		</>
	);
}

export default function QuestionSettings({
	index,
	question,
	questionnaireNanoId,
	isEditable = true,
	isScreeningQuestion = false,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const mediaTypes = [
		{ title: t('questionSettings.media.image'), id: 'image' },
		{ title: t('questionSettings.media.video'), id: 'video' },
	];

	const [showMediaPanel, setShowMediaPanel] = useState(false);
	const [selectedMediaType, setSelectedMediaType] = useState(
		question.media ? question.media.media_type : mediaTypes[0].id,
	);

	const [isUploading, setIsUploading] = useState(false);
	const inputFile = useRef(null);
	const errors = question.errors?.config;

	const handleImageTooLarge = () => {
		NiceModal.show(Modal, {
			icon: 'error',
			title: t('questionEditor.modal.fileError'),
			message: t('questionEditor.modal.fileErrorMsg'),
			showPrimaryButton: false,
			secondaryButtonLabel: t('Close'),
		});
	};

	useEffect(() => {
		setIsUploading(false);
	}, [question.media]);

	useEffect(() => {
		setShowMediaPanel(false);
	}, [index]);

	const handleQuestionMediaChange = async ({
		question,
		mediaType,
		newFile,
	}) => {
		const payload = {
			question_type: question.question_type,
			is_screening: isScreeningQuestion,
		};

		payload[mediaType] = newFile;

		const res = await updatePreQuestion({
			questionnaireNanoId: questionnaireNanoId,
			preQuestionNanoId: question.nanoid,
			payload: payload,
		});

		dispatch(
			updateQuestionMedia({
				questionIndex: index,
				question: question,
				media: res.media,
			}),
		);
	};

	const handleImage = event => {
		setIsUploading(true);
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);

		const maxFileSize = 7 * 1024 * 1024;

		if (event.target.files[0].size > maxFileSize) {
			setIsUploading(false);
			handleImageTooLarge();
			return;
		}

		reader.onload = () => {
			scaleBase64Image(reader.result, 1280, 800).then(
				async resizedImageBase64 => {
					handleQuestionMediaChange({
						question: question,
						newFile: resizedImageBase64,
						mediaType: 'image',
					});
				},
			);
		};
		reader.onerror = () => {
			setIsUploading(false);
		};
	};

	const handleVideo = event => {
		setIsUploading(true);

		const maxFileSize = 50 * 1024 * 1024;

		if (event.target.files[0].size > maxFileSize) {
			setIsUploading(false);
			handleImageTooLarge();
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = () => {
			handleQuestionMediaChange({
				question: question,
				newFile: reader.result,
				mediaType: 'video',
			});
		};
		reader.onerror = () => {
			setIsUploading(false);
		};
	};

	const ratingIconList = [];
	each(RATING_META_DATA, (value, key) => {
		ratingIconList.push({
			value: key,
			label: value.label,
		});
	});

	const opinionScaleMaxList = [];
	each(range(2, 11), i => {
		opinionScaleMaxList.push({
			value: i,
			label: i,
		});
	});

	const opinionScaleMinList = [];
	each(range(0, 2), i => {
		opinionScaleMinList.push({
			value: i,
			label: i,
		});
	});

	return (
		<>
			<div className="mt-3 grid grid-cols-1 gap-6">
				{question.question_type !== 'description' && !isScreeningQuestion && (
					<Switch
						title={t('Required')}
						description={t('questionSettings.requiredWarning')}
						checked={question.config.required}
						isDisabled={!isEditable}
						onChange={checked => {
							dispatch(
								updateQuestionSetting({
									question: question,
									setting: 'required',
									newValue: checked,
								}),
							);
						}}
					/>
				)}
				{question.question_type === 'text' && (
					<>
						<div>
							<Switch
								title={t('Multi-line')}
								description={t('questionSettings.multiLineNotification')}
								checked={question.config.is_multiline}
								isDisabled={!isEditable}
								onChange={checked => {
									dispatch(
										updateQuestionSetting({
											question: question,
											setting: 'is_multiline',
											newValue: checked,
										}),
									);
								}}
							/>
							<SettingValidationMessage settingErrors={errors?.is_multiline} />
						</div>
						<div>
							<label
								htmlFor="minCharacters"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Minimum Characters')}
							</label>
							<div className="mt-1">
								<input
									type="number"
									name="minCharacters"
									id="minCharacters"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.min_characters}
									min="1"
									max="10"
									disabled={!isEditable}
									onChange={event => {
										if (!isNaN(parseInt(event.target.value))) {
											dispatch(
												updateQuestionSetting({
													question: question,
													setting: 'min_characters',
													newValue: parseInt(event.target.value),
												}),
											);
										}
									}}
								/>
								<SettingValidationMessage
									settingErrors={errors?.min_characters}
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="maxCharacters"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Maximum Characters')}
							</label>
							<div className="mt-1">
								<input
									type="number"
									name="maxCharacters"
									id="maxCharacters"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.max_characters}
									min="1"
									max="10"
									disabled={!isEditable}
									onChange={event => {
										if (!isNaN(parseInt(event.target.value))) {
											dispatch(
												updateQuestionSetting({
													question: question,
													setting: 'max_characters',
													newValue: parseInt(event.target.value),
												}),
											);
										}
									}}
								/>
								<SettingValidationMessage
									settingErrors={errors?.max_characters}
								/>
							</div>
						</div>
					</>
				)}
				{question.question_type === 'number' && (
					<>
						<Switch
							title={t('Limit values')}
							description={t('questionSettings.limitValuesDescription')}
							checked={question.config.is_custom_range}
							isDisabled={!isEditable}
							onChange={checked => {
								dispatch(
									updateQuestionSetting({
										question: question,
										setting: 'is_custom_range',
										newValue: checked,
									}),
								);
							}}
						/>
						{question.config.is_custom_range && (
							<div className="flex gap-3">
								<div>
									<label
										htmlFor="minValue"
										className="block text-sm font-medium text-gray-700"
									>
										{t('Minimum')}
									</label>

									<input
										type="number"
										name="minValue"
										className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={question.config.min_value}
										disabled={!isEditable}
										onChange={event => {
											if (!isNaN(parseInt(event.target.value))) {
												dispatch(
													updateQuestionSetting({
														question: question,
														setting: 'min_value',
														newValue: parseInt(event.target.value),
													}),
												);
											}
										}}
									/>
									<SettingValidationMessage settingErrors={errors?.min_value} />
								</div>
								<div>
									<label
										htmlFor="maxValue"
										className="block text-sm font-medium text-gray-700"
									>
										{t('Maximum')}
									</label>
									<input
										type="number"
										name="maxValue"
										id="email"
										className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={question.config.max_value}
										disabled={!isEditable}
										onChange={event => {
											if (!isNaN(parseInt(event.target.value))) {
												dispatch(
													updateQuestionSetting({
														question: question,
														setting: 'max_value',
														newValue: parseInt(event.target.value),
													}),
												);
											}
										}}
									/>
									<SettingValidationMessage settingErrors={errors?.max_value} />
								</div>
							</div>
						)}
					</>
				)}
				{question.question_type === 'multiple_selection' && (
					<>
						<div>
							<div>
								<Listbox
									label={t('Selection Count')}
									items={MULTIPLE_SELECTION_OPTIONS}
									value={question.config.selection_count_type}
									isEditable={isEditable}
									onChange={value => {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'selection_count_type',
												newValue: value,
											}),
										);
									}}
								/>
								<SettingValidationMessage
									settingErrors={errors?.selection_count_type}
								/>
							</div>
							{question.config.selection_count_type === 'exact_number' && (
								<div className="mt-3">
									<input
										type="number"
										className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={question.config.min_selection_count}
										min="0"
										disabled={!isEditable}
										onChange={event => {
											if (!isNaN(parseInt(event.target.value))) {
												dispatch(
													updateQuestionSetting({
														question: question,
														setting: 'min_selection_count',
														newValue: parseInt(event.target.value),
													}),
												);
											}
										}}
									/>
									<SettingValidationMessage
										settingErrors={errors?.min_selection_count}
									/>
								</div>
							)}
							{question.config.selection_count_type === 'range' && (
								<div className="mt-3 flex flex-col">
									<div className="flex gap-3">
										<input
											type="number"
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={question.config.min_selection_count}
											min="0"
											disabled={!isEditable}
											onChange={event => {
												if (!isNaN(parseInt(event.target.value))) {
													dispatch(
														updateQuestionSetting({
															question: question,
															setting: 'min_selection_count',
															newValue: parseInt(event.target.value),
														}),
													);
												}
											}}
										/>
										<input
											type="number"
											name="max_selection_count"
											id="max_selection_count"
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={question.config.max_selection_count}
											min="0"
											disabled={!isEditable}
											onChange={event => {
												if (!isNaN(parseInt(event.target.value))) {
													dispatch(
														updateQuestionSetting({
															question: question,
															setting: 'max_selection_count',
															newValue: parseInt(event.target.value),
														}),
													);
												}
											}}
										/>
									</div>
									<div>
										<SettingValidationMessage
											settingErrors={errors?.max_selection_count}
										/>
									</div>
								</div>
							)}
						</div>
					</>
				)}
				{(question.question_type === 'multiple_selection' ||
					question.question_type === 'single_selection') && (
					<>
						<Switch
							title={t('Randomize')}
							description={t('questionSettings.randomizeDesc')}
							checked={question.config.is_randomized}
							isDisabled={!isEditable}
							onChange={checked => {
								dispatch(
									updateQuestionSetting({
										question: question,
										setting: 'is_randomized',
										newValue: checked,
									}),
								);
							}}
						/>
						{question.config.is_randomized && (
							<Listbox
								label={t('questionSettings.shuffle.title')}
								items={SHUFFLE_OPTIONS}
								value={question.config.shuffle}
								isEditable={isEditable}
								onChange={value => {
									dispatch(
										updateQuestionSetting({
											question: question,
											setting: 'shuffle',
											newValue: value,
										}),
									);
								}}
							/>
						)}
						{!isScreeningQuestion && (
							<Switch
								title={t('questionSettings.otherOption')}
								description={t('questionSettings.customResponseOption')}
								checked={question.config.show_other}
								isDisabled={!isEditable}
								onChange={checked => {
									dispatch(
										updateQuestionSetting({
											question: question,
											setting: 'show_other',
											newValue: checked,
										}),
									);
								}}
							/>
						)}
					</>
				)}

				{question.question_type === 'rating' && (
					<>
						<div>
							<Listbox
								label={t('Rating Icon')}
								items={ratingIconList}
								value={question.config.shape}
								isEditable={isEditable}
								onChange={value => {
									dispatch(
										updateQuestionSetting({
											question: question,
											setting: 'shape',
											newValue: value,
										}),
									);
								}}
							/>
						</div>
						<div>
							<label
								htmlFor="number_of_shape"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Rating Count')}
							</label>
							<div className="mt-1">
								<input
									type="number"
									name="number_of_shape"
									id="number_of_shape"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.number_of_shape}
									min="3"
									max="11"
									disabled={!isEditable}
									onChange={event => {
										if (event.target.value > 11 || event.target.value < 3)
											return;
										if (!isNaN(parseInt(event.target.value))) {
											dispatch(
												updateQuestionSetting({
													question: question,
													setting: 'number_of_shape',
													newValue: parseInt(event.target.value),
												}),
											);
										}
									}}
								/>
								<SettingValidationMessage
									settingErrors={errors?.number_of_shape}
								/>
							</div>
						</div>
					</>
				)}

				{question.question_type === 'opinion_scale' && (
					<>
						<div className="flex gap-3">
							<div>
								<label
									htmlFor="opinionScaleMin"
									className="block text-sm font-medium text-gray-700"
								>
									{t('Minimum')}
								</label>

								<input
									type="number"
									name="opinionScaleMin"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.minimum}
									disabled={!isEditable}
									onChange={event => {
										if (!isNaN(parseInt(event.target.value))) {
											dispatch(
												updateQuestionSetting({
													question: question,
													setting: 'minimum',
													newValue: parseInt(event.target.value),
												}),
											);
										}
									}}
								/>
								<SettingValidationMessage settingErrors={errors?.minimum} />
							</div>
							<div>
								<label
									htmlFor="opinionScaleMax"
									className="block text-sm font-medium text-gray-700"
								>
									{t('Maximum')}
								</label>
								<input
									type="number"
									name="opinionScaleMax"
									id="email"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.maximum}
									disabled={!isEditable}
									onChange={event => {
										if (!isNaN(parseInt(event.target.value))) {
											dispatch(
												updateQuestionSetting({
													question: question,
													setting: 'maximum',
													newValue: parseInt(event.target.value),
												}),
											);
										}
									}}
								/>
								<SettingValidationMessage settingErrors={errors?.maximum} />
							</div>
						</div>

						<div>
							<label
								htmlFor="minimumLabel"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Min Label')}
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="minimumLabel"
									id="minimumLabel"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.min_label}
									disabled={!isEditable}
									onChange={event => {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'min_label',
												newValue: event.target.value,
											}),
										);
									}}
								/>
								<SettingValidationMessage settingErrors={errors?.min_label} />
							</div>
						</div>
						<div>
							<label
								htmlFor="midLabel"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Mid Label')}
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="midLabel"
									id="midLabel"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.mid_label}
									disabled={!isEditable}
									onChange={event => {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'mid_label',
												newValue: event.target.value,
											}),
										);
									}}
								/>
								<SettingValidationMessage settingErrors={errors?.mid_label} />
							</div>
						</div>
						<div>
							<label
								htmlFor="maxLabel"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Max Label')}
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="maxLabel"
									id="maxLabel"
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									value={question.config.max_label}
									disabled={!isEditable}
									onChange={event => {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'max_label',
												newValue: event.target.value,
											}),
										);
									}}
								/>
								<SettingValidationMessage settingErrors={errors?.max_label} />
							</div>
						</div>
					</>
				)}
				{question.question_type === 'matrix' && (
					<div>
						<Switch
							title={t('Multiple Selection')}
							description={t('questionSettings.customResponseOption')}
							checked={question.config.is_multiselection}
							isDisabled={!isEditable}
							onChange={checked => {
								dispatch(
									updateQuestionSetting({
										question: question,
										setting: 'is_multiselection',
										newValue: checked,
									}),
								);
							}}
						/>
						<SettingValidationMessage
							settingErrors={errors?.is_multiselection}
						/>
					</div>
				)}
				{question.question_type === 'file_upload' && (
					<div className="grid grid-cols-2 gap-3">
						<div>
							<label
								htmlFor="minimumFileCount"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Minimum File Count')}
							</label>

							<input
								type="number"
								pattern="[0-9]*"
								name="minimumFileCount"
								min="0"
								className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
								value={question.config.min_file_count}
								disabled={!isEditable}
								onChange={event => {
									let input = parseInt(event.target.value);
									if (!isNaN(input)) {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'min_file_count',
												newValue: input,
											}),
										);
									}
								}}
							/>

							<SettingValidationMessage
								settingErrors={errors?.min_file_count}
							/>
						</div>
						<div>
							<label
								htmlFor="maximumFileCount"
								className="block text-sm font-medium text-gray-700"
							>
								{t('Maximum File Count')}
							</label>
							<input
								type="number"
								name="maximumFileCount"
								id="maximumFileCount"
								min="0"
								className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
								value={question.config.max_file_count}
								disabled={!isEditable}
								onChange={event => {
									let input = parseInt(event.target.value);
									if (!isNaN(input)) {
										dispatch(
											updateQuestionSetting({
												question: question,
												setting: 'max_file_count',
												newValue: input,
											}),
										);
									}
								}}
							/>
							<SettingValidationMessage
								settingErrors={errors?.max_file_count}
							/>
						</div>
					</div>
				)}
				<div>
					<Switch
						title={t('questionSettings.media.title')}
						description={t('questionSettings.media.description')}
						checked={question.media || showMediaPanel}
						isDisabled={!isEditable}
						onChange={checked => {
							setShowMediaPanel(!showMediaPanel);

							if (!checked) {
								handleQuestionMediaChange({
									question: question,
									newFile: '',
									mediaType: question.media.media_type,
								});
								setShowMediaPanel(checked);
							}
						}}
					/>
					{(showMediaPanel || question.media) && (
						<div className="w-full max-h-48">
							<input
								type="file"
								ref={inputFile}
								onChange={event => {
									if (selectedMediaType === 'image') {
										handleImage(event);
									} else if (selectedMediaType === 'video') {
										handleVideo(event);
									}
								}}
								style={{ display: 'none' }}
							/>
							<div className="mt-2 space-y-4 sm:flex sm:items-center sm:space-x-6 sm:space-y-0 mb-2">
								{mediaTypes.map((mediaType, index) => (
									<div key={index} className="flex items-center">
										<input
											id={mediaType.id}
											name="notification-method"
											type="radio"
											checked={mediaType.id === selectedMediaType}
											onChange={() => setSelectedMediaType(mediaType.id)}
											className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
										/>
										<label
											htmlFor={mediaType.id}
											className="ml-2 block text-sm font-medium leading-6 text-gray-900"
										>
											{mediaType.title}
										</label>
									</div>
								))}
							</div>
							<button
								type="button"
								disabled={isUploading}
								onClick={() => inputFile.current.click()}
								className="w-full inline-flex justify-around items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:opacity-30"
							>
								<div className="flex gap-1.5">
									{!isUploading && (
										<PhotoIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
									)}
									{isUploading && (
										<Spinner className="-ml-0.5 h-5 w-5 text-white" />
									)}
									{selectedMediaType === 'image' && (
										<>{t('questionSettings.media.uploadImageButton')}</>
									)}
									{selectedMediaType === 'video' && (
										<>{t('questionSettings.media.uploadVideoButton')}</>
									)}
								</div>
							</button>
							{selectedMediaType === 'image' && (
								<div className="mt-1 text-xs text-gray-500">
									{t('questionSettings.media.helpText.image')}
								</div>
							)}
							{selectedMediaType === 'video' && (
								<div className="mt-1 text-xs text-gray-500">
									{t('questionSettings.media.helpText.video')}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

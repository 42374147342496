export function getTranslatedQuestionText(
	translations,
	language,
	sourceObjectNanoId,
	originalText = '',
) {
	/* This is for both questions and choices. */

	if (language === 'tr') return originalText;

	if (translations.translatedQuestionTexts[language]?.status === 'loaded') {
		if (
			translations.translatedQuestionTexts[language].texts[sourceObjectNanoId]
		) {
			return translations.translatedQuestionTexts[language].texts[
				sourceObjectNanoId
			];
		}
	}
	return originalText;
}

export function getTranslatedResponseText(response, questionNanoId, language) {
	if (
		response.translations &&
		response.translations[language] &&
		response.translations[language][questionNanoId]
	) {
		return response.translations[language][questionNanoId];
	}
	return response[questionNanoId];
}

export const LANGUAGE_NAMES = {
	tr: 'Türkçe',
	en: 'English',
	fr: 'Français',
};

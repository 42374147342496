import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	addresses: [],
	isLoading: false,
	addressCreationStatus: 'idle',

	// Dictionary for individual addresses, are they being deleted? updating? Default: 'idle'
	addressStatus: {},

	cities: {
		cityList: [],
		isLoading: false,
	},

	districts: {
		// A dictionary with cityNanoId as key district list as value
		cityDistrictList: {},
		isLoading: false,
	},
};

// eslint-disable-next-line import/no-unused-modules
export const addressSlice = createSlice({
	name: 'address',
	initialState,
	reducers: {
		loadAddressesStart: state => {
			state.isLoading = true;
		},
		loadAddressesFinish: (state, action) => {
			state.addresses = action.payload.addresses;
			state.isLoading = false;
		},
		createAddressStart: state => {
			state.addressCreationStatus = 'creating';
		},
		createAddressFinish: state => {
			state.addressCreationStatus = 'created';
		},
		updateAddressStart: (state, action) => {
			state.addressStatus[action.payload.addressNanoId] = 'updating';
		},
		updateAddressFinish: (state, action) => {
			state.addressStatus[action.payload.addressNanoId] = 'updated';
		},
		deleteAddressStart: (state, action) => {
			state.addressStatus[action.payload.addressNanoId] = 'deleting';
		},
		deleteAddressFinish: (state, action) => {
			state.addressStatus[action.payload.addressNanoId] = 'deleted';
		},
		clearAddressStatus: (state, action) => {
			state.addressCreationStatus = 'idle';
			if (action.payload.addressNanoId) {
				state.addressStatus[action.payload.addressNanoId] = 'idle';
			}
		},
		loadCitiesStart: state => {
			state.cities.isLoading = true;
		},
		loadCitiesFinish: (state, action) => {
			state.cities.cityList = action.payload.cities;
			state.cities.isLoading = false;
		},
		loadDistrictsStart: (state, action) => {
			delete state.districts.cityDistrictList[action.payload.cityNanoId];
			state.districts.isLoading = true;
		},
		loadDistrictsFinish: (state, action) => {
			state.districts.cityDistrictList[action.payload.cityNanoId] =
				action.payload.districts;
			state.districts.isLoading = false;
		},
	},
});

export const {
	loadAddressesStart,
	loadAddressesFinish,
	createAddressStart,
	createAddressFinish,
	updateAddressStart,
	updateAddressFinish,
	deleteAddressStart,
	deleteAddressFinish,
	clearAddressStatus,
	loadCitiesStart,
	loadCitiesFinish,
	loadDistrictsStart,
	loadDistrictsFinish,
} = addressSlice.actions;

export default addressSlice.reducer;

import { findIndex } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { classNames, toFixedNumberValue } from '../../utils';
import { useTranslation } from 'react-i18next';
import ValidationMessage from '../QuestionEditor/ValidationMessage';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import {
	updateQuotaFieldStart,
	updateQuotaResponseStart,
} from '../../store/projectEditorSlice';

export default function QuotaEditor({
	category,
	audienceFields,
	isEditable,
	categoryQuota,
	projectNanoId,
	audienceNanoId,
	areFieldsDisabled,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [responseQuotaInput, setResponseQuotaInput] = useState({});

	const findQuotaField = (categoryQuota, nanoId) =>
		categoryQuota.quotaFields?.find(field => field.audience_field === nanoId);

	const quotaFields = useMemo(() => {
		const categoryFields = audienceFields.filter(
			field => field.category.nanoid === category.nanoid,
		);
		categoryFields.sort(
			(a, b) => a.category_field.order - b.category_field.order,
		);
		return categoryFields;
	}, [audienceFields, category.nanoid]);

	const loadingQuotaFields = useSelector(
		state => state.projectEditor.audience.loadingQuotaField,
	);

	const audienceReach = useSelector(
		state => state.projectEditor.audience.reach,
	);

	const errors = useSelector(state => state.projectEditor.audience.errors);

	const errorList = errors
		.filter(error => error.category === category.slug)
		.reduce((acc, err) => {
			acc.push(...err.errors);
			return acc;
		}, []);

	const selectedQuotaFields = categoryQuota?.quotaFields || [];

	useEffect(() => {
		const quotaResponses = quotaFields.map(quotaField => {
			const audienceField = quotaField.nanoid;
			const quota = findQuotaField(categoryQuota, audienceField);

			if (quota && quota.isLoading) {
				return {
					audienceField: audienceField,
					response: responseQuotaInput[audienceField],
				};
			} else {
				return {
					audienceField: audienceField,
					response: quota ? quota.response_quota : 0,
				};
			}
		});

		const obj = quotaResponses.reduce((dict, quota) => {
			dict[quota.audienceField] = quota.response;
			return dict;
		}, {});

		setResponseQuotaInput(obj);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quotaFields, categoryQuota]);

	const isChecked = nanoid => {
		return selectedQuotaFields.some(field => field.audience_field === nanoid);
	};

	const isAllFieldsChecked = () => {
		return quotaFields.every(quotaField =>
			selectedQuotaFields.some(
				field => field.audience_field === quotaField.nanoid,
			),
		);
	};

	const getAudienceFieldNanoId = categoryField => {
		const r = findIndex(audienceFields, function (o) {
			return o.value.nanoid === categoryField.value.nanoid;
		});
		if (audienceFields[r]) {
			return audienceFields[r].nanoid;
		}
		return '';
	};

	const handleQuotaFieldChange = (e, nanoId) => {
		if (e.target.checked) {
			dispatch(
				updateQuotaFieldStart({
					projectNanoId: projectNanoId,
					audienceNanoId: audienceNanoId,
					quotaNanoId: categoryQuota.nanoid,
					audienceField: nanoId,
					response_quota: 0,
					type: 'add',
				}),
			);
		} else {
			const quotaField = findQuotaField(categoryQuota, nanoId);

			dispatch(
				updateQuotaFieldStart({
					projectNanoId: projectNanoId,
					audienceNanoId: audienceNanoId,
					quotaNanoId: categoryQuota.nanoid,
					quotaFieldNanoId: quotaField.nanoid,
					audienceField: nanoId,
					type: 'delete',
				}),
			);
		}
	};

	const handleResponseCountChange = (e, nanoId) => {
		const quotaField = findQuotaField(categoryQuota, nanoId);

		//if input is an empty string, set the value to 0
		const responseQuota = isNaN(parseInt(e.target.value))
			? 0
			: parseInt(e.target.value);

		setResponseQuotaInput(prev => ({ ...prev, [nanoId]: responseQuota }));
		const debouncedUpdateResponseFunc = debounce(
			() =>
				dispatch(
					updateQuotaResponseStart({
						projectNanoId: projectNanoId,
						audienceNanoId: audienceNanoId,
						quotaNanoId: categoryQuota.nanoid,
						quotaFieldNanoId: quotaField.nanoid,
						responseQuota: responseQuota,
					}),
				),
			1500,
		);

		debouncedUpdateResponseFunc();
	};

	const sum =
		toFixedNumberValue(
			categoryQuota.quotaFields?.reduce(
				(total, quotaField) => (total += quotaField.response_quota),
				0,
			),
			2,
		) || 0;

	function percentage(partialValue, totalValue) {
		const percentage = partialValue > 0 ? (100 * partialValue) / totalValue : 0;
		return toFixedNumberValue(percentage, 2);
	}

	const isQuotaFieldUpdating = (nanoid, audienceField) => {
		const quotaField = findQuotaField(categoryQuota, audienceField);
		return (
			(loadingQuotaFields[categoryQuota.nanoid] &&
				loadingQuotaFields[categoryQuota.nanoid].findIndex(
					field => field === nanoid,
				) > -1) ||
			(quotaField && quotaField.isLoading)
		);
	};

	return (
		<div className="border-l p-4 pr-0 w-2/4 md:w-2/4">
			<p className="text-gray-700 font-medium">{t('Quota')}</p>
			<p className="text-gray-700 text-xs">{t('quotaDescription')}</p>
			{errorList.length > 0 &&
				errorList.map((error, index) => (
					<ValidationMessage
						key={index}
						message={error}
						className="mt-4 mb-2"
					/>
				))}

			{
				<div className="mt-4 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="relative min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th scope="col">&nbsp;</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
											>
												{t('Choice')}
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
											>
												{t('audienceSettingsCompletes')}
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										{quotaFields.length === 0 && (
											<tr className="w-full text-center">
												<td colSpan={12}>
													<Spinner className="w-8 h-8 mx-auto my-3" />
												</td>
											</tr>
										)}
										{quotaFields.length > 0 &&
											quotaFields.map((categoryField, index) => {
												const { category_field } = categoryField;
												const audienceField = categoryField.nanoid;

												return (
													<tr key={index}>
														<td className="bg-white whitespace-nowrap w-1/12 text-center text-sm font-medium text-gray-800">
															{isQuotaFieldUpdating(
																categoryField.nanoid,
																audienceField,
															) ? (
																<Spinner className="h-4 w-4 ml-4 text-gray-600" />
															) : (
																<input
																	id={`${category_field.nanoid}-${index}`}
																	name={category_field.nanoid}
																	type="checkbox"
																	disabled={!isEditable || areFieldsDisabled()}
																	checked={isChecked(audienceField)}
																	onChange={e =>
																		handleQuotaFieldChange(e, audienceField)
																	}
																	value={
																		getAudienceFieldNanoId(category_field) !==
																		''
																			? getAudienceFieldNanoId(category_field)
																			: category_field.nanoid
																	}
																	className="disabled:cursor-not-allowed disabled:opacity-30 ml-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
																/>
															)}
														</td>
														<td className="whitespace-wrap w-6/12 px-3 py-1 text-sm text-gray-500">
															<label
																className="cursor-pointer"
																htmlFor={`${category_field.nanoid}-${index}`}
															>
																{category_field.name}
															</label>
														</td>
														<td className="whitespace-nowrap w-5/12 py-4 pr-2 text-sm text-gray-500">
															<>
																{findQuotaField(
																	categoryQuota,
																	audienceField,
																) === undefined ? (
																	<>&nbsp;</>
																) : (
																	<div className="relative rounded-md shadow-sm">
																		<input
																			type="text"
																			pattern="[0-9]*"
																			name="price"
																			id="price"
																			disabled={areFieldsDisabled()}
																			className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-800 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-30"
																			placeholder="0.00"
																			value={
																				responseQuotaInput[audienceField] || 0
																			}
																			onChange={e =>
																				handleResponseCountChange(
																					e,
																					audienceField,
																				)
																			}
																			aria-describedby="price-currency"
																		/>

																		<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
																			<span
																				className={classNames(
																					areFieldsDisabled()
																						? 'opacity-30'
																						: '',
																					'text-gray-500 sm:text-sm',
																				)}
																				id="price-currency"
																			>
																				{percentage(
																					responseQuotaInput[audienceField],
																					audienceReach,
																				)}
																				%
																			</span>
																		</div>
																	</div>
																)}
															</>
														</td>
													</tr>
												);
											})}
									</tbody>
									{quotaFields.length > 0 && isAllFieldsChecked() && (
										<tfoot className="bg-gray-50">
											<tr className="text-gray-800">
												<td
													colSpan={2}
													className="font-mono uppercase text-sm px-3 pr-4 text-right"
												>
													{t('sum')}
												</td>
												<td className="flex font-mono text-sm justify-between pl-3 pr-5 py-3.5">
													<div>{sum}</div>
													<div>{percentage(sum, audienceReach)}%</div>
												</td>
											</tr>
										</tfoot>
									)}
								</table>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

import { indexOf } from 'lodash';
import { CONDITION_CHECKS } from '../constants';
import { hiddenCategories, overrideHiddenCategories } from './analysis';

export const AUTH_STORAGE_KEY = 'auth';
export const RESENT_EMAIL_KEY = 'isResent';

export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export const toFixedNumberValue = (value, dp) => {
	return +parseFloat(value).toFixed(dp);
};

export const saveToLocalStorage = (key, data) => {
	const savedData = typeof data === 'string' ? data : JSON.stringify(data);
	localStorage.setItem(key, savedData);
};

export const getItemFromLocalStorage = key => {
	const data = localStorage.getItem(key);
	if (data === undefined || data === null) {
		return null;
	}
	return JSON.parse(data);
};

export function newChoiceTemplate({ title, choice_type = 'default' }) {
	return {
		title: title,
		choice_type: choice_type,
	};
}

export function getDefaultConditionValueForQuestion({ question }) {
	let value = null;

	if (
		question.question_type === 'single_selection' ||
		question.question_type === 'multiple_selection' ||
		question.question_type === 'yes_no'
	) {
		value = [question.choices[0].nanoid];
	} else if (question.question_type === 'text') {
		value = '';
	} else if (
		question.question_type === 'number' ||
		question.question_type === 'rating' ||
		question.question_type === 'opinion_scale' ||
		question.question_type === 'nps'
	) {
		value = null;
	} else if (question.question_type === 'ranking') {
		value = {
			choice: question.choices[0].nanoid,
			order: 1,
		};
	} else if (question.question_type === 'matrix') {
		value = {
			choice: question.rows[0].nanoid,
			columns: [question.columns[0].nanoid],
		};
	}

	return value;
}

export function newConditionTemplate({ question }) {
	const check = CONDITION_CHECKS[question.question_type][0].value;
	const value = getDefaultConditionValueForQuestion({ question: question });

	return {
		type: 'question', // RESERVED: In the future we can have conditions based on audience profile too
		question: question.nanoid,
		check: check,
		value: value,
		target: '0',
	};
}

export function newRuleTemplate({ question }) {
	return {
		operator: 'and',
		then: {
			action: 'end_survey',
			question: null,
		},
		conditions: [
			newConditionTemplate({
				question: question,
			}),
		],
	};
}

export function getQuestionByNanoId(questionList, questionNanoId) {
	const foundIndex = questionList.findIndex(element => {
		return element.nanoid === questionNanoId;
	});
	if (foundIndex > -1) {
		return questionList[foundIndex];
	}
	return null;
}

export function scaleBase64Image(dataURL, maxWidth, maxHeight) {
	return new Promise(done => {
		var img = new Image();
		img.onload = () => {
			var scale, newWidth, newHeight, canvas, ctx;
			if (img.width > maxWidth) {
				scale = maxWidth / img.width;
			} else if (img.height > maxHeight) {
				scale = maxHeight / img.height;
			} else {
				scale = 1;
			}
			newWidth = img.width * scale;
			newHeight = img.height * scale;
			canvas = document.createElement('canvas');
			canvas.height = newHeight;
			canvas.width = newWidth;
			ctx = canvas.getContext('2d');

			ctx.drawImage(
				img,
				0,
				0,
				img.width,
				img.height,
				0,
				0,
				newWidth,
				newHeight,
			);
			done(canvas.toDataURL());
		};
		img.src = dataURL;
	});
}

export const isChoiceUsedInLogic = (choice, questionList) => {
	const eligibleQuestionTypes = [
		'multiple_selection',
		'single_selection',
		'ranking',
		'matrix',
	];
	let result = false;
	questionList.forEach(_question => {
		if (
			_question.logic.rules.length > 0 &&
			indexOf(eligibleQuestionTypes, _question.question_type) > -1
		) {
			if (!result) {
				_question.logic.rules.forEach(rule => {
					rule.conditions.forEach(condition => {
						if (_question.question_type === 'matrix') {
							if (indexOf(condition.value.columns, choice.nanoid) > -1) {
								// If it is a column...
								result = true;
							} else if (condition.value.choice === choice.nanoid) {
								// If it is a row...
								result = true;
							}
						} else if (_question.question_type === 'ranking') {
							result = condition.value.choice === choice.nanoid;
						} else {
							if (indexOf(condition.value, choice.nanoid) > -1) {
								result = true;
							}
						}
					});
				});
			}
		}
	});
	return result;
};

export function arrayToCsv(data) {
	// https://stackoverflow.com/a/68146412
	return data
		.map(
			row =>
				row
					.map(String) // convert every value to String
					.map(v => v.replaceAll('"', '""')) // escape double colons
					.map(v => `"${v}"`) // quote it
					.join(','), // comma-separated
		)
		.join('\r\n'); // rows starting on new lines
}

export const restrictInputType = (e, fn, type) => {
	let re;
	if (type === 'number') {
		re = /^[0-9\b]+$/;
	}
	if (type === 'letter') {
		re = /\p{L}+$/u;
	}
	if (e.target.value === '' || re.test(e.target.value)) {
		fn(e);
	}
};

export function setDocumentTitle(title) {
	document.title = `${title} - Sorbunu`;
}

export function selectProjectFilterCategories(projectNanoid, categoryName) {
	let status = indexOf(hiddenCategories, categoryName) === -1;
	if (!status)
		status = !(
			indexOf(overrideHiddenCategories[projectNanoid], categoryName) === -1
		);

	return status;
}

export function newQuestionTemplate({
	t,
	type,
	existingQuestion,
	isScreening,
}) {
	let choices = [];
	let rows = [];
	let columns = [];
	let title = '';
	const description = '';
	const config = {};

	if (type !== 'label') {
		config['required'] = true;
	}

	if (type === 'multiple_selection' || type === 'single_selection') {
		choices = [newChoiceTemplate('')];
		config['selection_count_type'] = 'unlimited';
	} else if (type === 'yes_no') {
		choices = [
			newChoiceTemplate(t('questionEditor.questionTypes.yes_no.yes')),
			newChoiceTemplate(t('questionEditor.questionTypes.yes_no.no')),
		];
	} else if (type === 'text') {
		config['is_multiline'] = false;
		config['min_characters'] = 1;
		config['max_characters'] = 3000;
	} else if (type === 'rating') {
		config['shape'] = 'stars';
		config['number_of_shape'] = 10;
	} else if (type === 'ranking') {
		choices = [newChoiceTemplate('')];
	} else if (type === 'nps') {
		title = t('questionEditor.questionTypes.nps.defaultTitle');
		config['minimum'] = 0;
		config['maximum'] = 10;
		config['min_label'] = t('questionEditor.questionTypes.nps.minLabel');
		config['mid_label'] = '';
		config['max_label'] = t('questionEditor.questionTypes.nps.maxLabel');
	} else if (type === 'opinion_scale') {
		config['minimum'] = 0;
		config['maximum'] = 10;
		config['min_label'] = '';
		config['mid_label'] = '';
		config['max_label'] = '';
	} else if (type === 'matrix') {
		rows = [
			{
				title: t('questionEditor.questionTypes.matrix.defaultRow1'),
			},
		];

		columns = [
			{
				title: t('questionEditor.questionTypes.matrix.defaultColumn1'),
			},
			{
				title: t('questionEditor.questionTypes.matrix.defaultColumn2'),
			},
		];

		config['multipleSelection'] = false;
	} else if (type === 'file_upload') {
		config['min_file_count'] = 1;
		config['max_file_count'] = 5;
	}

	if (existingQuestion) {
		return {
			...existingQuestion,
			question_type: type,
			choices: choices,
		};
	}

	let rules = [];

	return {
		title: title,
		description: description,
		question_type: type,
		choices: choices,
		rows: rows,
		columns: columns,
		config: config,
		logic: {
			rules: rules,
		},
		is_screening: isScreening,
	};
}

export function formatNumberAsThousands(num) {
	if (num < 1000) {
		return num.toString();
	} else if (num >= 1000 && num < 1000000) {
		return (num / 1000).toFixed(1) + 'K';
	} else if (num >= 1000000 && num < 1000000000) {
		return (num / 1000000).toFixed(1) + 'M';
	} else {
		return (num / 1000000000).toFixed(1) + 'B';
	}
}

export function getOrdinalNumber(currentLanguage, number) {
	if (currentLanguage === 'en-US') {
		const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

		const suffixes = new Map([
			['one', 'st'],
			['two', 'nd'],
			['few', 'rd'],
			['other', 'th'],
		]);
		const formatOrdinals = n => {
			const rule = enOrdinalRules.select(n);
			const suffix = suffixes.get(rule);
			return `${n}${suffix}`;
		};
		return formatOrdinals(number);
	} else {
		return `${number}.`;
	}
}

import moment from 'moment';

export default function Comment({ comment }) {
	return (
		<li className="flex space-x-3 py-4">
			<div className="flex-shrink-0">
				{comment.user.profile_picture ? (
					<img
						className="h-8 w-8 rounded-full"
						src={comment.user.profile_picture}
						alt={comment.user.first_name}
					/>
				) : (
					<svg
						className="h-8 w-8 rounded-full bg-gray-100 text-gray-300"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
					</svg>
				)}
			</div>
			<div className="min-w-0 flex-1">
				<div className="flex gap-3">
					<p className="text-sm font-bold text-gray-800">
						{comment.user.first_name}{' '}
						{comment.user.company && '(' && comment.user.company.name && ')'}
					</p>
					{comment.created && (
						<p className="text-sm text-gray-600" title={comment.created}>
							{moment(comment.created).fromNow()}
						</p>
					)}
				</div>
				<p className="mt-2 text-sm text-gray-800">{comment.body}</p>
			</div>
		</li>
	);
}

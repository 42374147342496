import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSignUp } from '../../../store/actions/authActions';
import { Formik, Form, Field } from 'formik';
import { SignUpValues } from '../../../utils/Form/InitialValues';

import { signUpValidationSchema } from './constant';
import { FormInput } from '../../../components/TextInput';
import { Logo } from '../../../components/Logo';
import { useState, useCallback, useEffect } from 'react';
import Spinner from '../../../components/Spinner';
import SlideOver from '../../../components/SlideOver';
import FormAlert from '../../../components/FormAlert';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../../utils/analytics';
import LanguageSwitch from '../../../components/LanguageSwitch';
import { setDocumentTitle } from '../../../utils';
import TermsOfUse from '../../../components/LegalText/termsOfUse';
import PrivacyPolicy from '../../../components/LegalText/privacyPolicy';
import CommsOptin from '../../../components/LegalText/commsOptin';
import PersonalInformationProcessing from '../../../components/LegalText/personalInformationProcessing';

export default function SignUpPage() {
	const spin = useSelector(state => state.auth.spin);
	const errorMessage = useSelector(state => state.auth.failAuthData.errors);
	const dispatch = useDispatch();

	const [selectedLang, setSelectedLang] = useState('');
	const getSelectedLang = useCallback(lng => {
		setSelectedLang(lng);
	}, []);

	const [
		showPersonalInformationProcessing,
		setShowPersonalInformationProcessing,
	] = useState(false);
	const [showCommsOptinText, setShowCommsOptinText] = useState(false);
	const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
	const [showTermsOfUse, setShowTermsOfUse] = useState(false);

	const [checked, setChecked] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const { t } = useTranslation();

	const submitSignUp = values => {
		const signUpValues = { ...values, preferred_language: selectedLang };
		dispatch(userSignUp({ values: signUpValues }));
		sendGoogleEvent('sign_up', { method: 'native' });
	};

	const userAgreementClick = () => {
		setShowTermsOfUse(true);
	};

	const kvkkClick = () => {
		setShowPersonalInformationProcessing(true);
	};

	const handleprivacyPolicyClick = () => {
		setShowPrivacyPolicy(true);
	};
	const canBeChecked = () => {
		return checked ? setIsDisabled(true) : setIsDisabled(false);
	};

	const onCheckboxClick = () => {
		setChecked(!checked);
		return canBeChecked();
	};

	const handleOffersClick = e => {
		e.preventDefault();
		setShowCommsOptinText(true);
	};

	useEffect(() => {
		setDocumentTitle(t('pageTitles.signUp'));
	}, [t]);

	return (
		<>
			<div className="bg-white min-h-screen flex">
				<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96 -mt-10 mb-5">
						<LanguageSwitch getSelectedLang={getSelectedLang} />
					</div>

					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							<Logo className="h-12 w-auto" />
							<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
								{t('signUp.signUpHeading')}
							</h2>
						</div>
						<div className="mt-5">
							{errorMessage && <FormAlert errorMessage={errorMessage} />}
						</div>
						<div className="mt-8">
							<Formik
								initialValues={SignUpValues}
								onSubmit={values => {
									submitSignUp(values);
								}}
								validateOnChange={true}
								validateOnBlur={false}
								validationSchema={signUpValidationSchema}
							>
								{({ errors, touched }) => (
									<div className="mt-6">
										<Form className="space-y-6">
											<div className="space-y-1">
												<FormInput
													id="first_name"
													name="first_name"
													type="text"
													label={t('formLabels.firstName')}
													placeholder={t('formPlaceholders.firstName')}
													errorMessage={
														touched.first_name &&
														t(errors.first_name, {
															name: t('formLabels.firstName'),
														})
													}
												/>
											</div>
											<div className="space-y-1">
												<FormInput
													id="last_name"
													name="last_name"
													type="text"
													label={t('formLabels.lastName')}
													placeholder={t('formPlaceholders.lastName')}
													errorMessage={
														touched.last_name &&
														t(errors.last_name, {
															name: t('formLabels.lastName'),
														})
													}
												/>
											</div>
											<div className="space-y-1">
												<FormInput
													id="email"
													name="email"
													type="text"
													label={t('formLabels.email')}
													placeholder={t('formPlaceholders.enterEmail')}
													errorMessage={
														touched.email &&
														t(errors.email, {
															name: t('formLabels.email'),
														})
													}
												/>
											</div>

											<div className="space-y-1">
												<FormInput
													id="company_name"
													name="company_name"
													type="text"
													label={t('formLabels.companyName')}
													placeholder={t('formPlaceholders.companyName')}
													errorMessage={
														touched.company_name &&
														t(errors.company_name, {
															name: t('formLabels.companyName'),
														})
													}
												/>
											</div>

											<div className="space-y-1">
												<FormInput
													helperText={t('signUp.passwordHelperText')}
													id="password1"
													name="password1"
													type="password"
													label={t('formLabels.password')}
													placeholder={t('formPlaceholders.password')}
													errorMessage={
														touched.password1 &&
														t(errors.password1, {
															name: t('formLabels.password'),
														})
													}
												/>
											</div>
											<div className="space-y-1">
												<FormInput
													id="password2"
													name="password2"
													type="password"
													label={t('formLabels.confirmPassword')}
													placeholder={t('formPlaceholders.confirmPassword')}
													errorMessage={
														touched.password2 &&
														t(errors.password2, {
															name: t('formLabels.password'),
														})
													}
												/>
											</div>

											<div className="ml-2 block text-xs text-gray-900">
												{t('Kvkk.start')}
												<button
													className="text-indigo-600"
													type="button"
													onClick={kvkkClick}
												>
													{t('Kvkk.button')}
												</button>
												{t('Kvkk.end')}
											</div>

											<div className="flex items-center justify-between">
												<div className="flex items-center">
													<Field
														id="comms_optin_email"
														name="comms_optin_email"
														type="checkbox"
														className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
													/>
													<label
														htmlFor="comms_optin_email"
														className="ml-2 block text-xs text-gray-900"
													>
														{t(
															'notificationSettings.offerNotificationTextStart',
														)}
														<button
															type="button"
															onClick={handleOffersClick}
															className="text-indigo-600"
														>
															{t(
																'notificationSettings.offerNotificationPopButton',
															)}
														</button>
														{t('notificationSettings.offerNotificationTextEnd')}
													</label>
												</div>
											</div>

											<div className="flex items-center justify-between">
												<div className="flex items-center">
													<input
														onChange={onCheckboxClick}
														id="Terms-on-use-privacy"
														name="Terms-on-use-privacy"
														type="checkbox"
														className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
													/>
													<label
														htmlFor="Terms-on-use-privacy"
														className="ml-2 block text-xs text-gray-900"
													>
														{t('Terms & Conditions Start')}{' '}
														<button
															type="button"
															onClick={userAgreementClick}
															className="text-indigo-600"
														>
															{t('Terms & Conditions Button')}
														</button>{' '}
														{t('and')}{' '}
														<button
															className="text-indigo-600  mr-1"
															type="button"
															onClick={handleprivacyPolicyClick}
														>
															{t('Privacy Policy Button')}
														</button>
														{t('Terms & Conditions End')}
													</label>
												</div>
											</div>

											<div>
												<button
													disabled={isDisabled || spin}
													type="submit"
													className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
														!checked ? 'bg-gray-300' : 'bg-indigo-600'
													} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400`}
												>
													{spin ? (
														<Spinner className="h-5 w-5" />
													) : (
														<span>{t('Sign Up')}</span>
													)}
												</button>
											</div>
											<div className="flex justify-center text-sm">
												{t('Already have an account?')}
												<Link
													to="/sign-in"
													className="font-medium ml-3 text-indigo-600 hover:text-indigo-500"
												>
													{t('Sign In')}
												</Link>
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</div>
				<div className="hidden lg:block relative w-0 flex-1">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src="/assets/images/signin/futuristic.jpg"
					/>
				</div>
			</div>
			<SlideOver
				title={t('legal.signUp.personalInformationProcessing.title')}
				open={showPersonalInformationProcessing}
				setOpen={setShowPersonalInformationProcessing}
			>
				<PersonalInformationProcessing />
			</SlideOver>
			<SlideOver
				title={t('legal.signUp.commsOptinLegalText.title')}
				open={showCommsOptinText}
				setOpen={setShowCommsOptinText}
			>
				<CommsOptin />
			</SlideOver>
			<SlideOver
				title={t('legal.signUp.privacyPolicy.title')}
				open={showPrivacyPolicy}
				setOpen={setShowPrivacyPolicy}
			>
				<PrivacyPolicy />
			</SlideOver>
			<SlideOver
				title={t('legal.signUp.termsOfUse.title')}
				open={showTermsOfUse}
				setOpen={setShowTermsOfUse}
			>
				<TermsOfUse />
			</SlideOver>
		</>
	);
}

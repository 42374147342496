import { tidy, filter, select, arrange, desc, map } from '@tidyjs/tidy';
import { indexOf, mean } from 'lodash';
import { classNames } from '../../utils';
import BarChart from './BarChart';
import Table2 from './Table2';
import { calculateChartKeys } from './utils';
import { getTranslatedQuestionText } from '../../utils/Translation';
import { useTranslation } from 'react-i18next';

function calculateSingleItemScoreDetails(itemRankings, numRespondents) {
	const maxRank = Object.keys(itemRankings).length; // Assuming the number of keys represents the number of ranks
	const maxScore = maxRank * numRespondents; // Maximum possible score

	let itemScore = 0;
	const rankScores = {};

	for (const rank in itemRankings) {
		// Calculate the score for each rank
		const rankValue = parseInt(rank); // Extract rank number from the key
		const scoreForRank = (maxRank - rankValue + 1) * itemRankings[rank];
		rankScores[rank] = scoreForRank;

		// Add to total item score
		itemScore += scoreForRank;
	}

	// Convert the total score to a percentage
	const percentScoreIndex = (itemScore / maxScore) * 100;

	return {
		percentScoreIndex: percentScoreIndex.toFixed(2), // Rounds to two decimal places
		rankScores: rankScores,
	};
}

const getChoiceRanksFromResponseArray = (questionResponses, question) => {
	const choiceRanks = {};

	question.choices.forEach(choice => {
		choiceRanks[choice.nanoid] = [];
		questionResponses.forEach(response => {
			response[question.nanoid].forEach(userRank => {
				if (userRank.choice === choice.nanoid) {
					choiceRanks[choice.nanoid].push(userRank.order);
				}
			});
		});
	});

	return choiceRanks;
};

function RankingTable({
	firstHeader = 'Choice',
	data,
	question,
	translations,
	language,
}) {
	const { t } = useTranslation();
	const columnHeads = [];
	const tableRows = [];

	columnHeads.push({
		title: firstHeader,
		alignLeft: true,
	});

	const s = tidy(
		data,
		select(question.nanoid),
		map(d => {
			return d[question.nanoid];
		}),
	);

	question.choices.forEach(choice => {
		const row = [];

		row.push({
			value: getTranslatedQuestionText(
				translations,
				language,
				choice.nanoid,
				choice.title,
			),
			media: choice.media,
			alignLeft: true,
		});

		const ranks = {};

		Array.from({ length: question.choices.length }, (_, i) => {
			ranks[i + 1] = 0;
		});

		Object.keys(ranks).forEach(rank => {
			s.forEach(response => {
				const raq = tidy(
					response,
					filter(d => d.choice === choice.nanoid),
					filter(d => d.order === parseInt(rank)),
				);

				ranks[rank] += raq.length;
			});

			row.push({ value: `C: ${ranks[rank]}`, value2: null, mono: true });
		});

		const scores = calculateSingleItemScoreDetails(ranks, s.length);

		for (const rank in scores.rankScores) {
			row[rank]['value2'] = `S: ${scores.rankScores[rank]}`;
		}

		row.push({
			value: `${scores.percentScoreIndex}%`,
			mono: true,
		});

		tableRows.push(row);
	});

	question.choices.forEach(choice => {
		columnHeads.push({
			title: `#${choice.order}`,
		});
	});

	columnHeads.push({
		title: t('analysis.questions.ranking.table.scoreIndexColumnHeader'),
	});

	return <Table2 columnHeads={columnHeads} rows={tableRows} />;
}

export default function RankingQuestion({
	data,
	question,
	labels,
	comparison,
	isCompareMode,
	translations,
	language,
}) {
	const { t } = useTranslation();
	const keys = calculateChartKeys(comparison);

	let di = [];
	let comparisonTables = [];

	if (Object.keys(comparison).length > 0) {
		const renderedTables = {};

		di = question.choices.map(choice => {
			const returnData = {
				country: getTranslatedQuestionText(
					translations,
					language,
					choice.nanoid,
					choice.title,
				),
			};

			Object.keys(comparison).forEach(comparisonKey => {
				Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
					const rankKeys = Object.keys(
						comparison[comparisonKey][comparisonItemKey],
					);
					const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
					rankArray.forEach(rankKey => {
						// TODO: Use analysisFilter here
						const comparisonResponses = tidy(
							data,
							filter(d => {
								const dataType = typeof d[comparisonKey];
								if (dataType === 'string' || dataType === 'number') {
									// For single choice filter items
									return d[comparisonKey] == comparisonItemKey;
								} else if (dataType === 'object') {
									// For multiple choice & ranking filter items

									const innerDataType =
										d[comparisonKey][0] == null
											? 'string'
											: typeof d[comparisonKey][0];

									if (innerDataType === 'string') {
										//for multiple choice
										return indexOf(d[comparisonKey], comparisonItemKey) > -1;
									} else if (innerDataType === 'object') {
										let foundItemIndex = d[comparisonKey].findIndex(
											answer =>
												answer.order == rankKey &&
												answer.choice === comparisonItemKey,
										);

										return foundItemIndex > -1;
									}
								}
							}),
						);

						const choiceRanks = getChoiceRanksFromResponseArray(
							comparisonResponses,
							question,
						);

						const key =
							rankKey == -1
								? `${comparisonKey}_${comparisonItemKey}`
								: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;

						returnData[key] = mean(choiceRanks[choice.nanoid]);

						if (!renderedTables[key]) {
							comparisonTables.push(
								<div
									key={key}
									className={classNames(
										Object.keys(renderedTables).length > 0 ? 'mt-6' : '',
									)}
								>
									<RankingTable
										firstHeader={labels[key]}
										data={comparisonResponses}
										question={question}
										translations={translations}
										language={language}
									/>
								</div>,
							);
							renderedTables[key] = true;
						}
					});
				});
			});

			return returnData;
		});
	} else {
		const choiceRanks = getChoiceRanksFromResponseArray(data, question);

		di = question.choices.map(choice => {
			const returnData = {
				country: getTranslatedQuestionText(
					translations,
					language,
					choice.nanoid,
					choice.title,
				),
				responseCount: choiceRanks[choice.nanoid].length,
				responsePercentage: mean(choiceRanks[choice.nanoid]),
			};

			return returnData;
		});

		di = tidy(di, arrange(desc('responsePercentage')));
	}

	let chartContainerHeight = 'h-44'; // Minimum

	if (Object.keys(comparison).length > 0) {
		if (question.choices.length < 3) {
			chartContainerHeight = 'h-56';
		} else if (question.choices.length < 5) {
			chartContainerHeight = 'h-72';
		} else if (question.choices.length < 7) {
			chartContainerHeight = 'h-96';
		} else {
			chartContainerHeight = 'h-[28rem]';
		}
	} else {
		if (question.choices.length < 3) {
			chartContainerHeight = 'h-44';
		} else if (question.choices.length < 5) {
			chartContainerHeight = 'h-56';
		} else if (question.choices.length < 7) {
			chartContainerHeight = 'h-72';
		} else {
			chartContainerHeight = 'h-96';
		}
	}

	return (
		<div className="px-4 pb-4 sm:px-6">
			<div className={classNames(chartContainerHeight, 'w-full mb-4')}>
				<BarChart
					type={'absolute'}
					minValue={0}
					maxValue={question.choices.length}
					tickValues={question.choices.length}
					groupMode={'grouped'}
					legend={t('analysis.questions.ranking.rowTitle')}
					keys={keys}
					data={di}
					legendLabels={labels}
					isCompareMode={isCompareMode}
				/>
			</div>
			{isCompareMode && <>{comparisonTables}</>}
			{!isCompareMode && (
				<RankingTable
					data={data}
					question={question}
					translations={translations}
					language={language}
				/>
			)}
		</div>
	);
}

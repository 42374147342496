import { createSlice, current } from '@reduxjs/toolkit';
import {
	calculateRowColumnData,
	createColumnForCrossTabTable,
} from '../utils/analysis';

const initialState = {
	/* 
	We keep each loaded project's data in its own key here so the UI does not show wrong data between project switches
	
	Map:
	projectNanoId => projectData
	
	The format of projectData is as below:
	{
		status: 'loading', // Can be 'loading' or 'idle'
		rawData: [], // A list of responses containing all the audience and question data
		filteredData: [], // A filtered version of rawData
		crossTab: { ... }, // CrossTab settings for the project
		labels: {} // A structure to hold project label related data   
	}
	*/
	projects: {},
	legendLabels: {},
};

// eslint-disable-next-line import/no-unused-modules
export const analysisSlice = createSlice({
	name: 'analysis',
	initialState,
	reducers: {
		loadAnalysisDataStart: (state, action) => {
			if (state.projects[action.payload.projectNanoId]) {
				state.projects[action.payload.projectNanoId].status = 'loading';
			} else {
				state.projects[action.payload.projectNanoId] = {
					status: 'loading',
					rawData: [],
					filteredData: [],
					crossTab: {
						// Selected rows, each item is a filter object with name, nanoid, choices etc.
						rows: [],

						// Selected columns, each item is a filter object with name, nanoid, choices etc.
						columns: [],

						// Each item in this list is a dimension as a row
						tableData: [],
					},
					labels: {
						status: {
							loadingStatus: 'idle',
							deletingStatus: {},
							addingStatus: 'idle',
						},
						labelNames: {},
						labelList: [],
					},
					filterData: {
						availableFilterOptions: [],
						appliedFilters: {},
						appliedFilterCount: 0,
					},
					comparisonData: {
						textNumberComparisonItems: {},
						appliedComparisonItems: {},
						appliedComparisonItemCount: 0,
					},
					translations: {
						displayLanguage: 'tr',
						questionnaireLanguages: {
							status: 'idle',

							/*
							{
								"nanoid": "KfJbQ9Qw8KjNxC5b",
								"questionnaire": "2NPFwW5M7qXThFrq",
								"language": "fr"
							},
							{
								"nanoid": "-VkPTHqE-dF2EYVg",
								"questionnaire": "2NPFwW5M7qXThFrq",
								"language": "en"
							}
							*/
							languages: [],
						},
						translatedQuestionTexts: {
							/*
							{
								"tr": {
									"status": "idle",
									"texts":
									[
										{
											"nanoid": "ThaJI55miM-i2E0p",
											"questionnaire_language": "KfJbQ9Qw8KjNxC5b",
											"source_object_nanoid": "Q81bMvWgXJuQQg9A",
											"translated_text": "How are you today?"
										},
										...
									]
								}
							},
							*/
						},
					},
				};
			}
		},
		setDisplayLanguage: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].translations.displayLanguage = action.payload.language;
		},
		loadAnalysisDataFinish: (state, action) => {
			state.projects[action.payload.projectNanoId].status = 'idle';
		},
		setRawData: (state, action) => {
			state.projects[action.payload.projectNanoId].rawData =
				action.payload.data;
		},
		setFilteredData: (state, action) => {
			state.projects[action.payload.projectNanoId].filteredData =
				action.payload.data;
		},
		setAvailableFilterOptions: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].filterData.availableFilterOptions =
				action.payload.availableFilterOptions;

			const translationState = current(
				state.projects[action.payload.projectNanoId].translations,
			);

			action.payload.availableFilterOptions.forEach(section => {
				section.category_set.forEach(category => {
					category.categoryfield_set.forEach(categoryField => {
						let categoryName = category.name;
						let categoryFieldName = categoryField.name;

						if (categoryField.ranks) {
							categoryField.ranks.forEach(rank => {
								let key = `${category.slug}_${categoryField.nanoid}_${rank.rank}`;
								state.legendLabels[
									key
								] = `${categoryName}: ${categoryFieldName}-#${rank.rank}`;
							});
						}
						const key = `${category.slug}_${categoryField.nanoid}`;

						if (section.slug === '') {
							// This is the questions section. Here we check if we need to translate the question and choice label
							const displayLanguage = translationState.displayLanguage;

							if (displayLanguage && displayLanguage !== 'original') {
								if (
									translationState.translatedQuestionTexts[displayLanguage] &&
									translationState.translatedQuestionTexts[displayLanguage]
										.status === 'loaded'
								) {
									if (
										translationState.translatedQuestionTexts[displayLanguage]
											.texts[category.slug]
									) {
										categoryName =
											translationState.translatedQuestionTexts[displayLanguage]
												.texts[category.slug];
									}

									if (
										translationState.translatedQuestionTexts[displayLanguage]
											.texts[categoryField.nanoid]
									) {
										categoryFieldName =
											translationState.translatedQuestionTexts[displayLanguage]
												.texts[categoryField.nanoid];
									}
								}
							}
						}
						state.legendLabels[key] = `${categoryName}: ${categoryFieldName}`;
					});
				});
			});
		},
		resetFilters: (state, action) => {
			state.projects[action.payload.projectNanoId].filterData.appliedFilters =
				{};
			state.projects[
				action.payload.projectNanoId
			].filterData.appliedFilterCount = 0;
		},
		applyFilter: (state, action) => {
			const filterItem = action.payload.filterItem;
			const filterField = action.payload.filterField;
			const rank = action.payload.rank;

			if (
				state.projects[action.payload.projectNanoId].filterData.appliedFilters[
					filterItem
				]
			) {
				if (rank) {
					if (
						state.projects[action.payload.projectNanoId].filterData
							.appliedFilters[filterItem][filterField]
					) {
						state.projects[
							action.payload.projectNanoId
						].filterData.appliedFilters[filterItem][filterField][rank] = true;
					} else {
						state.projects[
							action.payload.projectNanoId
						].filterData.appliedFilters[filterItem][filterField] = {};
						state.projects[
							action.payload.projectNanoId
						].filterData.appliedFilters[filterItem][filterField][rank] = true;
					}
				} else {
					state.projects[
						action.payload.projectNanoId
					].filterData.appliedFilters[filterItem][filterField] = true;
				}
			} else {
				state.projects[action.payload.projectNanoId].filterData.appliedFilters[
					filterItem
				] = {};
				if (rank) {
					state.projects[
						action.payload.projectNanoId
					].filterData.appliedFilters[filterItem][filterField] = {};
					state.projects[
						action.payload.projectNanoId
					].filterData.appliedFilters[filterItem][filterField][rank] = true;
				} else {
					state.projects[
						action.payload.projectNanoId
					].filterData.appliedFilters[filterItem][filterField] = true;
				}
			}
		},
		removeFilter: (state, action) => {
			const filterItem = action.payload.filterItem;
			const filterField = action.payload.filterField;
			const rank = action.payload.rank;

			if (rank) {
				delete state.projects[action.payload.projectNanoId].filterData
					.appliedFilters[filterItem][filterField][rank];

				if (
					Object.keys(
						state.projects[action.payload.projectNanoId].filterData
							.appliedFilters[filterItem][filterField],
					).length === 0
				) {
					delete state.projects[action.payload.projectNanoId].filterData
						.appliedFilters[filterItem][filterField];
					if (
						Object.keys(
							state.projects[action.payload.projectNanoId].filterData
								.appliedFilters[filterItem],
						).length === 0
					) {
						delete state.projects[action.payload.projectNanoId].filterData
							.appliedFilters[filterItem];
					}
				}
			} else {
				delete state.projects[action.payload.projectNanoId].filterData
					.appliedFilters[filterItem][filterField];

				if (
					Object.keys(
						state.projects[action.payload.projectNanoId].filterData
							.appliedFilters[filterItem],
					).length === 0
				) {
					delete state.projects[action.payload.projectNanoId].filterData
						.appliedFilters[filterItem];
				}
			}
		},
		setAppliedFilterCount: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].filterData.appliedFilterCount = action.payload.count;
		},
		setAppliedComparisonItems: (state, action) => {
			const { item, checked, question, projectNanoId } = action.payload;

			const textNumberComparisonItems =
				state.projects[projectNanoId].comparisonData.textNumberComparisonItems;

			if (textNumberComparisonItems[question]) {
				textNumberComparisonItems[question][item.key] = {
					...item,
					checked: checked,
				};
			} else {
				textNumberComparisonItems[question] = {};
				textNumberComparisonItems[question][item.key] = {
					...item,
					checked: checked,
				};
			}
		},
		resetComparison: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].comparisonData.appliedComparisonItems = {};

			state.projects[
				action.payload.projectNanoId
			].comparisonData.textNumberComparisonItems = {};

			state.projects[
				action.payload.projectNanoId
			].comparisonData.appliedComparisonItemCount = 0;
		},
		applyComparisonItem: (state, action) => {
			const comparisonItem = action.payload.comparisonItem;
			const comparisonField = action.payload.comparisonField;
			const rank = action.payload.rank;

			if (
				state.projects[action.payload.projectNanoId].comparisonData
					.appliedComparisonItems[comparisonItem]
			) {
				if (rank) {
					if (
						state.projects[action.payload.projectNanoId].comparisonData
							.appliedComparisonItems[comparisonItem][comparisonField]
					) {
						state.projects[
							action.payload.projectNanoId
						].comparisonData.appliedComparisonItems[comparisonItem][
							comparisonField
						][rank] = true;
					} else {
						state.projects[
							action.payload.projectNanoId
						].comparisonData.appliedComparisonItems[comparisonItem][
							comparisonField
						] = {};
						state.projects[
							action.payload.projectNanoId
						].comparisonData.appliedComparisonItems[comparisonItem][
							comparisonField
						][rank] = true;
					}
				} else {
					state.projects[
						action.payload.projectNanoId
					].comparisonData.appliedComparisonItems[comparisonItem][
						comparisonField
					] = true;
				}
			} else {
				state.projects[
					action.payload.projectNanoId
				].comparisonData.appliedComparisonItems[comparisonItem] = {};
				if (rank) {
					state.projects[
						action.payload.projectNanoId
					].comparisonData.appliedComparisonItems[comparisonItem][
						comparisonField
					] = {};
					state.projects[
						action.payload.projectNanoId
					].comparisonData.appliedComparisonItems[comparisonItem][
						comparisonField
					][rank] = true;
				} else {
					state.projects[
						action.payload.projectNanoId
					].comparisonData.appliedComparisonItems[comparisonItem][
						comparisonField
					] = true;
				}
			}
		},
		removeComparisonItem: (state, action) => {
			const textNumberComparisonItems =
				state.projects[action.payload.projectNanoId].comparisonData
					.textNumberComparisonItems;

			const comparisonItem = action.payload.comparisonItem;
			const comparisonField = action.payload.comparisonField;
			const rank = action.payload.rank;

			if (rank) {
				delete state.projects[action.payload.projectNanoId].comparisonData
					.appliedComparisonItems[comparisonItem][comparisonField][rank];
				if (
					Object.keys(
						state.projects[action.payload.projectNanoId].comparisonData
							.appliedComparisonItems[comparisonItem][comparisonField],
					).length === 0
				) {
					delete state.projects[action.payload.projectNanoId].comparisonData
						.appliedComparisonItems[comparisonItem][comparisonField];
					if (
						Object.keys(
							state.projects[action.payload.projectNanoId].comparisonData
								.appliedComparisonItems[comparisonItem],
						).length === 0
					) {
						delete state.projects[action.payload.projectNanoId].comparisonData
							.appliedComparisonItems[comparisonItem];
					}
				}

				//for applied comparison Items in Text and Number Questions

				Object.keys(textNumberComparisonItems).forEach(item => {
					const questionComparisonItem = textNumberComparisonItems[item];
					Object.keys(questionComparisonItem).forEach(comparison => {
						const compareItem = questionComparisonItem[comparison];

						if (
							compareItem.comparisonKey === comparisonItem &&
							compareItem.comparisonItemKey === comparisonField &&
							compareItem.rankKey === rank
						) {
							delete state.projects[action.payload.projectNanoId].comparisonData
								.textNumberComparisonItems[item][comparison];
						}
					});
				});
			} else {
				delete state.projects[action.payload.projectNanoId].comparisonData
					.appliedComparisonItems[comparisonItem][comparisonField];

				if (
					Object.keys(
						state.projects[action.payload.projectNanoId].comparisonData
							.appliedComparisonItems[comparisonItem],
					).length === 0
				) {
					delete state.projects[action.payload.projectNanoId].comparisonData
						.appliedComparisonItems[comparisonItem];
				}

				//for applied comparison Items in Text and Number Questions

				Object.keys(textNumberComparisonItems).forEach(item => {
					const questionComparisonItem = textNumberComparisonItems[item];
					Object.keys(questionComparisonItem).forEach(comparison => {
						const compareItem = questionComparisonItem[comparison];

						if (
							compareItem.comparisonKey === comparisonItem &&
							compareItem.comparisonItemKey === comparisonField
						) {
							delete state.projects[action.payload.projectNanoId].comparisonData
								.textNumberComparisonItems[item][comparison];
						}
					});
				});
			}
		},
		setAppliedComparisonItemCount: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].comparisonData.appliedComparisonItemCount = action.payload.count;
		},
		addCrossTabRow: (state, action) => {
			state.projects[action.payload.projectNanoId].crossTab.rows.splice(
				action.payload.destinationIndex,
				0,
				action.payload.filterObject,
			);
		},
		removeCrossTabRow: (state, action) => {
			state.projects[action.payload.projectNanoId].crossTab.rows.splice(
				action.payload.index,
				1,
			);
		},
		addCrossTabColumn: (state, action) => {
			state.projects[action.payload.projectNanoId].crossTab.columns.splice(
				action.payload.destinationIndex,
				0,
				action.payload.filterObject,
			);
		},
		removeCrossTabColumn: (state, action) => {
			state.projects[action.payload.projectNanoId].crossTab.columns.splice(
				action.payload.index,
				1,
			);
		},
		swapCrossTabRowsAndColumns: (state, action) => {
			const columns =
				state.projects[action.payload.projectNanoId].crossTab.columns;
			state.projects[action.payload.projectNanoId].crossTab.columns =
				state.projects[action.payload.projectNanoId].crossTab.rows;
			state.projects[action.payload.projectNanoId].crossTab.rows = columns;
		},
		moveCrossTabFilterItemBetweenRowsAndColumns: (state, action) => {
			const sourceList =
				action.payload.source === 'rows'
					? state.projects[action.payload.projectNanoId].crossTab.rows
					: state.projects[action.payload.projectNanoId].crossTab.columns;
			const destList =
				action.payload.dest === 'rows'
					? state.projects[action.payload.projectNanoId].crossTab.rows
					: state.projects[action.payload.projectNanoId].crossTab.columns;

			const filterObject = sourceList[action.payload.sourceIndex];

			sourceList.splice(action.payload.sourceIndex, 1);
			destList.splice(action.payload.destIndex, 0, filterObject);
		},

		// Whenever row or column list changes, this action is dispatched by a saga
		updateCrossTabTable: (state, action) => {
			const _crossTabTable = [];
			const rawData = current(
				state.projects[action.payload.projectNanoId].rawData,
			);

			const calculatedValues = calculateRowColumnData(
				state.projects[action.payload.projectNanoId].crossTab.columns,
				state.projects[action.payload.projectNanoId].crossTab.rows,
				rawData,
			);

			state.projects[action.payload.projectNanoId].crossTab.rows.forEach(
				rowFilterItem => {
					_crossTabTable.push({
						nanoid: rowFilterItem.nanoid,
						name: rowFilterItem.name,
						type: rowFilterItem.type,
						question_type: rowFilterItem.question_type,
						choices: rowFilterItem.categoryfield_set.map(choice => {
							if (rowFilterItem.question_type === 'ranking') {
								const choiceRanks = [];
								//loop ranking choice number times to create rank rows
								for (
									let i = 0;
									i < rowFilterItem.categoryfield_set.length;
									i++
								) {
									let columnValues = [];

									columnValues = createColumnForCrossTabTable(
										state.projects[action.payload.projectNanoId],
										calculatedValues,
										rowFilterItem,
										choice,
										i + 1,
									);

									choiceRanks.push(columnValues);
								}

								return {
									nanoid: choice.nanoid,
									name: choice.name,
									columnValues: choiceRanks,
								};
							} else {
								let columnValues = [];

								columnValues = createColumnForCrossTabTable(
									state.projects[action.payload.projectNanoId],
									calculatedValues,
									rowFilterItem,
									choice,
									0,
								);

								return {
									nanoid: choice.nanoid,
									name: choice.name,
									columnValues: columnValues,
								};
							}
						}),
					});
				},
			);

			state.projects[action.payload.projectNanoId].crossTab.tableData =
				_crossTabTable;
		},
		loadQuestionnaireLanguagesStart: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].translations.questionnaireLanguages.status = 'loading';
		},
		loadQuestionnaireLanguagesFinish: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].translations.questionnaireLanguages.status = 'idle';
			state.projects[
				action.payload.projectNanoId
			].translations.questionnaireLanguages.languages = action.payload.results;

			action.payload.results.forEach(questionnaireLanguage => {
				const currentLanguageStatus = current(
					state.projects[action.payload.projectNanoId].translations
						.translatedQuestionTexts,
				);

				// Do not reset language texts if it was already loaded
				if (
					!currentLanguageStatus[questionnaireLanguage.language] ||
					currentLanguageStatus[questionnaireLanguage.language].status !==
						'loaded'
				) {
					state.projects[
						action.payload.projectNanoId
					].translations.translatedQuestionTexts[
						questionnaireLanguage.language
					] = {
						status: 'idle',
						texts: {},
					};
				}
			});
		},
		loadTranslatedQuestionTextsStart: (state, action) => {
			state.projects[
				action.payload.projectNanoId
			].translations.translatedQuestionTexts[action.payload.language] = {
				status: 'loading',
			};
		},
		loadTranslatedQuestionTextsFinish: (state, action) => {
			const processedTexts = {};
			action.payload.results.forEach(resultItem => {
				processedTexts[resultItem.source_object_nanoid] =
					resultItem.translated_text;
			});
			state.projects[
				action.payload.projectNanoId
			].translations.translatedQuestionTexts[action.payload.language] = {
				status: 'loaded',
				texts: processedTexts,
			};
		},
		addResponseLabelStart: (state, action) => {
			state.projects[action.payload.projectNanoId].labels.status.addingStatus =
				'loading';
		},
		addResponseLabelFinish: (state, action) => {
			const { responseNanoId, projectNanoId, questionNanoId, labelNanoId } =
				action.payload;

			state.projects[projectNanoId].labels.status.addingStatus = 'idle';

			const label = `${responseNanoId}_${labelNanoId}`;
			if (state.projects[projectNanoId].labels.status.deletingStatus[label]) {
				delete state.projects[projectNanoId].labels.status.deletingStatus[
					label
				];
			}

			//update rawData
			const rawResponseToUpdate = state.projects[projectNanoId].rawData.find(
				response => response.nanoid === responseNanoId,
			);

			if (rawResponseToUpdate.labels[questionNanoId]) {
				rawResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			} else {
				rawResponseToUpdate.labels[questionNanoId] = [];
				rawResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			}

			//update filteredData
			const filteredResponseToUpdate = state.projects[
				projectNanoId
			].filteredData.find(response => response.nanoid === responseNanoId);

			if (filteredResponseToUpdate.labels[questionNanoId]) {
				filteredResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			} else {
				filteredResponseToUpdate.labels[questionNanoId] = [];
				filteredResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			}
		},
		addQuestionnaireLabelStart: (state, action) => {
			state.projects[action.payload.projectNanoId].labels.status.loadingStatus =
				'loading';
		},
		addQuestionnaireLabelFinish: (state, action) => {
			const {
				projectNanoId,
				questionnaireLabels,
				labelNanoId,
				questionNanoId,
				responseNanoId,
			} = action.payload;

			state.projects[projectNanoId].labels.status.loadingStatus = 'loaded';
			state.projects[projectNanoId].labels.labelList = questionnaireLabels;
			questionnaireLabels.forEach(label => {
				state.projects[projectNanoId].labels.labelNames[label.nanoid] =
					label.name;
			});

			//update rawData
			const rawResponseToUpdate = state.projects[projectNanoId].rawData.find(
				response => response.nanoid === responseNanoId,
			);

			if (rawResponseToUpdate.labels[questionNanoId]) {
				rawResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			} else {
				rawResponseToUpdate.labels[questionNanoId] = [];
				rawResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			}

			//update filteredData
			const filteredResponseToUpdate = state.projects[
				projectNanoId
			].filteredData.find(response => response.nanoid === responseNanoId);

			if (filteredResponseToUpdate.labels[questionNanoId]) {
				filteredResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			} else {
				filteredResponseToUpdate.labels[questionNanoId] = [];
				filteredResponseToUpdate.labels[questionNanoId].push(labelNanoId);
			}
		},

		loadLabelsStart: (state, action) => {
			state.projects[action.payload.projectNanoId].labels.status.loadingStatus =
				'loading';
		},
		loadLabelsFinish: (state, action) => {
			state.projects[action.payload.projectNanoId].labels.status.loadingStatus =
				'loaded';
			state.projects[action.payload.projectNanoId].labels.labelList =
				action.payload.data;
			action.payload.data.forEach(label => {
				state.projects[action.payload.projectNanoId].labels.labelNames[
					label.nanoid
				] = label.name;
			});
		},
		deleteLabelStart: (state, action) => {
			const { responseNanoId, projectNanoId, labelNanoId } = action.payload;
			const labelToDelete = `${responseNanoId}_${labelNanoId}`;
			state.projects[projectNanoId].labels.status.deletingStatus = {
				[labelToDelete]: 'deleting',
			};
		},
		deleteLabelFinish: (state, action) => {
			const { responseNanoId, projectNanoId, questionNanoId, labelNanoId } =
				action.payload;

			const labelToDelete = `${responseNanoId}_${labelNanoId}`;
			//update rawdata
			const rawResponseToUpdate = state.projects[projectNanoId].rawData.find(
				response => response.nanoid === responseNanoId,
			);
			const updatedRawLabelArray = rawResponseToUpdate.labels[
				questionNanoId
			].filter(label => label !== labelNanoId);

			rawResponseToUpdate.labels[questionNanoId] = updatedRawLabelArray;

			//update filteredData
			const filteredResponseToUpdate = state.projects[
				projectNanoId
			].filteredData.find(response => response.nanoid === responseNanoId);

			const updatedFilteredLabelArray = filteredResponseToUpdate.labels[
				questionNanoId
			].filter(label => label !== labelNanoId);

			filteredResponseToUpdate.labels[questionNanoId] =
				updatedFilteredLabelArray;

			state.projects[projectNanoId].labels.status.deletingStatus = {
				[labelToDelete]: 'deleted',
			};
		},
	},
});
export const {
	loadAnalysisDataStart,
	loadAnalysisDataFinish,
	setRawData,
	setFilteredData,
	setAvailableFilterOptions,
	resetFilters,
	applyFilter,
	removeFilter,
	resetComparison,
	setAppliedFilterCount,
	setAppliedComparisonItems,
	applyComparisonItem,
	removeComparisonItem,
	setAppliedComparisonItemCount,
	addCrossTabRow,
	removeCrossTabRow,
	addCrossTabColumn,
	removeCrossTabColumn,
	swapCrossTabRowsAndColumns,
	moveCrossTabFilterItemBetweenRowsAndColumns,
	updateCrossTabTable,
	loadQuestionnaireLanguagesStart,
	loadQuestionnaireLanguagesFinish,
	loadTranslatedQuestionTextsStart,
	loadTranslatedQuestionTextsFinish,
	setDisplayLanguage,
	addResponseLabelFinish,
	addResponseLabelStart,
	addQuestionnaireLabelFinish,
	addQuestionnaireLabelStart,
	loadLabelsStart,
	loadLabelsFinish,
	deleteLabelStart,
	deleteLabelFinish,
} = analysisSlice.actions;

export default analysisSlice.reducer;

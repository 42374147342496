import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	createAddress,
	deleteAddress,
	getAddressList,
	updateAddress,
} from '../../../api/services/Address';
import { getCityList } from '../../../api/services/City';
import { getDistrictsByCity } from '../../../api/services/District';
import { sendGoogleEvent } from '../../../utils/analytics';

import {
	createAddressFinish,
	deleteAddressFinish,
	loadAddressesFinish,
	loadAddressesStart,
	loadCitiesFinish,
	loadDistrictsFinish,
	updateAddressFinish,
} from '../../addressReducer';

function* loadAddressesSaga() {
	const result = yield getAddressList();

	yield put(loadAddressesFinish({ addresses: result.results }));
}

export function* watchLoadAddresses() {
	yield takeLatest('address/loadAddressesStart', loadAddressesSaga);
}

function* createAddressSaga(action) {
	yield createAddress({
		addressPayload: action.payload.addressPayload,
	});

	yield put(loadAddressesStart());

	yield put(createAddressFinish());

	sendGoogleEvent('addresses_add');
}

export function* watchCreateAddress() {
	yield takeLatest('address/createAddressStart', createAddressSaga);
}

function* updateAddressSaga(action) {
	yield updateAddress({
		addressNanoId: action.payload.addressNanoId,
		addressPayload: action.payload.addressPayload,
	});

	yield put(loadAddressesStart());

	yield put(
		updateAddressFinish({
			addressNanoId: action.payload.addressNanoId,
		}),
	);
	sendGoogleEvent('addresses_update');
}

export function* watchUpdateAddress() {
	yield takeEvery('address/updateAddressStart', updateAddressSaga);
}

function* deleteAddressSaga(action) {
	yield deleteAddress({
		addressNanoId: action.payload.addressNanoId,
	});

	yield put(loadAddressesStart());

	yield put(
		deleteAddressFinish({
			addressNanoId: action.payload.addressNanoId,
		}),
	);
	sendGoogleEvent('addresses_remove');
}

export function* watchDeleteAddress() {
	yield takeEvery('address/deleteAddressStart', deleteAddressSaga);
}

function* loadCitiesSaga() {
	const result = yield getCityList();
	yield put(loadCitiesFinish({ cities: result }));
}

export function* watchLoadCities() {
	yield takeLatest('address/loadCitiesStart', loadCitiesSaga);
}

function* loadDistrictsSaga(action) {
	const result = yield getDistrictsByCity({
		cityNanoId: action.payload.cityNanoId,
	});
	yield put(
		loadDistrictsFinish({
			cityNanoId: action.payload.cityNanoId,
			districts: result,
		}),
	);
}

export function* watchLoadDistricts() {
	yield takeLatest('address/loadDistrictsStart', loadDistrictsSaga);
}

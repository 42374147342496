import { tidy, filter, arrange, desc } from '@tidyjs/tidy';
import { indexOf, cloneDeep, find } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import {
	OtherResponsesList,
	OtherResponsesToggleButton,
} from './OtherResponses';
import { getTranslatedQuestionText } from '../../utils/Translation';

const generateTableData = (data, labels, question, comparison) => {
	// Generate data for the table
	// we are in comparison mode here so every row is a comparison item and columns are choices
	const tempTableData = [];
	if (Object.keys(comparison).length > 0) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					const choices = [];
					question.choices.forEach(choice => {
						const choiceTitle = choice.title ? choice.title : choice.value;
						const choiceValue = choice.nanoid ? choice.nanoid : choice.value;

						const choiceResponses = tidy(
							data,
							filter(d => {
								const dataType = typeof d[question.nanoid];
								if (dataType === 'string' || dataType === 'number') {
									// For single choice filter items
									return d[question.nanoid] === choiceValue;
								} else if (dataType === 'object') {
									//for multiple choice
									return indexOf(d[question.nanoid], choiceValue) > -1;
								}
							}),
						);

						const comparedChoiceCount = tidy(
							choiceResponses,
							filter(d => {
								const dataType = typeof d[comparisonKey];

								if (dataType === 'string' || dataType === 'number') {
									// For single choice filter items
									return d[comparisonKey] == comparisonItemKey;
								} else if (dataType === 'object') {
									// For multiple choice & ranking filter items
									const innerDataType =
										d[comparisonKey][0] == null
											? 'string'
											: typeof d[comparisonKey][0];

									if (innerDataType === 'string') {
										//for multiple choice
										return indexOf(d[comparisonKey], comparisonItemKey) > -1;
									} else if (innerDataType === 'object') {
										let foundItemIndex = d[comparisonKey].findIndex(
											answer =>
												answer.order == rankKey &&
												answer.choice === comparisonItemKey,
										);

										return foundItemIndex > -1;
									}
								}
							}),
						);

						choices.push({
							choiceTitle: choiceTitle,
							choice: choice, // This is the choice data coming from the API
							responseCount: comparedChoiceCount.length,
							media: choice.media,
						});
					});
					tempTableData.push({
						answer:
							rankKey == -1
								? labels[`${comparisonKey}_${comparisonItemKey}`]
								: labels[`${comparisonKey}_${comparisonItemKey}_${rankKey}`],
						comparisonKey: comparisonKey,
						comparisonItemKey: comparisonItemKey,
						comparisonRankKey: rankKey == -1 ? null : rankKey,
						choices: choices,
					});
				});
			});
		});

		return tidy(tempTableData, arrange(desc('responseCount')));
	} else {
		// Generate data for the table
		// we are not in comparison mode here so every row is just a choice

		question.choices.forEach(choice => {
			const choiceTitle = choice.title ? choice.title : choice.value;
			const choiceValue = choice.nanoid ? choice.nanoid : choice.value;

			const choices = tidy(
				data,
				filter(d => {
					const dataType = typeof d[question.nanoid];

					if (dataType === 'string' || dataType === 'number') {
						// For single choice filter items
						return d[question.nanoid] === choiceValue;
					} else if (dataType === 'object') {
						// For multiple choice filter items
						return indexOf(d[question.nanoid], choiceValue) > -1;
					}
				}),
			);

			tempTableData.push({
				answer: choiceTitle,
				choice: choice, // This is the choice data coming from the API
				responseCount: choices.length,
				media: choice.media,
				percentage:
					data.length > 0
						? `${parseFloat((choices.length / data.length) * 100).toFixed(2)}%`
						: `0%`,
			});
		});

		const arrangedTableData = tidy(
			tempTableData,
			arrange(desc('responseCount')),
		);

		//move other choice to the bottom if exists
		const otherChoiceIndex = arrangedTableData.findIndex(
			data => data.choice.choice_type === 'other',
		);

		if (otherChoiceIndex > -1) {
			const otherResponse = arrangedTableData.splice(otherChoiceIndex, 1);
			arrangedTableData.push(otherResponse[0]);
		}

		return arrangedTableData;
	}
};

function ComparisonRow({ question, row, data, language }) {
	const renderOtherRow = find(
		row.choices,
		i => i.choice.choice_type === 'other',
	);
	const [showOtherResponses, setShowOtherResponses] = useState(false);

	return (
		<>
			<tr>
				<td className="whitespace-pre-lines py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 break-words">
					{row.answer}
				</td>
				{row.choices.map((item, index) => (
					<td
						key={index}
						className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono"
					>
						<span>{item.responseCount}</span>
					</td>
				))}
				{row.choices[row.choices.length - 1].choice.choice_type === 'other' && (
					<td
						className={classNames(
							showOtherResponses ? 'border-r-4 border-r-indigo-600' : '',
							'text-right w-auto',
						)}
					>
						<OtherResponsesToggleButton
							className="mr-2"
							showOtherResponses={showOtherResponses}
							setShowOtherResponses={setShowOtherResponses}
						/>
					</td>
				)}
			</tr>
			{renderOtherRow && showOtherResponses && (
				<OtherResponsesList
					key={question.nanoid}
					data={data}
					filterKey={row.comparisonKey}
					filterValue={row.comparisonItemKey}
					filterRank={row.comparisonRankKey}
					question={question}
					language={language}
					colSpan={row.choices.length + 2}
				/>
			)}
		</>
	);
}

export default function Table({
	translations,
	language,
	data,
	labels,
	question,
	comparison,
	isCompareMode,
}) {
	const [showOtherResponses, setShowOtherResponses] = useState(false);

	const { t } = useTranslation();
	const q = cloneDeep(question);
	Array.from({ length: question.config.number_of_shape }, (_, i) => {
		q.choices.push({
			value: i + 1,
		});
	});

	const tableData = generateTableData(data, labels, q, comparison);

	return (
		<div className="overflow-hidden relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
			<div className="overflow-y-auto">
				<table className="w-full divide-y divide-gray-300 overflow-y-auto table-auto">
					<thead className="bg-gray-50">
						{!isCompareMode && (
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left"
								>
									{t('Answer')}
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right"
								>
									{t('Responses')}
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right"
								>
									{t('Percentage')}
								</th>
							</tr>
						)}
						{isCompareMode && (
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 w-48 text-left "
								>
									{t('Comparison Item')}
								</th>
								{tableData[0].choices.map((item, index) => (
									<th
										key={index}
										scope="col"
										className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right w-20 break-words"
									>
										{item.choiceTitle}
									</th>
								))}
								{question.config.show_other && <th>&nbsp;</th>}
							</tr>
						)}
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{!isCompareMode && (
							<>
								{tableData.map((row, index) => (
									<tr key={index}>
										<td
											className={classNames(
												showOtherResponses && row.choice.choice_type === 'other'
													? 'border-l-4 border-l-indigo-600 sm:pl-5'
													: '',
												row.choice.choice_type === 'other'
													? 'items-center'
													: '',
												'whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 ',
											)}
										>
											<div className="flex items-center gap-2">
												{(question.question_type === 'single_selection' ||
													question.question_type === 'multiple_selection' ||
													question.question_type === 'yes_no') && (
													<div className="whitespace-pre-line break-all">
														{getTranslatedQuestionText(
															translations,
															language,
															row.choice.nanoid,
															row.answer,
														)}
													</div>
												)}
												{question.question_type !== 'single_selection' &&
													question.question_type !== 'multiple_selection' &&
													question.question_type !== 'yes_no' && (
														<>{row.answer}</>
													)}

												{row.choice.choice_type === 'other' && (
													<OtherResponsesToggleButton
														showOtherResponses={showOtherResponses}
														setShowOtherResponses={setShowOtherResponses}
													/>
												)}
											</div>
											{row.media && (
												<a
													href={row.media.url}
													target="_blank"
													rel="noreferrer"
												>
													<img src={row.media.url} className="w-32 rounded" />
												</a>
											)}
										</td>
										<td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono">
											<span>{row.responseCount}</span>
										</td>
										<td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono">
											<span>{row.percentage}</span>
										</td>
									</tr>
								))}
								{question.config.show_other && showOtherResponses && (
									<OtherResponsesList
										key={question.nanoid}
										data={data}
										question={q}
										language={language}
									/>
								)}
							</>
						)}

						{isCompareMode && (
							<>
								{tableData.map((row, index) => (
									<ComparisonRow
										key={index}
										question={question}
										row={row}
										data={data}
										language={language}
									/>
								))}
							</>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

import { tidy, filter } from '@tidyjs/tidy';
import { indexOf } from 'lodash';
import { useEffect, useState } from 'react';
import { classNames } from '../../utils';
import BarChart from './BarChart';
import Table2 from './Table2';
import { calculateChartKeys } from './utils';
import { useTranslation } from 'react-i18next';
import {
	calculateArithmeticMean,
	calculateMedian,
	calculateStandardDeviation,
} from '../../utils/analysis';

const heightMap = {
	1: 'h-[10rem]',
	2: 'h-[10rem]',
	3: 'h-[10rem]',
	4: 'h-[15rem]',
	5: 'h-[15rem]',
	6: 'h-[15rem]',
	7: 'h-[25rem]',
	8: 'h-[25rem]',
	9: 'h-[25rem]',
	10: 'h-[25rem]',
	11: 'h-[25rem]',
};

function StatsTable({ data, question, isCompareMode, comparison, labels }) {
	const { t } = useTranslation();

	const statsColumnHeads = [
		{
			title: '',
			alignLeft: true,
		},
		{
			title: t('analysis.opinionScale.stats.average'),
		},
		{
			title: t('analysis.opinionScale.stats.median'),
		},
		{
			title: t('analysis.opinionScale.stats.stdev.s'),
		},
		{
			title: t('analysis.opinionScale.stats.stdev.p'),
		},
	];

	let statRows = [];

	if (isCompareMode) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					const comparisonResponses = tidy(
						data,
						filter(d => {
							const dataType = typeof d[comparisonKey];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[comparisonKey] == comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice & ranking filter items
								const innerDataType =
									d[comparisonKey][0] == null
										? 'string'
										: typeof d[comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									return indexOf(d[comparisonKey], comparisonItemKey) > -1;
								} else if (innerDataType === 'object') {
									let foundItemIndex = d[comparisonKey].findIndex(
										answer =>
											answer.order == rankKey &&
											answer.choice === comparisonItemKey,
									);

									return foundItemIndex > -1;
								}
							}
						}),
					);

					const key =
						rankKey == -1
							? `${comparisonKey}_${comparisonItemKey}`
							: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
					const label = labels[key];

					const selectedNumbers = comparisonResponses
						.filter(d => d[question.nanoid])
						.map(d => d[question.nanoid]);

					const newRow = [
						{
							value: label,
							alignLeft: true,
						},
						{
							value: calculateArithmeticMean(selectedNumbers),
							mono: true,
						},
						{
							value: calculateMedian(selectedNumbers),
							mono: true,
						},
						{
							value: calculateStandardDeviation(selectedNumbers, 'S'),
							mono: true,
						},
						{
							value: calculateStandardDeviation(selectedNumbers, 'P'),
							mono: true,
						},
					];
					statRows.push(newRow);
				});
			});
		});
	} else {
		const selectedNumbers = data
			.filter(d => d[question.nanoid])
			.map(d => d[question.nanoid]);

		statRows = [
			[
				{
					value: t('analysis.opinionScale.stats.allResponses'),
					alignLeft: true,
				},
				{
					value: calculateArithmeticMean(selectedNumbers),
					mono: true,
				},
				{
					value: calculateMedian(selectedNumbers),
					mono: true,
				},
				{
					value: calculateStandardDeviation(selectedNumbers, 'S'),
					mono: true,
				},
				{
					value: calculateStandardDeviation(selectedNumbers, 'P'),
					mono: true,
				},
			],
		];
	}

	return (
		<>
			<Table2 columnHeads={statsColumnHeads} rows={statRows} />
		</>
	);
}

export default function OpinionScaleQuestion({
	data,
	question,
	labels,
	comparison,
	isCompareMode,
}) {
	const { t } = useTranslation();
	const [di, setDi] = useState([]);
	const keys = calculateChartKeys(comparison);
	const [columnHeads, setColumnHeads] = useState([]);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		const _di = [];

		for (
			var i = question.config.minimum;
			i < question.config.maximum + 1;
			i++
		) {
			const returnData = {
				country: i,
			};

			if (isCompareMode) {
				const ratingResponses = tidy(
					data,
					filter(d => {
						return d[question.nanoid] === i;
					}),
				);

				Object.keys(comparison).forEach(comparisonKey => {
					Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
						const rankKeys = Object.keys(
							comparison[comparisonKey][comparisonItemKey],
						);
						const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
						rankArray.forEach(rankKey => {
							// TODO: Use analysisFilter util
							const comparisonResponses = tidy(
								ratingResponses,
								filter(d => {
									const dataType = typeof d[comparisonKey];
									if (dataType === 'string' || dataType === 'number') {
										// For single choice filter items
										return d[comparisonKey] == comparisonItemKey;
									} else if (dataType === 'object') {
										// For multiple choice & ranking filter items
										const innerDataType =
											d[comparisonKey][0] == null
												? 'string'
												: typeof d[comparisonKey][0];

										if (innerDataType === 'string') {
											//for multiple choice
											return indexOf(d[comparisonKey], comparisonItemKey) > -1;
										} else if (innerDataType === 'object') {
											let foundItemIndex = d[comparisonKey].findIndex(
												answer =>
													answer.order == rankKey &&
													answer.choice === comparisonItemKey,
											);

											return foundItemIndex > -1;
										}
									}
								}),
							);
							const key =
								rankKey == -1
									? `${comparisonKey}_${comparisonItemKey}`
									: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;

							returnData[key] =
								ratingResponses.length > 0
									? comparisonResponses.length / ratingResponses.length
									: 0;
						});
					});
				});
			} else {
				const dat = tidy(
					data,
					filter(d => d[question.nanoid] === i),
				);

				returnData['responsePercentage'] =
					data.length > 0 ? dat.length / data.length : 0;
			}
			returnData['country'] = returnData['country'].toString();

			_di.push(returnData);
		}

		setDi(_di);
	}, [comparison, data, isCompareMode, question.config, question.nanoid]);

	useEffect(() => {
		const newColumnHeads = [
			{
				title: isCompareMode ? 'Comparison' : '',
				alignLeft: true,
			},
		];

		di.forEach(item => {
			newColumnHeads.push({
				title: item.country,
				alignLeft: false,
			});
		});

		setColumnHeads(newColumnHeads);

		const newRows = [];

		if (isCompareMode) {
			Object.keys(comparison).forEach(comparisonKey => {
				Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
					const rankKeys = Object.keys(
						comparison[comparisonKey][comparisonItemKey],
					);
					const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
					rankArray.forEach(rankKey => {
						const key =
							rankKey == -1
								? `${comparisonKey}_${comparisonItemKey}`
								: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
						const label = labels[key];

						const row = [
							{
								value: label,
							},
						];

						di.forEach(item => {
							row.push({
								value: `${(item[key] * 100).toFixed(2)}%`,
								mono: true,
							});
						});

						newRows.push(row);
					});
				});
			});
		} else {
			const row = [
				{
					value: 'Percentage',
				},
			];

			di.forEach(item => {
				row.push({
					value: `${(item.responsePercentage * 100).toFixed(2)}%`,
					mono: true,
				});
			});

			newRows.push(row);
		}
		setRows(newRows);
	}, [comparison, di, isCompareMode, labels]);

	const scaleSize = Math.min(
		Math.max(question.config.maximum - question.config.minimum, 1),
		Object.keys(heightMap).length,
	);
	const heightClass = heightMap[scaleSize];

	return (
		<div className="px-4 pb-4 sm:px-6 flex flex-col gap-4">
			<div className={classNames(heightClass)}>
				<BarChart
					legend={t('% of all responses')}
					keys={keys}
					data={di}
					legendLabels={labels}
					isCompareMode={isCompareMode}
				/>
			</div>
			<Table2 columnHeads={columnHeads} rows={rows} />
			<StatsTable
				data={data}
				question={question}
				isCompareMode={isCompareMode}
				comparison={comparison}
				labels={labels}
			/>
		</div>
	);
}

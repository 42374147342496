import TextareaAutosize from 'react-textarea-autosize';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateQuestionTitle,
	updateQuestionDescription,
	createChoice,
	updateChoice,
	deleteChoice,
	reorderChoiceList,
} from '../../store/projectEditorSlice';
import TextQuestion from './questions/TextQuestion';
import NumberSelectionQuestion from './questions/NumberSelectionQuestion';
import MultipleSelectionQuestion from './questions/MultipleSelectionQuestion';
import SingleSelectionQuestion from './questions/SingleSelectionQuestion';
import YesNoQuestion from './questions/YesNoQuestion';
import RankingQuestion from './questions/RankingQuestion';
import RatingQuestion from './questions/RatingQuestion';
import OpinionScaleQuestion from './questions/OpinionScaleQuestion';
import MatrixQuestion from './questions/MatrixQuestion';
import FileUploadQuestion from './questions/FileUploadQuestion';
import {
	classNames,
	isChoiceUsedInLogic,
	newChoiceTemplate,
} from '../../utils';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { updatePreChoice } from '../../api/services/PreChoice';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../utils/analytics';
import { QUESTION_META_DATA } from '../../constants';
import ValidationMessage from './ValidationMessage';
import PreviewButton from '../PreviewButton';
import AddQuestionButton from '../AddQuestionButton';
import VideoPlayer from './VideoPlayer';

export default function QuestionEditor({
	index,
	question,
	questionnaireNanoId,
	isEditable = true,
	isScreeningQuestion = false,
	length,
	setOpen,
	totalQuestions,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const updateTitle = event => {
		dispatch(
			updateQuestionTitle({
				questionIndex: index,
				question: question,
				title: event.target.value,
			}),
		);
	};

	const updateDescription = event => {
		dispatch(
			updateQuestionDescription({
				questionIndex: index,
				question: question,
				description: event.target.value,
			}),
		);
	};

	const handleCreateChoice = () => {
		const newChoice = newChoiceTemplate({ title: '' });

		dispatch(
			createChoice({
				question: question,
				newChoice: newChoice,
			}),
		);
		sendGoogleEvent('questionnaire_choice_add', {
			question_type: question.question_type,
			choice_type: newChoice.choice_type,
		});
	};

	const handleReorderChoiceList = ({ question, source, destination }) => {
		dispatch(
			reorderChoiceList({
				question: question,
				source: source,
				destination: destination,
			}),
		);
	};

	const handleDeleteChoice = ({ question, index, choice }) => {
		if (isChoiceUsedInLogic(choice, questionList)) {
			NiceModal.show(Modal, {
				icon: 'warning',
				title: t('Cannot Delete {{option}}', {
					option: t('Choice'),
				}),
				message: t('questionEditor.modal.deleteErrorMsg'),
				showPrimaryButton: false,
				secondaryButtonLabel: t('Close'),
			});
		} else {
			dispatch(
				deleteChoice({ question: question, index: index, choice: choice }),
			);
			sendGoogleEvent('questionnaire_choice_remove', {
				question_type: question.question_type,
				choice_type: choice.choice_type,
			});
		}
	};

	const handleChoiceTitleChange = ({ question, choice, title }) => {
		dispatch(
			updateChoice({
				question: question,
				choice: choice,
				title: title,
			}),
		);
	};

	const handleChoiceImageTooLarge = () => {
		NiceModal.show(Modal, {
			icon: 'error',
			title: t('questionEditor.modal.fileError'),
			message: t('questionEditor.modal.fileErrorMsg'),
			showPrimaryButton: false,
			secondaryButtonLabel: t('Close'),
		});
	};

	const handleChoiceImageChange = async ({ question, choice, newImage }) => {
		const res = await updatePreChoice({
			questionnaireNanoId: questionnaireNanoId,
			preQuestionNanoId: question.nanoid,
			preChoiceNanoId: choice.nanoid,
			payload: { image: newImage },
		});
		dispatch(
			updateChoice({
				question: question,
				choice: choice,
				media: res.media,
			}),
		);
	};
	const handleChoiceQualifyChange = ({ question, choice, isQualified }) => {
		dispatch(
			updateChoice({
				question: question,
				choice: choice,
				is_qualified: isQualified,
			}),
		);
	};

	const canAdd = question?.question_type !== 'yes_no';
	const canDelete = question?.question_type !== 'yes_no';
	const canReorder = question?.question_type !== 'yes_no';

	return (
		<>
			{question && (
				<div
					className={classNames(
						QUESTION_META_DATA[question.question_type].borderColor,
						'tour-question-editor bg-white border-l-4 shadow relative rounded object-center group-two',
					)}
				>
					<div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t">
						<div className="flex gap-2 justify-between items-top">
							<div className="flex grow gap-2">
								<div
									className={classNames(
										QUESTION_META_DATA[question.question_type].borderColor,
										QUESTION_META_DATA[question.question_type].textColor,
										'border-2 w-12 h-12 flex flex-col items-center justify-around rounded font-mono',
									)}
								>
									{`Q${index + 1}`}
								</div>
								<div className="flex flex-col grow">
									<TextareaAutosize
										name="title"
										id="title"
										value={question.title}
										onChange={updateTitle}
										className="resize-none p-0 placeholder:text-lg text-lg font-medium block w-full border-0 border-b border-transparent bg-white focus:border-indigo-600 focus:ring-0"
										placeholder={t('Your question here.')}
										disabled={!isEditable}
										minRows={1}
										maxRows={3}
									/>
									{question.errors?.title && (
										<ValidationMessage
											message={question.errors?.title}
											className="mt-1 mb-2"
										/>
									)}

									<TextareaAutosize
										name="description"
										id="description"
										value={question.description}
										onChange={updateDescription}
										className="resize-none p-0 placeholder:italic block w-full border-0 border-b border-transparent bg-white focus:border-indigo-600 focus:ring-0 sm:text-sm"
										placeholder={t('Description (optional)')}
										disabled={!isEditable}
										minRows={1}
										maxRows={3}
									/>

									{question.errors?.description && (
										<ValidationMessage
											message={question.errors?.description}
											className="mt-1 mb-2"
										/>
									)}
								</div>
							</div>

							{!isScreeningQuestion && (
								<div className="transition-opacity ease-in-out opacity-0 group-two-hover:opacity-100">
									<PreviewButton
										questionnaireNanoId={questionnaireNanoId}
										questionType={question.question_type}
										setOpen={setOpen}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="bg-white shadow rounded object-center w-full h-2/3">
						<div className="px-4 py-5 overflow-y-auto sm:p-6">
							<div
								className={classNames(
									isScreeningQuestion ? 'ml-6 mr-16 md:mx-12' : 'mx-12',
								)}
							>
								<div className="flex justify-between gap-x-2">
									<div
										className={`grow relative group-one mb-4 ${
											question.question_type === 'matrix' &&
											'overflow-y-auto p-1'
										}`}
									>
										{question.media && question.media.media_type === 'image' && (
											<div className="relative group">
												<img
													className=" rounded mb-4 h-6/12 "
													src={question.media.url}
												/>
											</div>
										)}
										{question.media &&
											question.media.media_type === 'video' &&
											question.media.stream_url && (
												<div className="mb-4">
													<VideoPlayer url={question.media.stream_url} />
												</div>
											)}

										{question.question_type === 'text' && (
											<TextQuestion question={question} isEditMode={true} />
										)}
										{question.question_type === 'number' && (
											<NumberSelectionQuestion
												isEditMode={true}
												question={question}
											/>
										)}
										{question.question_type === 'multiple_selection' && (
											<MultipleSelectionQuestion
												question={question}
												isEditable={isEditable}
												canAdd={canAdd && isEditable}
												canDelete={canDelete && isEditable}
												canReorder={canReorder && isEditable}
												createChoice={createChoice}
												handleReorderChoiceList={handleReorderChoiceList}
												deleteChoice={deleteChoice}
												handleChoiceTitleChange={handleChoiceTitleChange}
												handleChoiceImageChange={handleChoiceImageChange}
												handleChoiceImageTooLarge={handleChoiceImageTooLarge}
												handleCreateChoice={handleCreateChoice}
												handleDeleteChoice={handleDeleteChoice}
												isScreeningQuestion={isScreeningQuestion}
												handleChoiceQualifyChange={handleChoiceQualifyChange}
											/>
										)}
										{question.question_type === 'single_selection' && (
											<SingleSelectionQuestion
												question={question}
												isEditable={isEditable}
												canAdd={canAdd && isEditable}
												canDelete={canDelete && isEditable}
												canReorder={canReorder && isEditable}
												createChoice={createChoice}
												handleReorderChoiceList={handleReorderChoiceList}
												handleChoiceTitleChange={handleChoiceTitleChange}
												handleChoiceImageChange={handleChoiceImageChange}
												handleChoiceImageTooLarge={handleChoiceImageTooLarge}
												handleCreateChoice={handleCreateChoice}
												handleDeleteChoice={handleDeleteChoice}
												isScreeningQuestion={isScreeningQuestion}
												handleChoiceQualifyChange={handleChoiceQualifyChange}
											/>
										)}
										{question.question_type === 'yes_no' && (
											<YesNoQuestion
												question={question}
												isEditable={isEditable}
												isEditMode={true}
												handleChoiceImageChange={handleChoiceImageChange}
												handleChoiceImageTooLarge={handleChoiceImageTooLarge}
												isScreeningQuestion={isScreeningQuestion}
												handleChoiceQualifyChange={handleChoiceQualifyChange}
											/>
										)}
										{question.question_type === 'ranking' && (
											<RankingQuestion
												question={question}
												isEditable={isEditable}
												isEditMode={true}
												canAdd={canAdd && isEditable}
												canDelete={canDelete}
												canReorder={canReorder}
												createChoice={createChoice}
												handleReorderChoiceList={handleReorderChoiceList}
												handleChoiceTitleChange={handleChoiceTitleChange}
												handleChoiceImageChange={handleChoiceImageChange}
												handleChoiceImageTooLarge={handleChoiceImageTooLarge}
												handleCreateChoice={handleCreateChoice}
												handleDeleteChoice={handleDeleteChoice}
											/>
										)}
										{question.question_type === 'rating' && (
											<RatingQuestion question={question} isEditMode={true} />
										)}
										{(question.question_type === 'nps' ||
											question.question_type === 'opinion_scale') && (
											<OpinionScaleQuestion
												question={question}
												isEditMode={true}
												isEditable={isEditable}
											/>
										)}
										{question.question_type === 'matrix' && (
											<MatrixQuestion
												question={question}
												isEditMode={true}
												isEditable={isEditable}
											/>
										)}
										{question.question_type === 'file_upload' && (
											<FileUploadQuestion
												question={question}
												isEditMode={true}
											/>
										)}
										{question.question_type === 'description' && (
											<div className=" my-16 text-sm text-center text-gray-700">
												{t(
													'questionEditor.questionTypes.description.explanation',
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="flex flex-row-reverse mt-4 mb-80 z-50">
				<AddQuestionButton
					isEditable={isEditable}
					isScreeningQuestion={isScreeningQuestion}
					length={length}
					totalQuestions={totalQuestions}
				/>
			</div>
		</>
	);
}

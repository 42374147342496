import { useDispatch, useSelector } from 'react-redux';
import {
	CloudArrowDownIcon,
	LanguageIcon,
	FaceSmileIcon,
} from '@heroicons/react/24/outline';
import Tabs from '../Tabs';
import ProgressBar from '../ProgressBar';
import Dropdown from '../Dropdown';
import { useTranslation } from 'react-i18next';
import { findIndex, find } from 'lodash';
import { filter as lodashFilter } from 'lodash';
import { sendGoogleEvent } from '../../utils/analytics';
import { arrayToCsv, selectProjectFilterCategories } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	LANGUAGE_NAMES,
	getTranslatedQuestionText,
	getTranslatedResponseText,
} from '../../utils/Translation';
import { setDisplayLanguage } from '../../store/analysisSlice';
import * as XLSX from 'xlsx';
import { Buttons } from '../Button';
import Badge from '../Badge';
import { toggleProjectFeedbackSlideOver } from '../../store/projectSlice';

const optionDropdownItems = [
	{
		items: [
			{
				title: 'Download all responses as CSV', //t('Download all responses as CSV')
				type: 'analysis_export_all_as_csv',
				icon: CloudArrowDownIcon,
				disabled: false,
			},
			{
				title: 'Download all responses as Excel', //t('Download all responses as Excel')
				type: 'analysis_export_all_as_excel',
				icon: CloudArrowDownIcon,
				disabled: false,
			},
			{
				title: 'Download all responses that match the filter as CSV', //t('Download all responses that match the filter as CSV')
				type: 'analysis_export_filtered_as_csv',
				icon: CloudArrowDownIcon,
				disabled: true,
			},
			{
				title: 'Download all responses that match the filter as Excel', //t('Download all responses that match the filter as Excel')
				type: 'analysis_export_filtered_as_excel',
				icon: CloudArrowDownIcon,
				disabled: true,
			},
			{
				title: 'Rate your experience', //t('Rate your experience')
				type: 'rate_experience',
				icon: FaceSmileIcon,
				disabled: false,
			},
		],
	},
];

const getChoiceTitleByNanoId = (choiceList, nanoId) => {
	const choice = find(choiceList, choice => choice.nanoid === nanoId);
	if (choice) {
		return choice.title;
	}
	return null;
};

function LanguageDropdownHeader() {
	const { t } = useTranslation();
	// Translate Results
	// Display results in your language.
	return (
		<>
			<p className="text-sm font-medium">
				{t('translations.dropdownHeader.title')}
			</p>
			<p className="text-sm text-gray-900">
				{t('translations.dropdownHeader.subTitle')}
			</p>
		</>
	);
}

export default function AnalysisHeader({
	selectedTab,
	rawData,
	filteredData,
	hasFilter,
	translations,
	language,
	setIsFilterOpen,
	setIsCompareOpen,
	setCurrentTab,
	appliedFilterCount,
	appliedComparisonItemCount,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [dropdownItems, setDropDownItems] = useState(optionDropdownItems);
	const { nanoid } = useParams();
	const navigate = useNavigate();

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);
	const reach = useSelector(state => state.projectEditor.audience.reach);

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);

	const questionnaireLanguages =
		projectAnalysis?.translations.questionnaireLanguages.languages;
	const translatedQuestionTexts =
		projectAnalysis?.translations.translatedQuestionTexts;
	const questionnaireLanguagesNanoIds = {};

	const availableFilterOptions = projectAnalysis
		? projectAnalysis.filterData.availableFilterOptions
		: {};

	const languageDropdownItems = [
		{
			items: [
				{
					title: LANGUAGE_NAMES['tr'],
					type: 'tr',
					icon: LanguageIcon,
					disabled: false,
				},
			],
		},
	];

	if (questionnaireLanguages) {
		questionnaireLanguages.forEach(language => {
			questionnaireLanguagesNanoIds[language.language] = language.nanoid;
			languageDropdownItems[0].items.push({
				type: language.language,
				title: LANGUAGE_NAMES[language.language]
					? LANGUAGE_NAMES[language.language]
					: language.language,
				icon: LanguageIcon,
				disabled:
					translatedQuestionTexts[language.language].status === 'loading',
			});
		});
	}

	const progressPercentage =
		reach > 0 ? ((rawData.length / reach) * 100).toFixed(2) : 0;

	useEffect(() => {
		let tempItems = dropdownItems;

		tempItems.map(item =>
			item.items.map(it => {
				if (
					it.type === 'analysis_export_filtered_as_csv' ||
					it.type === 'analysis_export_filtered_as_excel'
				) {
					it.disabled = !hasFilter;
				}
				return it;
			}),
		);
		setDropDownItems([...tempItems]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFilter]);

	const exportResponsesData = (data, fileTitle, exportType) => {
		const rows = [
			[t('Response ID'), t('Response Date')], // First row
			['', ''], // Second row – more "columns" will be added here below depending on the type of each question
		];

		// A map to tell the algorithm below which choice to put in which "column"
		const columnToChoiceMap = {};

		// A map to ...
		const rowToQuestionMap = {};

		questionList.forEach(question => {
			if (question.question_type === 'description') {
				return;
			}
			rowToQuestionMap[rows[0].length] = question.nanoid;

			rows[0].push(
				getTranslatedQuestionText(
					translations,
					language,
					question.nanoid,
					question.title,
				),
			);

			switch (question.question_type) {
				case 'rating':
				case 'nps':
				case 'yes_no':
				case 'opinion_scale':
				case 'single_selection':
					rows[1].push(t('Response'));
					break;
				case 'text':
				case 'number':
					rows[1].push(t('Open-ended Response'));
					break;
				case 'file_upload':
					rows[1].push(t('File Links'));
					break;
				case 'ranking':
				case 'multiple_selection':
					question.choices.forEach((choice, index) => {
						if (index < question.choices.length - 1) {
							rows[0].push('');
						}
						columnToChoiceMap[rows[1].length] = choice.nanoid;

						rows[1].push(
							getTranslatedQuestionText(
								translations,
								language,
								choice.nanoid,
								choice.title,
							),
						);
					});
					break;
				case 'matrix': {
					const rowCount = lodashFilter(
						question.choices,
						item => item.choice_type === 'row',
					).length;

					let count = 1;
					question.choices.forEach(choice => {
						if (choice.choice_type === 'row') {
							if (count < rowCount) {
								rows[0].push('');
								count++;
							}

							columnToChoiceMap[rows[1].length] = choice.nanoid;

							rows[1].push(
								getTranslatedQuestionText(
									translations,
									language,
									choice.nanoid,
									choice.title,
								),
							);
						}
					});
					break;
				}
			}
		});

		const categoryFieldMap = {};

		availableFilterOptions.forEach(section => {
			if (section.slug !== '') {
				section.category_set
					.filter(category =>
						selectProjectFilterCategories(nanoid, category.slug),
					)
					.forEach(category => {
						category.categoryfield_set.forEach(categoryField => {
							categoryFieldMap[categoryField.nanoid] = categoryField.name;
						});

						rows[0].push(category.name);
						rows[1].push(t('Demographics'));
					});
			}
		});

		data.forEach(response => {
			const responseRow = [response.nanoid, response.created];
			questionList.forEach(question => {
				if (question.question_type === 'description') {
					return;
				}
				switch (question.question_type) {
					case 'yes_no':
					case 'single_selection':
						{
							const choiceTitle = getChoiceTitleByNanoId(
								question.choices,
								response[question.nanoid],
							);
							const translatedChoiceTitle = getTranslatedQuestionText(
								translations,
								language,
								response[question.nanoid],
								choiceTitle,
							);
							responseRow.push(translatedChoiceTitle);
						}
						break;
					case 'opinion_scale':
					case 'nps':
					case 'rating':
					case 'text':
					case 'number':
						{
							const translatedResponseText = getTranslatedResponseText(
								response,
								question.nanoid,
								language,
							);
							responseRow.push(translatedResponseText);
						}
						break;
					case 'file_upload': {
						let finalStr = '';
						response[question.nanoid].forEach(fileUpload => {
							finalStr += `${fileUpload.file_url},`;
						});
						responseRow.push(finalStr);
						break;
					}
					case 'ranking':
						question.choices.forEach(choice => {
							const foundItem = find(
								response[question.nanoid],
								item => item.choice === choice.nanoid,
							);
							if (foundItem) {
								responseRow.push(foundItem.order);
							} else {
								responseRow.push('');
							}
						});
						break;
					case 'multiple_selection':
						question.choices.forEach(choice => {
							if (
								findIndex(response[question.nanoid], i => i === choice.nanoid) >
								-1
							) {
								responseRow.push(choice.title);
							} else {
								responseRow.push('');
							}
						});
						break;
					case 'matrix':
						question.choices.forEach(choice => {
							if (choice.choice_type === 'row') {
								const foundItem = find(
									response[question.nanoid],
									item => item.choice === choice.nanoid,
								);

								if (foundItem) {
									let finalStr = [];
									question.choices.forEach(columnChoice => {
										if (columnChoice.choice_type === 'column') {
											const foundColumnItem = find(
												foundItem.columns,
												item => item.choice === columnChoice.nanoid,
											);

											if (foundColumnItem) {
												const choiceTitle = getChoiceTitleByNanoId(
													question.choices,
													foundColumnItem.choice,
												);

												const translatedChoiceTitle = getTranslatedQuestionText(
													translations,
													language,
													foundColumnItem.choice,
													choiceTitle,
												);

												finalStr.push(`${translatedChoiceTitle}`);
											}
										}
									});

									responseRow.push(finalStr.join(','));
								} else {
									responseRow.push('');
								}
							}
						});
						break;
				}
			});

			availableFilterOptions.forEach(section => {
				if (section.slug !== '') {
					section.category_set
						.filter(category =>
							selectProjectFilterCategories(nanoid, category.slug),
						)
						.forEach(category => {
							const responseDemographic = response[category.slug]
								? response[category.slug].constructor === Array
									? response[category.slug]
									: [response[category.slug]]
								: [];

							const responseDemographicNameList = responseDemographic.map(
								demographicNanoId => categoryFieldMap[demographicNanoId],
							);

							responseRow.push(responseDemographicNameList.join());
						});
				}
			});

			rows.push(responseRow);
		});

		if (exportType === 'excel') {
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.aoa_to_sheet(rows);

			XLSX.utils.book_append_sheet(workbook, worksheet, fileTitle);
			XLSX.writeFile(workbook, fileTitle);

			return;
		}

		const csvContent = 'data:text/csv;charset=utf-8,' + arrayToCsv(rows);

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', fileTitle);
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "Responses.csv".
		document.body.removeChild(link);
	};

	const handleOptionSelection = item => {
		switch (item.type) {
			case 'analysis_export_all_as_csv': {
				exportResponsesData(rawData, 'Responses.csv', 'csv');
				sendGoogleEvent('analysis_export_all_as_csv', { export_type: 'csv' });
				break;
			}
			case 'analysis_export_all_as_excel': {
				exportResponsesData(rawData, 'Responses.xlsx', 'excel');
				sendGoogleEvent('analysis_export_all_as_excel', {
					export_type: 'excel',
				});
				break;
			}
			case 'analysis_export_filtered_as_csv': {
				exportResponsesData(filteredData, 'FilteredResponses.csv', 'csv');
				sendGoogleEvent('analysis_export_filtered_as_csv', {
					export_type: 'csv',
				});
				break;
			}
			case 'analysis_export_filtered_as_excel': {
				exportResponsesData(filteredData, 'FilteredResponses.xlsx', 'excel');
				sendGoogleEvent('analysis_export_filtered_as_excel', {
					export_type: 'excel',
				});
				break;
			}
			case 'rate_experience': {
				dispatch(toggleProjectFeedbackSlideOver(true));
				break;
			}
		}
	};

	const handleLanguageSelection = item => {
		dispatch(
			setDisplayLanguage({
				projectNanoId: nanoid,
				questionnaireLanguageNanoId: questionnaireLanguagesNanoIds[item.type],
				language: item.type,
			}),
		);
	};

	return (
		<div className="w-full flex gap-4 items-center flex-col md:flex-row">
			<div className="w-full md:w-fit">
				<Tabs
					size="md"
					tabs={[
						{ key: 'questions', title: t('analysis.modes.questions') },
						{ key: 'cross-tab', title: t('analysis.modes.crossTab') },
					]}
					className="w-full md:w-fit"
					selectedTab={selectedTab}
					onChange={newTab => {
						if (newTab === 'questions') {
							navigate(`/project/${nanoid}/analysis`);
						} else if (newTab === 'cross-tab') {
							navigate(`/project/${nanoid}/analysis/cross-tab`);
						}
					}}
				/>
			</div>

			<div className="tour-analysis-progress flex flex-col gap-1 flex-1 w-full md:w-fit">
				<div className="flex justify-between text-xs font-medium text-gray-500">
					<div>0</div>
					<div>{t('Progress {{number}}%', { number: progressPercentage })}</div>
					<div>{reach}</div>
				</div>
				<ProgressBar progress={progressPercentage} />
			</div>
			{languageDropdownItems[0].items.length > 1 && (
				<div>
					<Dropdown
						label={`${t('translations.language')}: ${
							translations
								? LANGUAGE_NAMES[translations.displayLanguage]
									? LANGUAGE_NAMES[translations.displayLanguage]
									: translations.displayLanguage
								: 'selam'
						}`}
						items={languageDropdownItems}
						onChange={handleLanguageSelection}
						headerComponent={<LanguageDropdownHeader />}
					/>
				</div>
			)}
			<div className="tour-analysis-export flex justify-between items-center w-full md:w-fit md:block">
				<div className="flex md:hidden justify-between gap-2">
					<Buttons
						measure="small"
						kind="white"
						onClick={() => {
							setIsFilterOpen(true);
							setCurrentTab('filter');
						}}
					>
						{t('Filter')}{' '}
						{appliedFilterCount > 0 && (
							<Badge type={'complete'}>{appliedFilterCount}</Badge>
						)}
					</Buttons>
					<Buttons
						measure="small"
						kind="white"
						onClick={() => {
							setIsCompareOpen(true);
							setCurrentTab('compare');
						}}
					>
						{t('Compare')}
						{appliedComparisonItemCount > 0 && (
							<Badge type={'complete'}>{appliedComparisonItemCount}</Badge>
						)}
					</Buttons>
				</div>
				<Dropdown
					label={t('Options')}
					items={dropdownItems}
					onChange={handleOptionSelection}
				/>
			</div>
		</div>
	);
}

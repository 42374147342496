import { useEffect, useRef, useState } from 'react';
import { tidy, filter, arrange, asc } from '@tidyjs/tidy';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BarChart from './BarChart';
import ContextMenu from './ContextMenu';
import Table from './Table';
import { indexOf } from 'lodash';
import { arrayToCsv, classNames } from '../../utils';
import { QUESTION_META_DATA } from '../../constants';
import DataListDisplay from './DataListDisplay';
import RatingQuestion from './RatingQuestion';
import RankingQuestion from './RankingQuestion';
import { calculateChartKeys } from './utils';
import NpsQuestion from './NpsQuestion';
import { useTranslation } from 'react-i18next';
import { FileUploadQuestion } from './FileUploadQuestion';
import MatrixQuestion from './MatrixQuestion';
import OpinionScaleQuestion from './OpinionScaleQuestion';
import { sendGoogleEvent } from '../../utils/analytics';
import { getTranslatedQuestionText } from '../../utils/Translation';
import { calculateHeightForBarChart } from '../../utils/analysis';

export default function AnalysisQuestion({
	index,
	filteredData,
	translations,
	language,
	question,
	responseCount,
	filteredResponseCount,
}) {
	const { t } = useTranslation();
	const chartRef = useRef(); // For downloading it as image and PDF

	const { nanoid } = useParams();

	const [didi, setDidi] = useState([]);

	const comparison = useSelector(
		state =>
			state.analysis.projects[nanoid].comparisonData.appliedComparisonItems,
	);
	const isCompareMode = Object.keys(comparison).length > 0;

	const appliedFilters = useSelector(
		state => state.analysis.projects[nanoid].filterData.appliedFilters,
	);

	const [keys, setKeys] = useState([]);
	const legendLabels = useSelector(state => state.analysis.legendLabels);

	useEffect(() => {
		const calculateChoiceResponseCountForComparison = ({
			data,
			question,
			choice,
			comparisonData,
			comparisonKey,
		}) => {
			let sum = 0;
			Object.keys(comparisonData[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					// TODO: Try to refactor analysisFilter helper and use it here
					const comparedChoiceCount = tidy(
						data,
						filter(d => {
							const dataType = typeof d[question.nanoid];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[question.nanoid] === choice.nanoid;
							} else if (dataType === 'object') {
								// For multiple choice filter items
								return indexOf(d[question.nanoid], choice.nanoid) > -1;
							}
						}),

						filter(d => {
							const dataType = typeof d[comparisonKey];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[comparisonKey] == comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice filter items
								const innerDataType =
									d[comparisonKey][0] == null
										? 'string'
										: typeof d[comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									return indexOf(d[comparisonKey], comparisonItemKey) > -1;
								} else if (innerDataType === 'object') {
									let foundItemIndex = d[comparisonKey].findIndex(
										answer =>
											answer.order == rankKey &&
											answer.choice === comparisonItemKey,
									);

									return foundItemIndex > -1;
								}
							}
						}),
					);

					sum += comparedChoiceCount.length;
				});
			});
			return sum;
		};
		const calculatedKeys = calculateChartKeys(comparison);

		let di = question.choices.map(choice => {
			// TODO: Use analysisFilter util
			const choices = tidy(
				filteredData,
				filter(d => {
					const dataType = typeof d[question.nanoid];
					if (dataType === 'string' || dataType === 'number') {
						// For single choice filter items
						return d[question.nanoid] === choice.nanoid;
					} else if (dataType === 'object') {
						// For multiple choice filter items
						return indexOf(d[question.nanoid], choice.nanoid) > -1;
					}
				}),
			);

			const returnData = {
				country: getTranslatedQuestionText(
					translations,
					language,
					choice.nanoid,
					choice.title,
				),
			};

			if (Object.keys(comparison).length > 0) {
				Object.keys(comparison).forEach(comparisonKey => {
					let choiceResponseCount = calculateChoiceResponseCountForComparison({
						data: choices,
						question: question,
						choice: choice,
						comparisonData: comparison,
						comparisonKey: comparisonKey,
					});

					Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
						const rankKeys = Object.keys(
							comparison[comparisonKey][comparisonItemKey],
						);
						const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
						rankArray.forEach(rankKey => {
							// TODO: Try to refactor analysisFilter helper and use it here
							const comparedChoiceCount = tidy(
								choices,
								filter(d => {
									const dataType = typeof d[question.nanoid];
									if (dataType === 'string' || dataType === 'number') {
										// For single choice filter items
										return d[question.nanoid] === choice.nanoid;
									} else if (dataType === 'object') {
										// For multiple choice filter items
										return indexOf(d[question.nanoid], choice.nanoid) > -1;
									}
								}),

								filter(d => {
									const dataType = typeof d[comparisonKey];
									if (dataType === 'string' || dataType === 'number') {
										// For single choice filter items
										return d[comparisonKey] == comparisonItemKey;
									} else if (dataType === 'object') {
										// For multiple choice filter items
										const innerDataType =
											d[comparisonKey][0] == null
												? 'string'
												: typeof d[comparisonKey][0];

										if (innerDataType === 'string') {
											//for multiple choice
											return indexOf(d[comparisonKey], comparisonItemKey) > -1;
										} else if (innerDataType === 'object') {
											let foundItemIndex = d[comparisonKey].findIndex(
												answer =>
													answer.order == rankKey &&
													answer.choice === comparisonItemKey,
											);

											return foundItemIndex > -1;
										}
									}
								}),
							);

							const key =
								rankKey == -1
									? `${comparisonKey}_${comparisonItemKey}`
									: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
							returnData[key] =
								choiceResponseCount > 0
									? comparedChoiceCount.length / choiceResponseCount
									: 0;
						});
					});
				});
			} else {
				returnData['responseCount'] = choices.length;
				returnData['responsePercentage'] =
					filteredData.length > 0 ? choices.length / filteredData.length : 0;
			}

			return returnData;
		});

		di = tidy(di, arrange(asc('responseCount')));

		setKeys(calculatedKeys);
		setDidi(di);
	}, [
		comparison,
		language,
		filteredData,
		question,
		question.choices,
		question.nanoid,
		translations,
	]);

	const handleDownloadPdf = async () => {
		const element = chartRef.current;
		const canvas = await html2canvas(element, { logging: false });
		const data = canvas.toDataURL('image/png');

		const pdf = new jsPDF();
		const imgProperties = pdf.getImageProperties(data);
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

		pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
		pdf.save('Chart.pdf');

		sendGoogleEvent('analysis_export_chart', { export_type: 'pdf' });
	};

	const handleDownloadImage = async () => {
		const element = chartRef.current;
		const canvas = await html2canvas(element, { logging: false });

		const data = canvas.toDataURL('image/png');
		const link = document.createElement('a');

		if (typeof link.download === 'string') {
			link.href = data;
			link.download = 'Chart.png';

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			window.open(data);
		}
		sendGoogleEvent('analysis_export_chart', { export_type: 'image' });
	};

	const handleDownloadResponsesExcel = () => {
		const rows = [[t('Response ID'), t('Response'), t('Response Date')]];

		filteredData.forEach(item => {
			rows.push([item.nanoid, item[question.nanoid], item.created]);
		});

		const csvContent = 'data:text/csv;charset=utf-8,' + arrayToCsv(rows);

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'Responses.csv');
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".
		document.body.removeChild(link);
	};

	let contextMenuItem = [];

	switch (question.question_type) {
		case 'single_selection':
		case 'multiple_selection':
		case 'yes_no':
		case 'rating':
		case 'ranking':
		case 'nps':
		case 'opinion_scale':
		case 'matrix':
			contextMenuItem = [
				{
					name: t('Download Chart as Image'),
					handleClick: () => setTimeout(() => handleDownloadImage(), 250),
				},
				{
					name: t('Download Chart as PDF'),
					handleClick: () => setTimeout(() => handleDownloadPdf(), 250),
				},
			];
			break;
		case 'text':
		case 'number':
			contextMenuItem = [
				{
					name: t('Download as CSV'),
					handleClick: handleDownloadResponsesExcel,
				},
			];
			break;
		default:
			break;
	}

	const height = calculateHeightForBarChart(
		question.choices.length,
		isCompareMode,
		keys.length,
	);

	return (
		<div
			ref={chartRef}
			className={classNames(
				QUESTION_META_DATA[question.question_type].borderColor,
				'tour-analysis-question bg-white border-l-4 overflow-scroll relative shadow rounded-lg object-center',
			)}
		>
			<div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
				<div className="flex justify-between items-center">
					<div className="flex gap-2">
						<div
							className={classNames(
								QUESTION_META_DATA[question.question_type].borderColor,
								QUESTION_META_DATA[question.question_type].textColor,
								'border-2 w-12 h-12 flex flex-col items-center justify-around rounded shrink-0 font-mono',
							)}
						>
							{`Q${index}`}
						</div>
						<div>
							<h3 className="text-lg font-medium leading-6 text-gray-900">
								{getTranslatedQuestionText(
									translations,
									language,
									question.nanoid,
									question.title,
								)}
							</h3>
							<p className="mt-1 text-sm text-gray-500">
								{question.question_type === 'description'
									? t('descriptionQuestionResponseText')
									: t('{{responseCount}} people responded to this question.', {
											responseCount: responseCount,
									  })}

								{Object.keys(appliedFilters).length > 0 && (
									<>
										{' '}
										{t(
											'Current filters match {{filteredResponseCount}} responses.',
											{ filteredResponseCount: filteredResponseCount },
										)}
									</>
								)}
							</p>
						</div>
					</div>

					{question.question_type !== 'file_upload' &&
						question.question_type !== 'description' && (
							<div>
								<ContextMenu items={contextMenuItem} />
							</div>
						)}
				</div>
			</div>
			<div>
				{(question.question_type === 'single_selection' ||
					question.question_type === 'multiple_selection' ||
					question.question_type === 'yes_no') && (
					<div className="px-4 pb-4 sm:px-6">
						<div
							className="w-full mb-4"
							style={{
								height: height,
							}}
						>
							<BarChart
								legend={t('% of all responses')}
								keys={keys}
								data={didi}
								legendLabels={legendLabels}
								isCompareMode={isCompareMode}
							/>
						</div>
						<Table
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
							translations={translations}
							language={language}
						/>
					</div>
				)}

				{(question.question_type === 'text' ||
					question.question_type === 'number') && (
					<DataListDisplay
						question={question}
						data={filteredData}
						labels={legendLabels}
						comparison={comparison}
						isCompareMode={isCompareMode}
						language={language}
					/>
				)}
				{question.question_type === 'rating' && (
					<div ref={chartRef}>
						<RatingQuestion
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
						/>
					</div>
				)}
				{question.question_type === 'ranking' && (
					<div ref={chartRef}>
						<RankingQuestion
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
							translations={translations}
							language={language}
						/>
					</div>
				)}
				{question.question_type === 'nps' && (
					<div ref={chartRef}>
						<NpsQuestion
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
						/>
					</div>
				)}
				{question.question_type === 'opinion_scale' && (
					<div ref={chartRef}>
						<OpinionScaleQuestion
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
						/>
					</div>
				)}
				{question.question_type === 'matrix' && (
					<div ref={chartRef}>
						<MatrixQuestion
							data={filteredData}
							labels={legendLabels}
							question={question}
							comparison={comparison}
							isCompareMode={isCompareMode}
							translations={translations}
							language={language}
						/>
					</div>
				)}
				{question.question_type === 'file_upload' && (
					<FileUploadQuestion
						data={filteredData}
						labels={legendLabels}
						question={question}
						comparison={comparison}
						isCompareMode={isCompareMode}
						language={language}
					/>
				)}
			</div>
		</div>
	);
}
